import Loading from "../../../../../../../../../../../shared/components/Loading";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  scrappingBlatReferencia,
  scrappingBlatURL,
  scrappingBlatFiltros,
} from "../../../../../../../../../../../shared/services/scrapping";
import { setErrorMessage } from "../../../../../../../../../../../shared/helpers/functionalities";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../../../../../../../../../../../shared/components/InfoModal";
import Filters from "./components/Filters";
import "./scrapping_blat_form.scss";

export default function ScrappingBlatForm({ onScrapping }) {
  /** LocalStorage **/
  const referenciaScrapper = localStorage.getItem("referenciaScrapper");
  const filtrosByScrapper = JSON.parse(
    localStorage.getItem("filtrosByScrapper")
  );

  /** Variables */
  const rrrListId = "rrr-lt-0c56b687";
  const gpaId = "gpa26-com-24a0d8b8";
  const initialDataToLoad = [
    {
      type: "Ovoko",
      data: null,
      completed: false,
    },
    {
      type: "Azelerecambios",
      data: null,
      completed: false,
    },
    {
      type: "Opisto",
      data: null,
      completed: false,
    },
    {
      type: "GPA26",
      data: null,
      completed: false,
    },
  ];

  /** States **/
  const [isLoading, setIsLoading] = useState(false);
  const [dots, setDots] = useState([".", ".", "."]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [searchType, setSearchType] = useState(null);
  const [dataToLoad, setDataToLoad] = useState(initialDataToLoad);
  const filterBy = ["provincia", "motor", "vehiculo", "pieza"];
  const [currentCategories, setCurrentCategories] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const [currentFilterType, setCurrentFilterType] = useState(null);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const {
    register,
    formState: { errors },
    handleSubmit: handleSubmitURL,
    getValues,
  } = useForm({
    defaultValues: {
      referencia: referenciaScrapper,
      filtros:
        filtrosByScrapper &&
        currentFilterType &&
        filtrosByScrapper[currentFilterType],
    },
  });

  /** Lifecycle **/
  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 250);
    return () => clearInterval(interval);
  }, []);

  /** Methods **/
  const onSubmit = async (data, event) => {
    event && event.preventDefault();

    setLoadingProgress(0);
    setDataToLoad(initialDataToLoad);

    if (searchType !== "todos") {
      let singleResponse = null;

      if (searchType === "gpa") {
        const gpaScrapperReference = await scrapByReferenceResponse(
          "gpa",
          data.referencia
        );

        singleResponse = await scrapByUrlResponse(
          gpaScrapperReference.data[0]?.url,
          gpaId
        );
      } else if (searchType === "rrr") {
        const resp = await scrapByReferenceResponse(
          searchType,
          data.referencia,
          data.filtros,
          rrrListId
        );
        singleResponse = {
          data: resp.data?.parts,
        };
      } else {
        singleResponse = await scrapByReferenceResponse(
          searchType,
          data.referencia,
          data.filtros
        );
      }

      if (singleResponse?.data === null || !singleResponse?.data?.length) {
        toast.error(
          "No se han encontrado resultados para la referencia con los filtros seleccionados."
        );
        return;
      }

      const scrappingData = {
        list: { [searchType]: singleResponse?.data },
        oem_code: data.referencia,
      };
      onScrapping(scrappingData, data.referencia);
      return;
    }

    const rrrScrapperReference = await scrapByReferenceResponse(
      "rrr",
      data.referencia,
      data.filtros?.rrr || { pagina: 1 },
      rrrListId
    );
    onLoaded("Ovoko", rrrScrapperReference);
    const azelerecambiosResponse = await scrapByReferenceResponse(
      "azelerecambios",
      data.referencia,
      data.filtros?.azelerecambios || { pagina: 1 }
    );
    onLoaded("Azelerecambios", azelerecambiosResponse);
    const opistoResponse = await scrapByReferenceResponse(
      "opisto",
      data.referencia,
      data.filtros?.opisto || { pagina: 1 }
    );
    onLoaded("Opisto", opistoResponse);
    const gpaScrapperReference = await scrapByReferenceResponse(
      "gpa",
      data.referencia
    );
    const gpaResponse = gpaScrapperReference.data[0]?.url
      ? await scrapByUrlResponse(gpaScrapperReference.data[0]?.url, gpaId)
      : null;
    onLoaded("Gpa26", gpaResponse);

    const scrappingListResponse = {
      data: {
        rrr: rrrScrapperReference?.data?.parts,
        azelerecambios: azelerecambiosResponse?.data,
        opisto: opistoResponse?.data,
        gpa: gpaResponse?.data,
      },
    };

    if (
      rrrScrapperReference.data?.parts?.length ||
      azelerecambiosResponse.data?.length ||
      opistoResponse.data?.length ||
      gpaResponse
    ) {
      scrappingListResponse.success = true;

      if (scrappingListResponse && scrappingListResponse.success) {
        setIsLoading(false);
        const scrappingData = {
          list: scrappingListResponse.data,
          oem_code: data.referencia,
        };
        onScrapping(scrappingData);
      }
    } else {
      toast.error(
        "ERROR! Ha ocurrido un problema o el anuncio no está disponible."
      );
      return;
    }
  };

  const getFilters = async (type) => {
    setCurrentFilterType(type);
    setSearchType(type);

    const referencia = getValues("referencia");

    if (!referencia) {
      toast.error("La referencia de la pieza es obligatoria");
      return;
    }

    if (type === "azelerecambios") {
      const filters = await Promise.all(
        filterBy?.map(async (endpoint) => {
          const resp = await scrapFiltersResponse(type, referencia, endpoint);
          return resp?.data.d || {};
        })
      );

      const reducedFilters = filters.reduce((acc, item) => {
        const key = Object.keys(item)[0];
        if (item[key]?.length > 1) {
          acc[key] = item[key];
        }
        return acc;
      }, {});

      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        [type]: reducedFilters,
      }));
    }

    if (type === "rrr") {
      const resp = await scrapByReferenceResponse(
        type,
        referencia,
        {},
        rrrListId
      );

      const reducedFilters = resp.data?.filters
        ?.map((filter) => {
          return {
            [formatFilterKey(filter.name)]: filter.sub_filters.map(
              (subFilter) => {
                return {
                  text: subFilter.name,
                  value: subFilter.id,
                  count: subFilter.count,
                };
              }
            ),
          };
        })
        .reduce((acc, item) => {
          const key = Object.keys(item)[0];
          if (item[key]?.length > 1) {
            acc[key] = item[key];
          }
          return acc;
        }, {});

      setCurrentFilters((prevFilters) => ({
        ...prevFilters,
        [type]: reducedFilters,
      }));

      setCurrentCategories((prevCategories) => ({
        ...prevCategories,
        [type]: resp.data?.categories,
      }));
    }

    setFiltersVisible(true);
  };

  const formatFilterKey = (key) => {
    return key
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ /g, "_")
      .replace(/ñ/g, "n");
  };

  const scrapFiltersResponse = async (type, referencia, endpoint) => {
    setIsLoading(true);
    return await scrappingBlatFiltros(type, referencia, endpoint)
      .catch(() => {
        toast.error(
          setErrorMessage({
            message: "ERROR! Ha ocurrido un problema, vuelva a intentarlo.",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const scrapByReferenceResponse = async (type, reference, filtros, id) => {
    setIsLoading(true);
    return await scrappingBlatReferencia(type, reference, filtros, id)
      .catch((error) => {
        if (error.response?.status === 400) {
          return { data: [] };
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const scrapByUrlResponse = async (url, id) => {
    setIsLoading(true);
    return await scrappingBlatURL(url, id)
      .catch((error) => {
        toast.error(
          setErrorMessage(
            error
              ? error
              : "ERROR! Ha ocurrido un problema o el anuncio no está disponible."
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onLoaded = (type, response) => {
    setLoadingProgress((prevProgress) => prevProgress + 25);
    setDataToLoad((prevData) =>
      prevData.map((data) => {
        if (data.type === type) {
          return { ...data, data: response?.data || [], completed: true };
        }
        return data;
      })
    );
  };

  const handleScrapperReferenceChange = (e) => {
    const reference = e.target.value;
    localStorage.setItem("referenciaScrapper", reference);
  };

  const handleCloseFilters = () => {
    setFiltersVisible(false);
  };

  const handleFilterBy = (filterBy) => {
    setFiltersVisible(false);
    setSearchType(currentFilterType);
    localStorage.setItem(
      "filtrosByScrapper",
      JSON.stringify({ ...filtrosByScrapper, ...filterBy })
    );
    let filters = {};
    let data = {};

    const currentFilterBy = filterBy[currentFilterType];

    if (currentFilterType === "azelerecambios") {
      filters = {
        pagina: currentFilterBy.pagina,
        motor: formatMotorText(currentFilterBy.motores?.text),
        motorOrden: currentFilterBy.motores?.value,
        CodTipoVehiculo: currentFilterBy.piezas?.CodTipoVehiculo,
        CodPieza: currentFilterBy.piezas?.CodPieza,
        provincia: formatProvincia(currentFilterBy.provincias?.text),
      };
      data = {
        referencia: getValues("referencia"),
        filtros: filters,
      };
    } else if (currentFilterType === "rrr") {
      let filtrosPorParametro = "";
      Object.entries(currentFilterBy).forEach(([key, item]) => {
        if (key === "categoria" || key === "pagina" || !item.value) return;
        filtrosPorParametro += `&${item.value?.replace("-", "=")}`;
      });
      const categoria =
        currentFilterBy.categoria !== -1
          ? `&${currentFilterBy.categoria?.replace("-", "=")}`
          : "";
      filters = {
        pagina: currentFilterBy.pagina,
        categoria,
        filtrosPorParametro,
      };
      data = {
        referencia: getValues("referencia"),
        filtros: filters,
      };
    }

    onSubmit(data);
  };

  const formatMotorText = (text) => {
    return text?.toLowerCase().trim().replace(/\s+/g, "-").replace(/[()]/g, "");
  };

  const formatProvincia = (text) => {
    return text?.replace(/\s+/g, "%20");
  };

  return (
    <>
      <div
        className="position-fixed w-100 h-100 top-0 start-0"
        style={{
          zIndex: 999,
          display: isLoading ? "block" : "none",
        }}
      />
      {isLoading && searchType === "todos" && (
        <div className="d-flex align-items-center flex-column">
          <div className="d-flex">
            <span>Loading</span>
            <span style={{ width: "20px" }}>{dots}</span>
          </div>
          <Box sx={{ width: "50%" }}>
            <LinearProgressWithLabel value={loadingProgress} />
          </Box>
          {dataToLoad.map((data, index) => (
            <div className="d-flex gap-2" key={index}>
              {data.completed && (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="1x"
                  className="action"
                  color="#2EA745"
                />
              )}
              <strong style={{ color: data.completed ? "black" : "gray" }}>
                {data.type}
              </strong>
              <span style={{ color: data.completed ? "black" : "gray" }}>
                {`${
                  (data.data?.parts
                    ? data.data?.parts?.length
                    : data.data?.length) || 0
                } piezas encontradas`}
              </span>
            </div>
          ))}
        </div>
      )}

      {isLoading && searchType !== "todos" && <Loading />}

      {!isLoading && (
        <>
          <h5 className="fw-bold mb-3">Buscador de referencias</h5>
          <form
            className="d-flex flex-column"
            onSubmit={handleSubmitURL(onSubmit)}
          >
            <div className="row g-2">
              <div className="col-8">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Referencia pieza (OEM)"
                  {...register("referencia", { required: true })}
                  onChange={handleScrapperReferenceChange}
                />
                <div className="form-invalid">
                  {errors.referencia?.type === "required" &&
                    "La referencia de la pieza es obligatoria"}
                </div>
              </div>
              <div className="col-4">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="btn w-100 btn-custom"
                  onClick={() => setSearchType("todos")}
                >
                  <div className="d-flex gap-2 justify-content-center">
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="1x"
                      className="action"
                    />
                    <span>Todos</span>
                  </div>
                </button>
              </div>
            </div>

            <div className="d-flex flex-column w-100">
              <div className="d-flex flex-column flex-lg-row pb-2 pt-2 gap-2">
                <div className="d-flex w-100">
                  <div
                    className="btn-group w-100"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      disabled={isLoading}
                      className="btn btn-primary"
                      onClick={() => getFilters("rrr")}
                    >
                      <div className="d-flex gap-2 justify-content-center">
                        <FontAwesomeIcon
                          icon={faFilter}
                          size="1x"
                          className="action"
                          color="#fff"
                        />
                      </div>
                    </button>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-primary w-100 btn-divider"
                      onClick={() => setSearchType("rrr")}
                    >
                      <div className="d-flex gap-2 justify-content-center">
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="1x"
                          className="action"
                          color="#fff"
                        />
                        <span>Ovoko</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    className="btn-group w-100"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      disabled={isLoading}
                      className="btn btn-primary"
                      onClick={() => getFilters("azelerecambios")}
                    >
                      <div className="d-flex gap-2 justify-content-center">
                        <FontAwesomeIcon
                          icon={faFilter}
                          size="1x"
                          className="action"
                          color="#fff"
                        />
                      </div>
                    </button>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-primary w-100 btn-divider"
                      onClick={() => setSearchType("azelerecambios")}
                    >
                      <div className="d-flex gap-2 justify-content-center">
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="1x"
                          className="action"
                          color="#fff"
                        />
                        <span>Azelercambios</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div
                    className="btn-group w-100"
                    role="group"
                    aria-label="First group"
                  >
                    <button
                      type="button"
                      disabled={isLoading}
                      className="btn btn-primary"
                      onClick={() => getFilters("opisto")}
                    >
                      <div className="d-flex gap-2 justify-content-center">
                        <FontAwesomeIcon
                          icon={faFilter}
                          size="1x"
                          className="action"
                          color="#fff"
                        />
                      </div>
                    </button>
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="btn btn-primary w-100 btn-divider"
                      onClick={() => setSearchType("opisto")}
                    >
                      <div className="d-flex gap-2 justify-content-center">
                        <FontAwesomeIcon
                          icon={faSearch}
                          size="1x"
                          className="action"
                          color="#fff"
                        />
                        <span>Opisto</span>
                      </div>
                    </button>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <button
                    type="submit"
                    disabled={isLoading}
                    className="btn btn-primary w-100"
                    onClick={() => setSearchType("gpa")}
                  >
                    <div className="d-flex gap-2 justify-content-center">
                      <FontAwesomeIcon
                        icon={faSearch}
                        size="1x"
                        className="action"
                        color="#fff"
                      />
                      <span>GPA26</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}

      {filtersVisible && (
        <InfoModal
          state={filtersVisible}
          title="Filtros"
          content={
            <Filters
              categories={currentCategories[currentFilterType]}
              filters={currentFilters[currentFilterType]}
              filterType={currentFilterType}
              onFilter={handleFilterBy}
            />
          }
          onClose={handleCloseFilters}
        />
      )}
    </>
  );
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" color="success" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary" }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
