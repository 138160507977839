import { useState, useEffect } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import PlantillaEmailForm from "./components/PlantillaEmailForm";
import InfoModal from "../../../shared/components/InfoModal";
import { set } from "lodash";

export default function PlantillasEmail() {
  /** Variables **/
  const [plantillasEmailList, setPlantillasEmailList] = useState(null);
  const [currentPlantillaEmail, setCurrentPlantillaEmail] = useState(null);
  const [search, setSearch] = useState("");
  const [openForm, setOpenForm] = useState(false);

  /** Redux **/
  const plantillasEmail = useSelector((state) => {
    const { plantillas_email } = state;
    return plantillas_email;
  });

  /** Lifecycle **/
  useEffect(() => {
    setPlantillasEmailList(plantillasEmail);
  }, [plantillasEmail]);

  /** Methods **/
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearch(value);
    setPlantillasEmailList(
      plantillasEmail.filter(
        (plantillaEmail) =>
          !value ||
          plantillaEmail.nombre?.toLowerCase().includes(value) ||
          plantillaEmail.contenido?.toLowerCase().includes(value)
      )
    );
  };

  const handleOpenForm = (plantilla) => {
    plantilla?.id
      ? setCurrentPlantillaEmail(plantilla)
      : setCurrentPlantillaEmail(null);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
    setCurrentPlantillaEmail(null);
  };

  /** Render **/
  return (
    <div className="w-100 d-flex flex-column align-items-start">
      <div className="w-100 d-flex justify-content-center">
        <div className="col-12 col-md-8 d-flex flex-column align-items-center order-1 order-md-0">
          <div className="w-100 d-flex align-content-center justify-content-between mb-4">
            <div className="flex-grow-1 d-flex align-items-center ">
              <div className="position-relative w-50">
                <input
                  type="text"
                  name="searcher"
                  className="form-control"
                  value={search}
                  placeholder="Busca una plantilla email..."
                  onChange={(e) => handleSearch(e)}
                />
                <FontAwesomeIcon
                  icon={faSearch}
                  color="#215732"
                  className="icon"
                />
              </div>
            </div>

            <button
              type="button"
              className="btn btn-add"
              onClick={() => handleOpenForm()}
            >
              <span className="add">
                <FontAwesomeIcon
                  icon={faPlus}
                  size="1x"
                  className="action"
                  color="#215732"
                />
              </span>
              Nuevo
            </button>
          </div>

          <div className="w-100 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th style={{ width: "200px" }}>Nombre</th>
                  <th>Contenido</th>
                  <th className="text-center" style={{ width: "100px" }}>
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {plantillasEmailList?.map((plantilla) => {
                  return (
                    <tr key={"valor-" + plantilla.id}>
                      <td>
                        <span>{plantilla.nombre}</span>
                      </td>
                      <td
                        style={{
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          width: "600px",
                        }}
                      >
                        {plantilla.contenido}
                      </td>
                      <td className="text-center">
                        <span
                          title="Editar"
                          className="me-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenForm(plantilla)}
                        >
                          <FontAwesomeIcon
                            icon={faPen}
                            size="1x"
                            className="action"
                            color="#215732"
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {!plantillasEmailList?.length && (
              <p className="text-center p-3" style={{ background: "#F5F5F7" }}>
                Lista de plantias email vacía
              </p>
            )}
          </div>
        </div>

        {openForm && (
          <InfoModal
            state={openForm}
            title={
              (currentPlantillaEmail ? "Editar" : "Nueva") + " plantilla email"
            }
            content={
              <PlantillaEmailForm
                plantillaEmail={currentPlantillaEmail}
                onActionDone={handleCloseForm}
              />
            }
            width={"md"}
            onClose={handleCloseForm}
          />
        )}
      </div>
    </div>
  );
}
