import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Box, MenuItem, Select } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import moment from 'moment';
import { faEuroSign, faFilterCircleXmark, faSearch, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isBoolean, CustomTooltip } from "../../../../../../helpers/functionalities";
import AutocompleteSelect from "../../../../../AutocompleteSelect";

export default function Advanced({
    detalleTab,
    pageName,
    reset,
    onChange,
    onReset
}) {
    const user = JSON.parse(localStorage.getItem('user'));
    const recambistas = useSelector(state => {
        return state.recambistas
    });
    const transportistas = useSelector(state => {
        return state.transportistas
    });
    const atencionCliente = useSelector(state => {
        return state.atencion_cliente
    });
    const administracion = useSelector(state => {
        return state.administracion
    });
    const comerciales = useSelector(state => {
        return state.comerciales
    });
    const tipoIncidencias = useSelector(state => {
        return state.tipo_incidencias
    });
    const tipoFacturas = useSelector(state => {
        return state.tipo_facturas
    });
    const tipo_piezas = useSelector(state => {
        const { tipo_piezas } = state;
        return tipo_piezas;
    });
    const searcher = useSelector(state => {
        return state.search
    });
    const [search, setSearch] = useState('');
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [responsable, setResponsable] = useState(-1);
    const [creadoPor, setCreadoPor] = useState(user.rol_id === 5 ? user.id : -1);
    const [transportista, setTransportista] = useState(-1);
    const [comercial, setComercial] = useState(null);
    const [tipo, setTipo] = useState(pageName === 'comerciales' ? 'validos' : 'todos');
    const [credito, setCredito] = useState(-1);
    const [integracion, setIntegracion] = useState(-1);
    const [vales, setVales] = useState(-1);
    const [SEPA, setSEPA] = useState(-1);
    const [confirmacion, setConfirmacion] = useState(-1);
    const [solucionProveedor, setSolucionProveedor] = useState(-1);
    const [solucionCliente, setSolucionCliente] = useState(-1);
    const [responsables, setResponsables] = useState(null);
    const [personasIncidencias, setPersonasIncidencias] = useState(null);
    const [culpa, setCulpa] = useState('todos');
    const [tipoPiezas, setTipoPiezas] = useState([]);
    const [contieneCasco, setContieneCasco] = useState(-1);
    const [casco, setCasco] = useState(0);
    const [key, setKey] = useState("presupuesto");
    const [descuentos, setDescuentos] = useState(0);
    const [devoluciones, setDevoluciones] = useState(0);
    const [tipoFactura, setTipoFactura] = useState(0);

    useEffect(() => {
        if(reset) {
            setSearch('');
            setStartDateValue(null);
            setEndDateValue(null);
            setResponsable(-1);
            setCreadoPor(-1)
            setTransportista(-1);
            setComercial(null);
            setTipo(pageName === 'comerciales' ? 'validos' : 'todos');
            setMax(0);
            setMin(0);
            setSEPA(-1);
            setConfirmacion(-1);
            setSolucionProveedor(-1);
            setSolucionCliente(-1);
            setCredito(-1);
            setIntegracion(-1);
            setVales(-1);
            setCulpa('todos');
            setTipoPiezas([]);
            setContieneCasco(-1);
            setCasco(0);
            setKey("presupuesto");
            setDescuentos(0);
            setDevoluciones(0);
            setTipoFactura(0);
        } else {
            const isSearcherEmpty = Object.values(searcher).every(x => x === null || x === '');

            if(!isSearcherEmpty) {
                if(searcher?.search) setSearch(searcher?.search);
                if(searcher?.start_date) setStartDateValue(searcher?.start_date);
                if(searcher?.end_date) setEndDateValue(searcher?.end_date);
                if(searcher?.descuentos) setDescuentos(searcher?.descuentos);
                if(searcher?.devoluciones) setDevoluciones(searcher?.devoluciones);
                if(searcher?.tipoFactura) setTipoFactura(searcher?.tipoFactura);
                if(searcher?.responsable) setResponsable(searcher?.responsable);
                if(searcher?.creado_por) setCreadoPor(searcher?.creado_por);
                if(searcher?.transportista) setTransportista(searcher?.transportista);
                if(searcher?.comercial) setComercial(searcher?.comercial);
                if(searcher?.tipo) setTipo(searcher?.tipo ? searcher?.tipo : (pageName === 'comerciales' ? 'validos' : 'todos'));
                if(searcher?.min) setMin(searcher?.min);
                if(searcher?.max) setMax(searcher?.max);
                if(searcher?.culpa) setCulpa(searcher?.culpa ? searcher?.culpa : 'todos');
                if(isBoolean(searcher?.credito)) setCredito(+searcher?.credito);
                if(isBoolean(searcher?.integracion)) setIntegracion(+searcher?.integracion);
                if(isBoolean(searcher?.vales)) setVales(+searcher?.vales);
                if(isBoolean(searcher?.sepa)) setSEPA(+searcher?.sepa);
                if(isBoolean(searcher?.confirmacion)) setConfirmacion(+searcher?.confirmacion);
                if(isBoolean(searcher?.solucion_proveedor)) setSolucionProveedor(+searcher?.solucion_proveedor);
                if(isBoolean(searcher?.solucion_cliente)) setSolucionCliente(+searcher?.solucion_cliente);
                if(isBoolean(searcher?.contiene_casco)) setContieneCasco(+searcher?.contiene_casco);
                if(searcher?.tipo_piezas) setTipoPiezas(searcher?.tipo_piezas ? searcher?.tipo_piezas : []);
                if(searcher?.casco) setCasco(searcher?.casco);
                if(searcher?.key) setKey(searcher.key);
            }
        }

        setResponsables(recambistas);
        setPersonasIncidencias([...atencionCliente, ...administracion]);

        if(detalleTab === 'cliente' || detalleTab === 'lead') setResponsable(-1);
    }, [searcher, recambistas, atencionCliente, administracion, reset, detalleTab]);

    const handleSearch = () => {
        onReset(false);

        var searchData = {
            key: key,
            search: search,
        }

        if(startDateValue) { searchData.start_date = moment(startDateValue).format('YYYY-MM-DD'); }
        if(endDateValue) { searchData.end_date = moment(endDateValue).format('YYYY-MM-DD'); }
        
        if(responsable && responsable !== -1) { searchData.responsable = parseInt(responsable); }
        if(creadoPor && creadoPor !== -1) { searchData.creado_por = parseInt(creadoPor); }
        if(comercial && comercial !== -1) { searchData.comercial = parseInt(comercial); }
        if(transportista && transportista !== -1) { searchData.transportista = parseInt(transportista); }
        
        if(min) { searchData.min = min; }
        if(max) { searchData.max = max; }
        if(tipo !== "todos") { searchData.tipo = tipo; }
        if(culpa !== "todos") { searchData.culpa = culpa; }
        if(casco) { searchData.casco = casco; }
        if(descuentos) { searchData.descuentos = descuentos; }
        if(devoluciones) { searchData.devoluciones = devoluciones; }
        if(tipoFactura) { searchData.tipoFactura = tipoFactura; }

        if(tipoPiezas && tipoPiezas?.length > 0) { searchData.tipo_piezas = tipoPiezas; }

        if(isBoolean(credito)) {searchData.credito = !!Number(credito);}
        if(isBoolean(integracion)) {searchData.integracion = !!Number(integracion);}
        if(isBoolean(SEPA)) {searchData.sepa = !!Number(SEPA);}
        if(isBoolean(confirmacion)) {searchData.confirmacion = !!Number(confirmacion);}
        if(isBoolean(vales)) { searchData.vales = !!Number(vales); }
        if(isBoolean(solucionProveedor)) { searchData.solucion_proveedor = !!Number(solucionProveedor); }
        if(isBoolean(solucionCliente)) { searchData.solucion_cliente = !!Number(solucionCliente); }
        if(isBoolean(contieneCasco)) { searchData.contiene_casco = !!Number(contieneCasco); }

        onChange(searchData);
    };

    const handleChangeKey = (event) => setKey(event.target.value);
    const handleChangeVales = (event) =>  setVales(parseInt(event.target.value));
    const handleChange = (event) => setSearch(event.target.value);
    const handleResponsable = (e) => setResponsable(parseInt(e.target.value))
    const handleResponsableIncidencias = (e) => setCreadoPor(parseInt(e.target.value))
    const handleTransportista = (e) => setTransportista(parseInt(e.target.value))
    const handleComercial = (e) => setComercial(parseInt(e.target.value))
    const handleTipo = (e) => setTipo(e.target.value)
    const handleCulpa = (e) => setCulpa(e.target.value)
    const handleChangeMin = (event) => setMin(event.target.value);
    const handleChangeMax = (event) => setMax(event.target.value);
    const handleChangeCasco = (event) => setCasco(event.target.value);

    const handleChangeContieneCasco = (event) => {
        setContieneCasco(event.target.value);

        if(event.target.value !== 1) {
            setCasco(0);
        }
    }
    const handleChangeCredito = (event) => setCredito(event.target.value);
    const handleChangeIntegracion = (event) => setIntegracion(event.target.value);
    const handleChangeSEPA = (event) => setSEPA(event.target.value);
    const handleChangeConfirmacion = (event) => setConfirmacion(event.target.value);
    const handleChangeSolucionProveedor = (event) => setSolucionProveedor(event.target.value);
    const handleChangeSolucionCliente = (event) => setSolucionCliente(event.target.value);

    const handleChangeTipoPiezas = (tipPiezas) => {
        if(tipPiezas !== undefined && tipPiezas !== null) {
            if(tipPiezas?.length > 0) {
                setTipoPiezas(tipPiezas)
            } else { 
                setTipoPiezas([])
            }
        }
    }

    const getMenuItemTooltipValue = () => {
        switch(key) {
            case 'incidencia':
                return 'Incidencia: "I-0000000" o "0000000"';
            case 'presupuesto':
                return 'Presupuesto: "P-0000000" o "0000000" o Matrícula.';
            case 'cliente':
                return 'Cliente: Nombre, teléfono, CIF.';
            case 'proveedor':
                return 'Proveedor: Nombre, teléfono, CIF.';
            case 'pieza':
                return 'Pieza: ID o referencia.';
            case 'factura':
                return 'Factura/Albarán: "F-0000000", "ALV-0000000" o "0000000"';
            case 'envio':
                return 'Envío: "E-0000000", "0000000" o Número de seguimiento.';
            default:
                return '';
        }
    }

    return (
        <div className="w-100 d-flex flex-wrap align-items-end justify-content-end">
            { (
                pageName === 'presupuestos' || 
                pageName === 'ecommerce' || 
                pageName === 'ventas' || 
                pageName === 'facturas' || 
                pageName === 'devoluciones' || 
                pageName === 'incidencias' || 
                pageName === 'comerciales' || 
                pageName === 'portugal'
            ) ?
                <div className="d-flex flex-column me-3 mt-md-2 mt-lg-0">
                    <small>Fecha</small>

                    <div className="d-flex align-items-center">
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker
                                disableFuture
                                label=""
                                id="start_date"
                                value={startDateValue}
                                onChange={(newValue) => {
                                    setStartDateValue(newValue);
                                }}
                                mask={'__/__/____'}
                                inputFormat="dd/MM/yyyy"
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box className="date-picker me-3" sx={{ position: 'relative' }}>
                                        <input className="form-control" ref={inputRef} {...inputProps} />
                                        {InputProps?.endAdornment}
                                    </Box>
                                )}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker
                                disableFuture
                                label=""
                                id="end_date"
                                value={endDateValue}
                                onChange={(newValue) => {
                                    setEndDateValue(newValue);
                                }}
                                mask={'__/__/____'}
                                inputFormat="dd/MM/yyyy"
                                minDate={startDateValue}
                                renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <Box className="date-picker" sx={{ position: 'relative' }}>
                                        <input className="form-control" ref={inputRef} {...inputProps} />
                                        {InputProps?.endAdornment}
                                    </Box>
                                )}
                            />
                        </LocalizationProvider>
                    </div>
                </div>
                : ''
            }

            { (
                pageName === 'presupuestos' || 
                pageName === 'ecommerce' || 
                pageName === 'ventas' || 
                pageName === 'devoluciones' || 
                pageName === 'incidencias' || 
                pageName === 'envios' || 
                pageName === 'solicitudes'
            ) ?
                <div className="d-flex flex-grow-1 flex-wrap flex-md-row align-items-end justify-content-start">
                    { (
                        pageName === 'ventas' || 
                        pageName === 'devoluciones' || 
                        pageName === 'presupuestos' || 
                        pageName === 'ecommerce' ||
                        pageName === 'envios' || 
                        pageName === 'solicitudes'
                    ) ?
                        <div className="d-flex flex-column me-3 mt-3 mt-lg-0">
                            <small>Responsable</small>
                            { (recambistas && recambistas.length > 0) &&
                            <Select
                                size="small"
                                name="responsable"
                                id="responsable"
                                onChange={handleResponsable}
                                value={responsable ? responsable : -1}>
                                    <MenuItem 
                                        key={'recambistas-0'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    {recambistas.map(op => 
                                        <MenuItem 
                                            key={'recambistas-' + op.id}
                                            value={op.id}>
                                            {op.username}
                                        </MenuItem>
                                    )}
                                    {pageName === 'ventas' &&
                                        <MenuItem 
                                            value={45}>
                                            {"ecommerce"}
                                        </MenuItem>
                                    }
                            </Select>
                            }
                        </div>
                        : ''
                    }

                    { (
                        pageName !== 'incidencias' && 
                        pageName !== 'envios' && 
                        pageName !== 'solicitudes'
                    ) ?
                        <>
                            <div className="d-flex flex-column me-3">
                                <small>Importe (con IVA)</small>

                                <div className="d-flex align-items-center">
                                    <div className="position-relative">
                                        <input 
                                            type="number" 
                                            name="min" 
                                            step={0.1}
                                            className="form-control" 
                                            value={min} 
                                            onChange={handleChangeMin}
                                            placeholder="mínimo" 
                                        />
                                        <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                    </div>

                                    <div className="position-relative ms-3">
                                        <input 
                                            type="number" 
                                            name="max" 
                                            step={0.1}
                                            className="form-control" 
                                            value={max} 
                                            placeholder="máximo"
                                            onChange={handleChangeMax}
                                        />
                                        <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                    </div>
                                </div>
                            </div>

                            { (pageName === 'presupuestos' || pageName === 'ventas') &&
                                <>
                                <div className="d-flex align-items-center me-3">
                                    <div className="d-flex flex-column me-3">
                                        <small>Contiene casco</small>

                                        <Select
                                            size="small"
                                            name="contiene_casco"
                                            id="contiene_casco"
                                            onChange={handleChangeContieneCasco}
                                            value={contieneCasco !== null ? contieneCasco : -1}>
                                                <MenuItem 
                                                    key={'filter-casco--1'}
                                                    value={-1}>
                                                    {"Todos"}
                                                </MenuItem>
                                                <MenuItem 
                                                    key={'filter-casco-0'}
                                                    value={0}>
                                                    {"No"}
                                                </MenuItem>
                                                <MenuItem 
                                                    key={'filter-casco-1'}
                                                    value={1}>
                                                    {"Si"}
                                                </MenuItem>
                                        </Select>
                                    </div>
                                    
                                    <div className="">
                                        <small>Importe casco</small>

                                        <div className="position-relative">
                                            <input 
                                                type="number" 
                                                name="casco" 
                                                step={0.1}
                                                className="form-control" 
                                                value={casco} 
                                                placeholder="casco"
                                                onChange={handleChangeCasco}
                                            />
                                            <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                        </div>
                                    </div>
                                </div>
                                </>
                            }
                        </>
                        
                        : ''
                    }

                    { (pageName === 'ventas') ?
                        <div className="d-flex flex-column me-3 mt-3 mt-lg-0">
                            <small>Confirmación</small>

                            <Select
                                size="small"
                                name="confirmacion"
                                id="confirmacion"
                                onChange={handleChangeConfirmacion}
                                value={confirmacion !== null ? confirmacion : -1}>
                                    <MenuItem 
                                        key={'filter-confirmacion--1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-confirmacion-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-confirmacion-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>
                        : ''
                    }

                    { (pageName === 'incidencias') ?
                        <>
                            <div className="d-flex align-items-center mt-3 mt-md-0">
                                <div className="d-flex flex-column me-3 mt-3 mt-lg-0">
                                    <small>Responsable</small>
                                    { (responsables && responsables.length > 0) &&
                                    <Select
                                        size="small"
                                        name="responsable"
                                        id="responsable"
                                        onChange={handleResponsable}
                                        value={responsable ? responsable : -1}>
                                            <MenuItem 
                                                key={'responsable-0'}
                                                value={-1}>
                                                {"Todos"}
                                            </MenuItem>
                                            {responsables.map(op => 
                                                <MenuItem 
                                                    key={'responsable-' + op.id}
                                                    value={op.id}>
                                                    {op.username}
                                                </MenuItem>
                                            )}
                                    </Select>
                                    }
                                </div>

                                <div className="d-flex flex-column me-3 mt-3 mt-lg-0">
                                    <small>Creado por</small>
                                    { (personasIncidencias && personasIncidencias.length > 0) &&
                                    <Select
                                        size="small"
                                        name="creado_por"
                                        id="creado_por"
                                        onChange={handleResponsableIncidencias}
                                        value={creadoPor ? creadoPor : -1}>
                                            <MenuItem 
                                                key={'incidencias-0'}
                                                value={-1}>
                                                {"Todos"}
                                            </MenuItem>
                                            {personasIncidencias.map(op => 
                                                <MenuItem 
                                                    key={'incidencias-' + op.id}
                                                    value={op.id}>
                                                    {op.username}
                                                </MenuItem>
                                            )}
                                    </Select>
                                    }
                                </div>
                            </div>

                            <div className="d-flex flex-column me-3 mt-3 mt-lg-0">
                                <small>Tipo</small>
                                { (tipoIncidencias && tipoIncidencias.length > 0) &&
                                    <Select
                                        size="small"
                                        name="tipo"
                                        id="tipo"
                                        onChange={handleTipo}
                                        value={tipo ? tipo : -1}>
                                            <MenuItem 
                                                key={'tipo-0'}
                                                value={"todos"}>
                                                {"Todos"}
                                            </MenuItem>
                                            {tipoIncidencias.map(op => 
                                                <MenuItem 
                                                    key={'tipo-' + op.id}
                                                    value={op.id}>
                                                    {op.nombre}
                                                </MenuItem>
                                            )}
                                    </Select>
                                }
                            </div>

                            <div className="d-flex flex-column me-3 mt-3 mt-lg-0">
                                <small>Culpa</small>
                                <Select
                                    size="small"
                                    name="culpa"
                                    id="culpa"
                                    onChange={handleCulpa}
                                    value={culpa ? culpa : -1}>
                                        <MenuItem 
                                            value={"todos"}>
                                            {"Todos"}
                                        </MenuItem>
                                        <MenuItem 
                                            value={"cliente"}>
                                            {"Cliente"}
                                        </MenuItem>
                                        <MenuItem 
                                            value={"proveedor"}>
                                            {"Proveedor"}
                                        </MenuItem>
                                        <MenuItem 
                                            value={"recomotor"}>
                                            {"Recomotor"}
                                        </MenuItem>
                                        <MenuItem 
                                            value={"atencion-comercial"}>
                                            {"Atención comercial"}
                                        </MenuItem>
                                        <MenuItem 
                                            value={"transporte-propio"}>
                                            {"Transporte propio"}
                                        </MenuItem>
                                        <MenuItem 
                                            value={"transporte-proveedor"}>
                                            {"Transporte proveedor"}
                                        </MenuItem>
                                </Select>
                            </div>

                            <div className="d-flex flex-column me-3 mt-3 mt-lg-0">
                                <small>Tipo de pieza:</small>
                                <AutocompleteSelect 
                                    options={tipo_piezas}
                                    id={'tipo_piezas'}
                                    selectedValues={tipoPiezas}
                                    onSetValue={handleChangeTipoPiezas}
                                />
                            </div>

                            <div className="d-flex align-items-stretch mt-3 mt-lg-0 me-3">
                                <div className="form-group m-0 me-3">
                                    <small>Sol. Proveedor</small>

                                    <Select
                                        size="small"
                                        name="solucion_proveedor"
                                        id="solucion_proveedor"
                                        onChange={handleChangeSolucionProveedor}
                                        value={solucionProveedor !== null ? solucionProveedor : -1}>
                                            <MenuItem 
                                                key={'filter-sol-prov--1'}
                                                value={-1}>
                                                {"Todos"}
                                            </MenuItem>
                                            <MenuItem 
                                                key={'filter-sol-prov-0'}
                                                value={0}>
                                                {"No"}
                                            </MenuItem>
                                            <MenuItem 
                                                key={'filter-sol-prov-1'}
                                                value={1}>
                                                {"Si"}
                                            </MenuItem>
                                    </Select>
                                </div>

                                <div className="form-group justify-content-end m-0">
                                    <small>Sol. Cliente</small>

                                    <Select
                                        size="small"
                                        name="solucion_cliente"
                                        id="solucion_cliente"
                                        onChange={handleChangeSolucionCliente}
                                        value={solucionCliente !== null ? solucionCliente : -1}>
                                            <MenuItem 
                                                key={'filter-sol-cli--1'}
                                                value={-1}>
                                                {"Todos"}
                                            </MenuItem>
                                            <MenuItem 
                                                key={'filter-sol-cli-0'}
                                                value={0}>
                                                {"No"}
                                            </MenuItem>
                                            <MenuItem 
                                                key={'filter-sol-cli-1'}
                                                value={1}>
                                                {"Si"}
                                            </MenuItem>
                                    </Select>
                                </div>
                            </div>
                        </>
                        : ''
                    }

                    { (pageName === 'envios') ?
                        <div className="d-flex align-items-center">
                            <div className="form-group m-0">
                                <small>Transportista</small>
                                { (transportistas && transportistas.length > 0) &&
                                    <Select
                                        size="small"
                                        name="transportista"
                                        id="transportista"
                                        onChange={handleTransportista}
                                        value={transportista ? transportista : -1}>
                                            <MenuItem 
                                                key={'transportista-0'}
                                                value={-1}>
                                                {"Todos"}
                                            </MenuItem>
                                            {transportistas.map(op => 
                                                <MenuItem 
                                                    key={'transportista-' + op.id}
                                                    value={op.id}>
                                                    {op.nombre}
                                                </MenuItem>
                                            )}
                                    </Select>
                                }
                            </div>
                        </div>
                        : ''
                    }
                </div>
            : (pageName === 'comerciales' || pageName === 'portugal') ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end">
                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Comercial</small>
                            { (comerciales && comerciales.length > 0) &&
                                    <select 
                                        id="comercial"
                                        value={comercial ? comercial : -1}
                                        onChange={handleComercial}>
                                        <option key={'comerciales-' + 0} value={-1}>Todos</option>
                                        {comerciales.map(op => <option key={'comerciales-' + op.id} value={op.id}>{op.username}</option>)}
                                    </select>
                            }
                        </div>
                    </div>

                    <div className="d-flex align-items-center me-0 me-md-3 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Tipo</small>
                            
                            <select 
                                id="tipo"
                                value={tipo ? tipo : 'validos'}
                                onChange={(e) => handleTipo(e)}>
                                    <option value={"todos"}>Todos</option>
                                    <option value={"validos"}>Válidos</option>
                                    <option value={"no-llamar"}>No llamar</option>
                                    <option value={"no-piden"}>No piden</option>
                            </select>
                           
                        </div>
                    </div>
                </div>
            : pageName === 'proveedores' ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-between">
                    <div className="d-flex align-items-center mx-0 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>A Crédito</small>
                            <Select
                                size="small"
                                name="credito"
                                id="credito"
                                onChange={handleChangeCredito}
                                value={credito !== null ? credito : -1}>
                                    <MenuItem 
                                        key={'filter-credito---1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>Integración</small>
                            <Select
                                size="small"
                                name="integracion"
                                id="integracion"
                                onChange={handleChangeIntegracion}
                                value={integracion !== null ? integracion : -1}>
                                    <MenuItem 
                                        key={'filter-integracion---1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-integracion-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-integracion-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>Vales</small>
                            <Select
                                size="small"
                                name="vales"
                                id="vales"
                                onChange={handleChangeVales}
                                value={vales !== null ? vales : -1}>
                                    <MenuItem 
                                        key={'filter-vales---1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-vales-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-vales-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
            : pageName === 'clientes' ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-between">
                    <div className="d-flex align-items-center mx-0 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>A Crédito</small>
                            <Select
                                size="small"
                                name="credito"
                                id="credito"
                                onChange={handleChangeCredito}
                                value={credito !== null ? credito : -1}>
                                    <MenuItem 
                                        key={'filter-credito---1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-credito-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>SEPA</small>
                            <Select
                                size="small"
                                name="sepa"
                                id="sepa"
                                onChange={handleChangeSEPA}
                                value={SEPA !== null ? SEPA : -1}>
                                    <MenuItem 
                                        key={'filter-sepa---1'}
                                        value={-1}>
                                        {"Todos"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-sepa-0'}
                                        value={0}>
                                        {"No"}
                                    </MenuItem>
                                    <MenuItem 
                                        key={'filter-sepa-1'}
                                        value={1}>
                                        {"Si"}
                                    </MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
            : pageName === 'facturas' ?
                <div className="d-flex flex-grow-1 flex-column flex-md-row align-items-end justify-content-between">
                    <div className="d-flex align-items-center mx-0 mt-3 mt-md-0">
                        <div className="form-group m-0">
                            <small>Descuentos</small>
                            <Select
                                size="small"
                                name="descuentos"
                                id="descuentos"
                                onChange={(e) => setDescuentos(e.target.value)}
                                value={descuentos !== null ? descuentos : 0}
                            >
                                <MenuItem
                                    key={'filter-descuentos-0'}
                                    value={0}
                                >
                                    {"No"}
                                </MenuItem>
                                <MenuItem 
                                    key={'filter-descuentos-1'}
                                    value={1}
                                >
                                    {"Si"}
                                </MenuItem>
                            </Select>
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>Devoluciones</small>
                            <Select
                                size="small"
                                name="devoluciones"
                                id="devoluciones"
                                onChange={(e) => setDevoluciones(e.target.value)}
                                value={devoluciones !== null ? devoluciones : 0}
                            >
                                <MenuItem 
                                    key={'filter-devoluciones-0'}
                                    value={0}
                                >
                                    {"No"}
                                </MenuItem>
                                <MenuItem 
                                    key={'filter-devoluciones-1'}
                                    value={1}
                                >
                                    {"Si"}
                                </MenuItem>
                            </Select>
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>Tipo</small>
                            { (tipoFacturas && tipoFacturas.length > 0) &&
                                <Select
                                    size="small"
                                    name="tipoFactura"
                                    id="tipoFactura"
                                    onChange={(e) => setTipoFactura(e.target.value)}
                                    value={tipoFactura ? tipoFactura : 0}
                                >
                                    <MenuItem
                                        key={'tipoFactura-0'}
                                        value={0}>
                                        {"Todos"}
                                    </MenuItem>
                                    {tipoFacturas.map(op => 
                                        <MenuItem 
                                            key={'tipoFactura-' + op.id}
                                            value={op.id}
                                        >
                                            {op.nombre?.slice(0, 1).toUpperCase() + op.nombre?.slice(1)}
                                        </MenuItem>
                                    )}
                                </Select>
                            }
                        </div>

                        <div className="form-group m-0 ms-3">
                            <small>Rango (TOTAL I.V.A)</small>
                            { (tipoFacturas && tipoFacturas.length > 0) &&
                                <div className="d-flex align-items-center">
                                    <div className="position-relative">
                                        <input 
                                            type="number" 
                                            name="min" 
                                            step={0.1}
                                            className="form-control" 
                                            value={min} 
                                            onChange={(e) => setMin(e.target.value)}
                                            placeholder="Mínimo" 
                                            style={{height: '40px'}}
                                        />
                                        <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                    </div>

                                    <div className="position-relative ms-3">
                                        <input 
                                            type="number" 
                                            name="max" 
                                            step={0.1}
                                            className="form-control" 
                                            value={max} 
                                            placeholder="Máximo"
                                            onChange={(e) => setMax(e.target.value)}
                                            style={{height: '40px'}}
                                        />
                                        <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            :
                ''
            }

            <div className="d-flex align-items-center align-self-end mt-3 mt-lg-0">
                { (
                    pageName === 'presupuestos' || 
                    pageName === 'ecommerce' || 
                    pageName === 'ventas' || 
                    pageName === 'devoluciones' || 
                    pageName === 'incidencias' || 
                    pageName === 'envios'
                ) ?
                    <div className="d-flex me-3 gap-2 align-items-center">
                        {getMenuItemTooltipValue() &&
                            <CustomTooltip
                                title={<p className="m-0">{getMenuItemTooltipValue()}</p>}
                            >
                                <span style={{cursor: 'pointer'}}>
                                    <FontAwesomeIcon icon={faInfoCircle} size="lg" className="action" color="#215731" /> 
                                </span>
                            </CustomTooltip>
                        }
                        <Select
                            size="small"
                            name="key"
                            id="key"
                            onChange={handleChangeKey}
                            value={key ? key : "presupuesto"}>
                                { (pageName === 'incidencias') &&
                                    <MenuItem 
                                        value={"incidencia"}>
                                        {"Incidencia"}
                                    </MenuItem>
                                }
                                <MenuItem 
                                    value={"presupuesto"}>
                                    {"Presupuesto"}
                                </MenuItem>
                                <MenuItem 
                                    value={"cliente"}>
                                    {"Cliente"}
                                </MenuItem>
                                { (pageName === 'devoluciones') &&
                                    <MenuItem 
                                        value={"devolucion"}>
                                        {"Devolución"}
                                    </MenuItem>
                                }
                                { (
                                    pageName === 'envios' ||
                                    pageName === 'ventas' ||
                                    pageName === 'presupuestos' || 
                                    pageName === 'incidencias'
                                ) &&
                                    <MenuItem 
                                        value={"proveedor"}>
                                        {"Proveedor"}
                                    </MenuItem>
                                }
                                <MenuItem 
                                    value={"pieza"}>
                                    {"Pieza"}
                                </MenuItem>
                                { (
                                    pageName === 'ventas' || 
                                    pageName === 'incidencias' ||
                                    pageName === 'devoluciones'
                                ) &&
                                    <MenuItem 
                                        value={"factura"}>
                                        {"Factura/Albarán"}
                                    </MenuItem>
                                }
                                { (pageName === 'ventas' || pageName === 'envios') &&
                                    <MenuItem 
                                        value={"envio"}>
                                        {"Envio"}
                                    </MenuItem>
                                }
                                { (pageName === 'ventas') &&
                                    <MenuItem 
                                        value={"codigo_pedido"}>
                                        {"Código de pedido"}
                                    </MenuItem>
                                }
                        </Select>
                    </div>
                    : ''
                }

                <div className="position-relative">
                    <input 
                        type="text" 
                        name="searcher" 
                        className="form-control" 
                        value={search}
                        placeholder={'Palabras clave a buscar'}
                        onChange={handleChange}
                        onKeyPress={e => {
                            if (e.key === 'Enter') {
                                handleSearch();
                            }}}  
                        />
                </div>

                <span className="d-block ms-3" title="Buscar" style={{cursor: 'pointer'}} onClick={() => handleSearch()}>
                    <FontAwesomeIcon icon={faSearch} color="#215732" className="icon" />
                </span>

                <span className="d-block ms-3" title="Reset filtros" style={{cursor: 'pointer'}} onClick={() => onReset(true)}>
                    <FontAwesomeIcon icon={faFilterCircleXmark} color="#dc3545" className="icon" />
                </span>
            </div>
        </div>
    );
}