import API from "../../../../../services/api";

export const getPlantillasEmail = async () => {
  return await API.get("/plantillas-email").then((res) => res.data);
};

export const addPlantillaEmail = async (data) => {
  return await API.post("/plantillas-email", data).then((res) => res.data);
};

export const editPlantillaEmail = async (id, data) => {
  return await API.put(`/plantillas-email/${id}`, data).then(
    (res) => res.data
  );
};
