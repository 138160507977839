import { useState, useEffect, useRef } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from '../shared/components/Header';
import { cambiarResponsable, cambiarSubestado, exportCulpaRecomotor, exportPerdidas, updateIncidencia, uploadDocuments } from "../shared/services/incidencias";
import InfoModal from "../shared/components/InfoModal";
import MultiFileUploadForm from '../shared/components/MultiFileUploadForm';
import { cambiarAactiva, cambiarAfinalizada, cambiarAfinanzas, fetchIncidencias, removeIncidencia } from "../../../../redux/incidencias";
import { generateFacturaPDF } from "../shared/services/facturas";
import Avisos from "./components/Avisos";
import { editAviso, saveAviso } from "../../../../redux/features/avisosList";
import ConfirmModal from "../shared/components/ConfirmModal";
import ExportForm from "../shared/components/ExportForm";
import EnvioForm from "../Envios/components/EnvioForm";
import { newEnvio } from "../shared/services/envios";
import { generateAlbaranPDF } from "../shared/services/albaranes";
import Loading from "../shared/components/Loading";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import IncidenciaTable from "./components/IncidenciaTable";
import SubestadoForm from "./components/SubestadoForm";
import AvisoForm from "./components/shared/AvisoForm";
import ResponsableForm from "./components/ResponsableForm";
import RecambistasReports from "./components/RecambistasReports";
import IncidenciaForm from "./components/IncidenciaForm";
import { isBoolean } from "../shared/helpers/functionalities";
import FinanzasModal from "./components/FinanzasModal";

export default function Incidencias() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();
  const currPage = parseInt(page); 
  const [currentPage, setPage] = useState(currPage);
  const currTab = localStorage.getItem('incidencias-tab') ? localStorage.getItem('incidencias-tab') : 'encurso';
  const user = JSON.parse(localStorage.getItem('user'));
  const firstRender = useRef(true);
  const [tab, setTab] = useState(currTab ? currTab : 'encurso');
  const [incidenciaId, setIncidenciaId] = useState(null);
  const [currentIncidencia, setCurrentIncidencia] = useState(null);
  const [viewAvisos, setViewAvisos] = useState(false);
  const [isExportPerdidas, setIsExportPerdidas] = useState(false);
  const [isExportCulpa, setIsExportCulpa] = useState(false);
  const [isEditAviso, setIsEditAviso] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [isForDelete, setIsForDelete] = useState(false);
  const [isForFinanzas, setIsForFinanzas] = useState(false);
  const [isOpenNewEnvio, setIsOpenNewEnvio] = useState(false);
  const [isOpenChangeSubestado, setIsOpenChangeSubestado] = useState(false);
  const [isOpenAviso, setIsOpenAviso] = useState(false);
  const [isOpenChangeResponsable, setIsOpenChangeResponsable] = useState(false);
  const [isEditIncidencia, setIsEditIncidencia] = useState(false);
  const incidencias = useSelector(state => {
    return state.incidencias.entities
  });
  const incidenciasStatus = useSelector(state => {
    return state.incidencias.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchIncidencias(currentPage, getQuery(tab, firstRender.current && user.rol_id === 5 ? user.id : null)));

    if(firstRender.current) {
      firstRender.current = false;
      return;
    }
  }, [searcher]);


  const getQuery = (section = null, userId = null) => {
    let query;
    let isEmpty = true;

    if(searcher) {
      isEmpty = Object.values(searcher).every(x => x === null || x === '');
      query = !isEmpty ? `&key=${searcher.key}&search=${searcher?.search ? searcher?.search : ''}`
        + (searcher?.start_date ? '&start_date=' + searcher?.start_date : '')
        + (searcher?.end_date ? '&end_date=' + searcher?.end_date : '')
        + ((searcher?.responsable && searcher?.responsable !== -1) ? '&responsable=' + searcher?.responsable : '')
        + ((searcher?.creado_por && searcher?.creado_por !== -1) ? '&creado_por=' + searcher?.creado_por : '')
        + ((searcher?.tipo && searcher?.tipo !== 'todos') ? '&tipo=' + searcher?.tipo : '')
        + ((searcher?.culpa && searcher?.culpa !== 'todos') ? '&culpa=' + searcher?.culpa : '')
        + ((searcher?.proveedor) ? '&proveedor=' + searcher?.proveedor.id : '')
        + ((searcher?.tipo_piezas && searcher?.tipo_piezas?.length > 0) ? '&tipo_piezas=' + searcher?.tipo_piezas.map(tp => tp.id)?.join(',') : '')
        + (isBoolean(searcher.solucion_proveedor) ? '&solucion_proveedor=' + searcher?.solucion_proveedor : '')
        + (isBoolean(searcher.solucion_cliente) ? '&solucion_cliente=' + searcher?.solucion_cliente : '')
      : null;
    }

    if(section) {
      if(!isEmpty) {
        query += '&seccion=' + section;
      } else {
        query = 'seccion=' + section;
      }
    }

    if(userId) {
      query += '&search=&creado_por=' + userId;
    }

    return query;
  }

  const handleChangeTab = (e, newTab) => { 
    setTab(newTab);
    setPage(1);
    navigate(`/incidencias/${1}`);
    dispatch(fetchIncidencias(1, getQuery(newTab)));
    localStorage.setItem('incidencias-tab', newTab);
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/incidencias/${1}`);
    dispatch({type: "search/set", payload: searchData});
  }

  const handleChangePage = (newPage, id) => {
    setPage(newPage);
    navigate(`/incidencias/${newPage}`);
    dispatch(fetchIncidencias(newPage, getQuery(tab)));
  }

  const handleReload = () =>  dispatch(fetchIncidencias(page, getQuery(tab)));
  
  const handleCloseUpload = () => setIsUpload(false);

  const handleCloseAviso = () => {
    setIsOpenAviso(false);
    setCurrentIncidencia(null);
  }
  const handleOpenAviso = (incidencia) => {
    setCurrentIncidencia(incidencia);
    setIsOpenAviso(true);
    setIsEditAviso(incidencia?.avisos?.length > 0 ? true : false);
  }

  const handleCreateAviso = (data) => {
    dispatch({type: 'loading/set', payload: true});

    if(isEditAviso) {
      dispatch(editAviso(data))
    } else {
      dispatch(saveAviso(data));
    }
   
    dispatch(fetchIncidencias(page[tab], getQuery(tab)));
    setIsOpenAviso(false);
  }

  const handleUpload = (incidenciaId) => {
    setIncidenciaId(incidenciaId);
    setIsUpload(true);
  }

  const handleUploadFiles = async (data) => {
    const files = data.files;
    let incidenciaFiles = new FormData();
    incidenciaFiles.append("id", incidenciaId);

    if(files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        incidenciaFiles.append(`files[${i}]`, files[i])
      }

      dispatch({type: 'loading/set', payload: true});
      const filesResult = await uploadDocuments(incidenciaId, incidenciaFiles);

      if(filesResult.success && filesResult.data) {
        dispatch({type: 'loading/set', payload: false});
        setIsUpload(false);
        toast.success(filesResult.message);
        dispatch(fetchIncidencias(page[tab], getQuery(tab)));
      } else {
        toast.error('Ups! Algo ha ido mal...');
      }
    }
  }

  const handleFinalizarIncidencia = async (incidenciaId) => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(cambiarAfinalizada(incidenciaId));
    dispatch(fetchIncidencias(page[tab], getQuery(tab)));
  }

  const handleReactivarIncidencia = async (incidenciaId) => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(cambiarAactiva(incidenciaId));
    dispatch(fetchIncidencias(page[tab], getQuery(tab)));
  }

  const generateThePdf = async (item, type) => {
    dispatch({type: 'loading/set', payload: true});
    let pdfResult;

    if(type === 'factura') {
      pdfResult =  await generateFacturaPDF(item.factura_id);
    } else {
      pdfResult = await generateAlbaranPDF(item.albaran_id);
    }
     
    if(pdfResult) {
      let pdfName = item.numero;
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', pdfName + '.pdf');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleExport = (item) => { 
    if(item === 'perdidas') {
      setIsExportPerdidas(true);
    } else { 
      setIsExportCulpa(true); 
    } 
  }
  const handleCloseExportPerdidas = () => { setIsExportPerdidas(false); }
  const handleCloseExportCulpa = () => { setIsExportCulpa(false); }
  const handleAvisos = () => { setViewAvisos(true); }
  const handleClose = () => { setViewAvisos(false); }
  const handleCloseNewEnvio = () => { setCurrentIncidencia(null); setIsOpenNewEnvio(false); }

  const handleOpenDeleteIncidencia = (incidenciaId) => {
    setIncidenciaId(incidenciaId);
    setIsForDelete(true);
  }
  const handleConfirmDelete = (confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: false});
      dispatch(removeIncidencia(incidenciaId));
      dispatch(fetchIncidencias(page[tab], getQuery(tab)));
    }

    setIsForDelete(false);
  }

  const handleOpenFinanzas = (incidencia) => {
    setCurrentIncidencia(incidencia);
    setIsForFinanzas(true);
  }

  const handleConfirmFinanzas = (data) => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(cambiarAfinanzas(data));
    dispatch(fetchIncidencias(page[tab], getQuery(tab)));
    setIsForFinanzas(false);
    setCurrentIncidencia(null);
  }
  
  const handleCloseFinanzas = () => {
    setIsForFinanzas(false);
    setCurrentIncidencia(null);
  }

  const handleExportPerdidas = async (data) => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await exportPerdidas(data);

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Listado de pérdidas descargado correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'perdidas_' + data.startDate + '_' + data.endDate + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleExportCulpaRecomotor = async (data) => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await exportCulpaRecomotor(data);

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Listado descargado correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'culpa_recomotor_' + data.startDate + '_' + data.endDate + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleOpenNewEnvio = (incidencia) => { 
    setIsOpenNewEnvio(true); 
    setCurrentIncidencia(incidencia); 
  }

  const handleNewEnvio = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await newEnvio(data);

    if(response.success) {
      dispatch({type: 'loading/set', payload: false});
      setIsOpenNewEnvio(false);
      setCurrentIncidencia(null);
      toast.success(response.message);
      navigate('/envios/1', { replace: true });
    }
  }

  const handleOpenChangeSubestado = (incidencia) => {
    setIsOpenChangeSubestado(true); 
    setCurrentIncidencia(incidencia); 
  } 

  const handleCloseChangeSubestado = () => {
    setIsOpenChangeSubestado(false); 
    setCurrentIncidencia(null); 
  }

  const handleChangeSubestado = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await cambiarSubestado(data);

    if(response.success) {
      dispatch({type: 'loading/set', payload: false});
      setIsOpenChangeSubestado(false);
      setCurrentIncidencia(null);
      toast.success(response.message);
      dispatch(fetchIncidencias(page[tab], getQuery(tab)));
    }
  }

  const handleOpenChangeResponsable = (incidencia) => {
    setCurrentIncidencia(incidencia);
    setIsOpenChangeResponsable(true);
  }
  const handleCloseChangeResponsable = () => {
    setIsOpenChangeResponsable(false);
    setCurrentIncidencia(null);
  }
  const handleChangeResponsable = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await cambiarResponsable(data);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseChangeResponsable();
      dispatch(fetchIncidencias(page[tab], getQuery(tab)));
    }
  }

  const handleOpenEdit = (incidencia) => {
    setCurrentIncidencia(incidencia);
    setIsEditIncidencia(true);
  }
  const handleCloseEdit = () => {
    setIsEditIncidencia(false);
    setCurrentIncidencia(null);
  }
  const handleEditIncidencia = async(data) => {
    const response = await updateIncidencia(currentIncidencia.id, data);

      if(response.success) {
          toast.success(response.message);
          handleCloseEdit();
          dispatch(fetchIncidencias(page[tab], getQuery(tab)));
      }
  }

  return (
    <div className="content pt-3">
      <Header 
        viewSearch={true}
        onSearch={handleSearch}
        onAvisos={handleAvisos}
        onExport={handleExport}
      ></Header>

      { (incidenciasStatus.loading === 'succeed' || incidenciasStatus.loading === 'rejected') ?
        <div className="w-100 mt-3">
          <TabContext value={tab}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChangeTab} aria-label="Estados de Incidencias">
              <Tab label="Pendiente de validación" value="pendiente" />
              <Tab label="En curso" value="encurso" />
              <Tab label="Finanzas" value="finanzas" />
              <Tab label="Archivadas" value="archivadas" />
              <Tab label="Recambistas" value="recambistas" />
            </TabList>
            </Box>
            <TabPanel value="pendiente">
                <IncidenciaTable 
                  id={'pendiente'}
                  currPage={currentPage}
                  incidencias={incidencias}
                  onPageChange={handleChangePage}
                  onGeneratePDF={generateThePdf}
                  onUpload={handleUpload}
                  onOpenSubEstados={handleOpenChangeSubestado}
                  onAviso={handleOpenAviso}
                  onFinalizar={handleFinalizarIncidencia}
                  onReactivar={handleReactivarIncidencia}
                  onFinanzas={handleOpenFinanzas}
                  onNewEnvio={handleOpenNewEnvio}
                  onDelete={handleOpenDeleteIncidencia}
                  onEdit={handleOpenEdit}
                />
            </TabPanel>
            <TabPanel value="encurso">
                <IncidenciaTable 
                  id={'encurso'}
                  currPage={currentPage}
                  incidencias={incidencias}
                  onPageChange={handleChangePage}
                  onGeneratePDF={generateThePdf}
                  onUpload={handleUpload}
                  onOpenSubEstados={handleOpenChangeSubestado}
                  onAviso={handleOpenAviso}
                  onFinalizar={handleFinalizarIncidencia}
                  onReactivar={handleReactivarIncidencia}
                  onFinanzas={handleOpenFinanzas}
                  onNewEnvio={handleOpenNewEnvio}
                  onDelete={handleOpenDeleteIncidencia}
                  onChangeResponsable={handleOpenChangeResponsable}
                  onEdit={handleOpenEdit}
                />
            </TabPanel>
            <TabPanel value="finanzas">
                <IncidenciaTable 
                  id={'finanzas'}
                  currPage={currPage}
                  incidencias={incidencias}
                  onPageChange={handleChangePage}
                  onGeneratePDF={generateThePdf}
                  onUpload={handleUpload}
                  onAviso={handleOpenAviso}
                  onFinalizar={handleFinalizarIncidencia}
                  onReactivar={handleReactivarIncidencia}
                  onFinanzas={handleOpenFinanzas}
                  onNewEnvio={handleOpenNewEnvio}
                  onDelete={handleOpenDeleteIncidencia}
                  onChangeResponsable={handleOpenChangeResponsable}
                  onEdit={handleOpenEdit}
                />
            </TabPanel>
            <TabPanel value="archivadas">
                <IncidenciaTable 
                  id={'archivadas'}
                  currPage={currentPage}
                  incidencias={incidencias}
                  onPageChange={handleChangePage}
                  onGeneratePDF={generateThePdf}
                  onUpload={handleUpload}
                  onAviso={handleOpenAviso}
                  onFinalizar={handleFinalizarIncidencia}
                  onReactivar={handleReactivarIncidencia}
                  onFinanzas={handleOpenFinanzas}
                  onNewEnvio={handleOpenNewEnvio}
                  onDelete={handleOpenDeleteIncidencia}
                  onChangeResponsable={handleOpenChangeResponsable}
                  onEdit={handleOpenEdit}
                />
            </TabPanel>
            <TabPanel value="recambistas">
              <RecambistasReports 
                reports={incidencias.reports}
                onReload={handleReload}
              />
            </TabPanel>
          </TabContext>
        </div>
        :
        <Loading />
      }

      { viewAvisos && 
        <InfoModal
          state={viewAvisos}
          title="Avisos"
          content={
            <Avisos />
          }
          width={'lg'}
          onClose={handleClose}
        ></InfoModal>
      }

      { isOpenAviso && 
        <InfoModal
          state={isOpenAviso}
          title={isEditAviso ? "Editar aviso" : "Nuevo aviso"}
          content={
            <AvisoForm 
              incidenciaId={currentIncidencia.id}
              incidencias={incidencias.activas}
              aviso={isEditAviso ? currentIncidencia?.avisos[0] : null}
              cancel={false}
              isEdit={isEditAviso}
              onSubmitAviso={handleCreateAviso}
            />
          }
          width={'lg'}
          onClose={handleCloseAviso}
        ></InfoModal>
      }

      { isUpload && 
        <InfoModal
          state={isUpload}
          title="Cargar documentación"
          content={
            <MultiFileUploadForm 
              onSubmit={handleUploadFiles} 
              itemId={incidenciaId} 
              labelText={"Documentos"} 
            />
          }
          onClose={handleCloseUpload}
        ></InfoModal>
      }

      { isForDelete && 
        <ConfirmModal 
            onConfirmAction={handleConfirmDelete} 
            title={'Eliminar'} 
            description={'Estás seguro de eliminar?'}
            state={isForDelete}>
        </ConfirmModal>
      }

      { isForFinanzas && 
        <FinanzasModal 
            incidencia={currentIncidencia}
            onConfirmAction={handleConfirmFinanzas} 
            onClose={handleCloseFinanzas}
            state={isForFinanzas}>
        </FinanzasModal>
      }

      { (isExportPerdidas) && 
        <InfoModal 
            state={isExportPerdidas}
          title="Exportación de pérdidas"
          content={
            <ExportForm 
              dateFilter={true}
              tipoClienteFilter={false}
              responsableFilter={false}
              perdidasFilter={true}
              onSubmitExport={handleExportPerdidas}
            />
          }
          onClose={handleCloseExportPerdidas}>
        </InfoModal>
      }

      { (isOpenChangeSubestado) && 
        <InfoModal 
            state={isOpenChangeSubestado}
          title="Cambiar subestado"
          content={
            <SubestadoForm 
              incidencia={currentIncidencia}
              onSubmitSubestado={handleChangeSubestado}
            />
          }
          onClose={handleCloseChangeSubestado}>
        </InfoModal>
      }

      { (isExportCulpa) && 
        <InfoModal 
            state={isExportCulpa}
            title="Exportación de culpa de Recomotor"
            content={
              <ExportForm 
                dateFilter={true}
                tipoClienteFilter={false}
                responsableFilter={false}
                perdidasFilter={false}
                onSubmitExport={handleExportCulpaRecomotor}
              />
          }
          onClose={handleCloseExportCulpa}>
        </InfoModal>
      }

      { isOpenNewEnvio && 
        <InfoModal 
          width={'lg'}
          state={isOpenNewEnvio}
          title={"Nuevo envío de incidencia " + currentIncidencia.numero}
          content={
            <EnvioForm 
              fromSection={'incidencia'}
              incidencia={currentIncidencia}
              onSubmitEnvio={handleNewEnvio}
            />
          }
          onClose={handleCloseNewEnvio}>
        </InfoModal>
      }

      { (isOpenChangeResponsable) &&
        <InfoModal 
          width={'sm'}
          state={isOpenChangeResponsable}
          title={"Cambiar responsable de la incidéncia " + currentIncidencia.numero}
          content={
            <ResponsableForm 
              incidencia={currentIncidencia}
              onSubmitResponsable={handleChangeResponsable}
            />
          }
          onClose={handleCloseChangeResponsable}>
        </InfoModal>
      }

      { (isEditIncidencia) &&
        <InfoModal 
          width={'sm'}
          state={isEditIncidencia}
          title={"Editar incidéncia " + currentIncidencia.numero}
          content={
            <IncidenciaForm 
              incidencia={currentIncidencia}
              onSubmitIncidencia={handleEditIncidencia}
            />
          }
          onClose={handleCloseEdit}>
        </InfoModal>
      }
    </div>
  )
}