import { useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  addPlantillaEmail,
  editPlantillaEmail,
} from "../../../../../shared/services/plantillasEmail";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { CustomTooltip } from "../../../../../shared/helpers/functionalities";

export default function PlantillaEmailForm({ plantillaEmail, onActionDone }) {
  /** Variables **/
  const { register, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      id: plantillaEmail?.id || null,
      nombre: plantillaEmail?.nombre || "",
      contenido: plantillaEmail?.contenido || "",
    },
  });

  /** Redux **/
  const dispatch = useDispatch();

  /** Lifecycle **/
  useEffect(() => {
    reset(plantillaEmail);
  }, [plantillaEmail]);

  /** Methods **/
  const handleSubmitPlantillaEmail = async (data, e) => {
    e.preventDefault();
    dispatch({ type: "loading/set", payload: true });

    let response;

    if (plantillaEmail) {
      const payload = {
        nombre: data.nombre,
        contenido: data.contenido,
        active: true,
      };
      response = await editPlantillaEmail(data.id, payload);
    } else {
      const payload = {
        nombre: data.nombre,
        contenido: data.contenido,
      };
      response = await addPlantillaEmail(payload);
    }

    if (response && response.success) {
      dispatch({ type: "loading/set", payload: false });
      toast.success(response.message);
      onActionDone();
    }
  };

  /** Render **/
  return (
    <form onSubmit={handleSubmit(handleSubmitPlantillaEmail)}>
      <div className="d-flex flex-column gap-3">
        <input
          type="hidden"
          {...register("id")}
          defaultValue={plantillaEmail?.id || ""}
        />

        <div className="d-flex flex-column">
          <label htmlFor="nombre">Nombre</label>
          <input
            type="text"
            {...register("nombre")}
            defaultValue={plantillaEmail?.nombre || ""}
          />
        </div>

        <div>
          <label htmlFor="contenido">Contenido</label>
          <textarea
            {...register("contenido")}
            defaultValue={plantillaEmail?.contenido || ""}
            rows={15}
          />
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <CustomTooltip
            title={
              <Fragment>
                <h3 className="mb-2">Leyenda</h3>
                <p><strong>$TOTAL_SIN_IVA</strong> - Precio total sin iva.</p>
                <p><strong>$ENVIO</strong> - Precio del transporte.</p>
                <p><strong>$TOTAL_CON_IVA</strong> - Precio total con iva.</p>
                <p><strong>$LINK_CHECKOUT</strong> - Enlace al checkout.</p>
                <p><strong>$CREATOR_NAME</strong> - Nombre del creador del presupuesto.</p>
                <p><strong>$CASCO</strong> - Precio del casco.</p>
              </Fragment>
            }
          >
            <span style={{ cursor: "pointer" }}>
              <FontAwesomeIcon
                icon={faInfoCircle}
                size="lg"
                className="action ms-3"
                color="#215731"
              />
            </span>
          </CustomTooltip>
          <button type="submit" className="btn btn-primary">
            Guardar
          </button>
        </div>
      </div>
    </form>
  );
}
