import { useState, useEffect } from "react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import Header from '../shared/components/Header'
import { useDispatch, useSelector } from "react-redux";
import { fetchDesguacesOffline } from "../../../../redux/desguaces_offline";
import Loading from "../shared/components/Loading";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";
import { toast } from "react-toastify";
import { setErrorMessage } from "../shared/helpers/functionalities";
import { updateDesguaceOffline } from "../shared/services/desguaces_offline";

export default function DesguacesOffline() {
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate();
  const [currentPage, setPage] = useState(currPage);
  const desguacesOffline = useSelector(state => {
    return state.desguaces_offline.entities
  });
  const status = useSelector(state => {
    return state.desguaces_offline.status
  });
  const searcher = useSelector(state => {
    return state.search
  })
  
  useEffect(() => {
    dispatch(fetchDesguacesOffline(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    const query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '')
      : null;

    return query;
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/desguaces-offline/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/desguaces-offline/${newPage}`);
    dispatch(fetchDesguacesOffline(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = desguacesOffline.total_items < 15 ? 1 : Math.ceil(desguacesOffline.total_items / 15);
    return parseInt(totalPages);
  }

  const handleViewSolicitudes = () => navigate('/solicitudes/1', {replace: true} )

  const handleAction = (action, desguace) => {
    if(action === 'enable') {
      handleToggle(desguace, true)
    } else if(action === 'disable') {
      handleToggle(desguace, false)
    }
  }

  const handleToggle = async(desguace, enable) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await updateDesguaceOffline({id: desguace.id, activo: enable})
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message)
      dispatch(fetchDesguacesOffline(currentPage, getQuery()));
    }
  }

  return (
    <div className="content">
      <Header 
        viewSearch={true}
        onSearch={handleSearch}
      ></Header>

      <div className="w-100 d-flex flex-column align-items-end mt-4">
        <div className="d-flex align-items-center">
            <button className="btn btn-default" onClick={handleViewSolicitudes}>Solicitudes</button>
        </div>

        { (status.loading === 'succeed' || status.loading === 'rejected') ?
          <>
            <div className="w-100 content-table">
                <div className="table-responsive">
                    <table className="table">
                    <thead>
                        <tr>
                          <th>nombre</th>
                          <th>email</th>
                          <th>email2</th>
                          <th className="text-center">telefono</th>
                          <th className="text-center">telefono 2</th>
                          <th className="text-center">activo</th>
                          <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        { desguacesOffline.desguaces_offline?.map((desguace) => {
                            return (
                                <tr key={desguace.id}>
                                    <td className="text-uppercase">{desguace.nombre}</td>
                                    <td>{desguace.email}</td>
                                    <td>{desguace.email2 ? desguace.email2 : '-'}</td>
                                    <td className="text-center">{desguace.telefono}</td>
                                    <td className="text-center">{desguace.telefono2}</td>
                                    <td className="text-center">
                                      <span className={desguace.activo ? 'text-green' : 'text-danger'}>
                                        <b>{desguace.activo ? 'SI' : 'NO'}</b>
                                      </span>
                                    </td>
                                    <td className="text-center">
                                      <ActionsMenu 
                                          options={getActions(desguace)}
                                          onAction={(action) => handleAction(action, desguace)}
                                      />
                                    </td>
                                </tr>
                            )
                        }) 
                        }
                        </tbody>
                    </table>
                </div>
            </div>

            <Pagination 
                className="mt-3" 
                count={getPaginationCount()} 
                page={currentPage} 
                onChange={handleChangePage} 
            />
          </>
          :
          <Loading />
        }
      </div>
    </div>
  )
}