import { useState, useEffect } from "react";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { downloadDocumentacion } from "../../../../services/clientes";
import GooglePlacesAutocomplete from "../../../GooglePlacesAutocomplete";
import { handleSetDireccion } from "../../../../helpers/functionalities";

export default function ClienteForm({
    cliente,
    telefono,
    defaultClient,
    onChangeClient,
    onSubmitClient,
}) {
    const dispatch = useDispatch();
    const clienteSchema = yup.object({
        nombre: yup.string().nullable(true),
        cif: yup.string().nullable().transform((_, val) => val ? val : null) ,
        telefono: yup.string().nullable().required("El teléfono es obligatorio"),
        email: yup.string().nullable(),
        direccion_facturacion:  yup.string().nullable(),
        codigo_postal_facturacion: yup.string().nullable(),
        poblacion_facturacion: yup.string().nullable(),
        area_facturacion: yup.string().nullable(),
        pais_facturacion_id: yup.number().nullable().integer()
    }).required();

    const { 
        register, 
        formState: { errors }, 
        handleSubmit, 
        setValue, 
        getValues,
        watch,
        reset, 
        setError,
    } = useForm({
            mode: "onChange",
            resolver: yupResolver(clienteSchema),
            defaultValues: {
                id: '',
                nombre: '',
                email: '',
                email2: '',
                cif: '',
                iban: '',
                tipo_banco_id: null,
                telefono: telefono ? telefono : '',
                tipo_cliente_id: 1,
                tipo_cliente_b2b_id: 1,
                tarifa_cliente_id: 1,
                direccion_facturacion: '',
                codigo_postal_facturacion: '',
                poblacion_facturacion: '',
                area_facturacion: '',
                pais_facturacion_id: 1,
            }
    });
    const paises = useSelector(state => {
        const { paises } = state;
        return paises;
    });
    const tipoClientes = useSelector(state => {
        const { tipo_clientes } = state;
        return tipo_clientes;
    });
    const tipoClientesB2b = useSelector(state => {
        const { tipo_clientes_b2b } = state;
        return tipo_clientes_b2b;
    });
    const tipoVencimientos = useSelector(state => {
        const { tipo_vencimientos } = state;
        return tipo_vencimientos;
    });
    const tipoBancos = useSelector(state => {
        const { tipo_bancos } = state;
        return tipo_bancos;
    });
    const tarifas = useSelector(state => {
        const { tarifas } = state;
        return tarifas;
    });
    const user = JSON.parse(localStorage.getItem('user'));
    const [ currentCliente, setCurrentCliente ] = useState({});
    const [ filterTarifas, setFilterTarifas ] = useState(tarifas);
    const [ isCredito ] = useState(false);
    const [ isCuentaGrande ] = useState(false);
    const [ isB2bClient, setIsB2bClient ] = useState(true);
    const [ isEdit, setIsEdit ] = useState(false);

    const formValues = watch();

    useEffect(() => {
        onChangeClient(formValues);
    }, [formValues]);

    useEffect(() => {
        setIsB2bClient(formValues.tipo_cliente_id === 2 ? true : false);
    }, [formValues]);
 
    useEffect(() => {
        if(telefono) setValue('telefono', telefono);

        if(cliente) {
            cliente = {...cliente, ...defaultClient};
            setIsEdit(true);
            setCurrentCliente(cliente);
            setIsB2bClient(cliente && parseInt(cliente.tipo_cliente_id) === 2 ? true : false);
            reset(cliente);
        } else resetValues();
    }, [cliente, telefono, defaultClient]);

    const resetValues = () => {
        reset({
            id: '',
            nombre: '',
            email: '',
            email2: '',
            email_albaranes: '',
            cif: '',
            iban: '',
            tipo_banco_id: null,
            telefono: telefono ? telefono : '',
            tipo_cliente_id: 2,
            tipo_cliente_b2b_id: 1,
            tarifa_cliente_id: 2,
            direccion_facturacion: '',
            codigo_postal_facturacion: '',
            poblacion_facturacion: '',
            area_facturacion: '',
            pais_facturacion_id: 1,
            ...defaultClient
        });
    }

    const handleChangePaisFacturacion = async(e) => {
        const value = parseInt(e.target.value);

        if(value !== getValues('pais_facturacion_id')) {
            setValue('codigo_postal_facturacion', '')
            setValue('direccion_facturacion', '')
            setValue('poblacion_facturacion', '')
            setValue('area_facturacion', '')
        }

        setValue('pais_facturacion_id', value);
    }

    const handleChangeTipoClientes = (e) => {
        setValue('tipo_cliente_id', parseInt(e.target.value));

        var t = tarifas.filter(f => f.tipo_cliente_id === parseInt(e.target.value));
        setFilterTarifas(t);

        if(parseInt(e.target.value) === 2) setIsB2bClient(true); 
        else setIsB2bClient(false);
    }

    const handleChangeTipoClientesB2b = (e) => setValue('tipo_cliente_b2b_id', e.target.value);
    const handleChangeTipoVencimiento = (e) => setValue('tipo_vencimiento_id', e.target.value);
    const handleChangeTarifa = (e) => setValue('tarifa_cliente_id', parseInt(e.target.value));
    const handleChangeTipoBanco = (e) => setValue('tipo_banco_id', parseInt(e.target.value));

    const onSubmit = async (data, e) => {
        e.preventDefault();
        if(!checkIfNameIsNotEmail(data.nombre)) return
        let dataAux;

        if(data.cif_ue && (data.pais_facturacion_id === 1 || data.pais_facturacion_id === '1')) {
            data.cif_ue = false;
        }

        if(data.tipo_cliente_id) {
            if(parseInt(data.tipo_cliente_id) === 2) data.b2b = true;
            else {
                data.b2b = false;
                data.tipo_cliente_b2b_id = null;
            }
        }

        if(!data.credito) {
            data.albaranes = false;
        }

        if(data.sepa_file?.length > 0) {
            dataAux = new FormData();

            Object.entries(data).forEach((value) => {
                if((value[0] === 'sepa_file') && value[1]?.length > 0) {
                    dataAux.append(value[0], value[1][0])
                } else { dataAux.append(value[0], value[1]) }
            });
        } else { dataAux = data; }

        onSubmitClient(dataAux);
    };

    const handleDownloadDocumentacion = async(doc) => {
        dispatch({type: 'loading/set', payload: true});
        const exportResult = await downloadDocumentacion(doc);

        if(exportResult) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('Documento descargado correctamente!')
            
            const url = window.URL.createObjectURL(new Blob([exportResult]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', doc);
            document.body.appendChild(link);
            link.click();
        } else {
            toast.error('Ups! Algo ha ido mal...')
        }
    }

    const handleChangeDireccion = (direccion) => {
        const newValues = handleSetDireccion(direccion, 'cliente');
        Object.keys(newValues).forEach(function(key) {
            setValue(key, newValues[key])
        });
    }

    const checkIfNameIsNotEmail = (email) => {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const isEmail = regex.test(email.trim().toLowerCase())
        if(isEmail) {
            setError('nombre', {type: 'valid', message: 'El nombre no puede ser un email.'})
            return false;
        }
        return true;
    };

    return (
        <div className="w-100">
            <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
                <div className="col-12 align-items-center mt-2 mb-4">
                    <div className="w-100 table-responsive">
                        <table className="table">
                            <tbody>
                                { (user.rol_id === 1 || user.rol_id === 4 || user.responsable) ?
                                    <tr>
                                        <td width="5%" className="text-center">
                                            <input 
                                                type="checkbox"
                                                {...register("bloqueado")}
                                                defaultChecked={false} />
                                        </td>
                                        <td>
                                            <label className="me-3">Bloqueado?</label>
                                        </td>
                                    </tr>
                                    : null
                                }
                                { (user.rol_id === 1 || user.rol_id === 4 || user.responsable) ?
                                    <tr>
                                        <td width="5%" className="text-center">
                                            <input 
                                                type="checkbox"
                                                {...register("credito")} 
                                                disabled={isCredito || watch('bloqueado')}
                                                defaultChecked={false} />
                                        </td>
                                        <td>
                                            <label className="me-3">El cliente paga a crédito?</label>
                                        </td>
                                    </tr>
                                    : null
                                }
                                { ((user.rol_id === 1 || user.rol_id === 4 || user.responsable) && watch('credito')) ?
                                    <tr>
                                        <td colSpan={2}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("con_codigo")}
                                                        disabled={watch('bloqueado')}
                                                        style={{marginLeft: '2px'}}
                                                        defaultChecked={isEdit ? currentCliente.con_codigo : true} />
                                                    <label className="ms-4">Código de autorización</label>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("albaranes")}
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={isEdit ? (currentCliente.albaranes === null ? false : currentCliente.albaranes) : true} />
                                                    <label className="ms-4">Albaranes (por defecto SI)</label>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("mostrar_referencia")}
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={isEdit ? (currentCliente.mostrar_referencia) : false} />
                                                    <label className="ms-4">Mostrar referencia? (por defecto NO)</label>
                                                </div>

                                                <div className="d-flex align-items-center">
                                                    <input 
                                                        type="checkbox"
                                                        {...register("sepa")}
                                                        disabled={watch('bloqueado')}
                                                        defaultChecked={false} />
                                                    <label className="ms-4">Es SEPA?</label>
                                                </div>
                                            </div>
                                            
                                        </td>
                                    </tr>
                                    : null
                                }
                                { ((user.rol_id === 1 || user.rol_id === 4 || user.responsable)) ?
                                    <tr>
                                        <td width="5%" className="text-center">
                                            <input 
                                                type="checkbox"
                                                {...register("aviso_incidencias")}
                                                disabled={watch('bloqueado')}
                                                defaultChecked={isEdit ? currentCliente.aviso_incidencias : true} />
                                        </td>
                                        <td>
                                            <label className="me-3">Aviso a incidencias? (por defecto SI)</label>
                                        </td>
                                    </tr>
                                    : null
                                }
                                { (user.rol_id === 1 || user.rol_id === 4 || user.responsable) ?
                                    <tr>
                                        <td width="5%" className="text-center">
                                            <input 
                                                type="checkbox"
                                                {...register("cuenta_grande")} 
                                                disabled={isCuentaGrande || watch('bloqueado')}
                                                defaultChecked={false} />
                                        </td>
                                        <td>
                                            <label className="me-3">Es una cuenta grande?</label>
                                        </td>
                                    </tr>
                                    : null
                                }  
                                { watch('pais_facturacion_id') !== 1 && watch('pais_facturacion_id') !== '1' ? 
                                    <tr>
                                        <td width="5%" className="text-center">
                                            <input
                                                type="checkbox"
                                                {...register("cif_ue")} 
                                                disabled
                                                defaultChecked={cliente?.cif_ue ? true : false} />
                                        </td>
                                        <td>
                                            <label className="me-3">NIF Intracomunitario</label>
                                        </td>
                                    </tr>
                                    : null
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="w-100 d-flex flex-wrap flex-md-nowrap">
                <form className="form col-12 mb-4" onSubmit={handleSubmit(onSubmit)}>
                    <input 
                        type="hidden" 
                        {...register("id")} 
                        defaultValue={isEdit ? currentCliente.id : ''} />

                    <div className="w-100 d-flex flex-column">
                        <div className="w-100 d-flex">
                            <div className="w-100 d-flex flex-column mt-2">
                                <h6 className="text-uppercase">Datos de facturación</h6>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="tipo_cliente_id">Tipo cliente</label>
                                        { (tipoClientes && tipoClientes.length > 0) &&
                                            <select 
                                                {...register("tipo_cliente_id", {required: true})}
                                                defaultValue={isEdit ? currentCliente.tipo_cliente_id : 1}
                                                disabled={watch('bloqueado')}
                                                onChange={handleChangeTipoClientes}>
                                                {tipoClientes.map(op => <option key={'tipo-clientes-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                        }
                                    </div>

                                    { isB2bClient &&
                                    <div className="form-group me-3">
                                        <label htmlFor="tipo_cliente_b2b_id">Tipo cliente B2B</label>
                                        { (tipoClientesB2b && tipoClientesB2b.length > 0) &&
                                            <select 
                                                {...register("tipo_cliente_b2b_id", {required: true})}
                                                defaultValue={isEdit ? currentCliente.tipo_cliente_b2b_id : 1}
                                                disabled={watch('bloqueado')}
                                                onChange={handleChangeTipoClientesB2b}>
                                                {tipoClientesB2b.map(op => <option key={'tipo-clientes-b2b-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                        }
                                    </div>
                                    }

                                    <div className="form-group">
                                        <label htmlFor="tarifa_cliente_id">Tarifa</label>
                                        { (filterTarifas) &&
                                            <select 
                                                {...register("tarifa_cliente_id", {required: true})}
                                                disabled={watch('bloqueado')}
                                                defaultValue={isEdit ? currentCliente.tarifa_cliente_id : 1}
                                                onChange={handleChangeTarifa}>
                                                {filterTarifas.map(op => <option key={'tipo-tarifa-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                        }
                                    </div>
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="nombre">Nombre</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            {...register("nombre", { maxLength: 50 })} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? currentCliente.nombre : ''} />
                                        
                                        <div className="form-invalid">
                                            {(errors.nombre) && errors.nombre?.message}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="cif">CIF / NIF</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            maxLength={16}
                                            {...register("cif", { maxLength: 16 })} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? currentCliente.cif : ''} />

                                        <div className="form-invalid">
                                            {(errors.cif) && errors.cif?.message}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="telefono">Teléfono</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            {...register("telefono", { required: true })} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? currentCliente.telefono : ''} />

                                        <div className="form-invalid">
                                            {(errors.telefono) && errors.telefono?.message}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="email">Email</label>
                                        <input 
                                            type="email" 
                                            className="form-control" 
                                            {...register("email", {  maxLength: 150 })} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? currentCliente.email : ''} />

                                        <div className="form-invalid">
                                            {(errors.email) && errors.email?.message}
                                        </div>
                                    </div>

                                    { ((currentCliente.tipo_cliente_b2b_id !== 2 && currentCliente?.credito && currentCliente?.albaranes) || isCredito) &&
                                        <div className="form-group ms-3">
                                            <label htmlFor="email">Email albaranes</label>
                                            <input 
                                                type="email" 
                                                className="form-control" 
                                                {...register("email_albaranes", {  maxLength: 150 })} 
                                                disabled={watch('bloqueado')}
                                                defaultValue={isEdit ? currentCliente.email_albaranes : ''} />

                                            <div className="form-invalid">
                                                {(errors.email_albaranes) && errors.email_albaranes?.message}
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="form-group">
                                        <label htmlFor="email">Email 2 (separados por , )</label>
                                        <textarea 
                                            type="email2" 
                                            className="form-control" 
                                            {...register("email2")} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? currentCliente.email2 : ''}></textarea>
                                    </div>
                                </div>

                                { ((user.rol_id === 1 || user.rol_id === 4 || user.responsable) && watch('credito')) ?
                                    <div className="d-flex align-items-center my-3">
                                        { watch('sepa') ?
                                            <div className="form-group me-3">
                                                <label htmlFor="sepa_file" className="w-100 d-flex align-items-center justify-content-between">
                                                    Documento SEPA
                                                    { (isEdit && currentCliente?.doc_sepa) ?
                                                        <span title="Documento SEPA" onClick={() => handleDownloadDocumentacion(currentCliente.doc_sepa)}>
                                                            <FontAwesomeIcon icon={faFilePdf} size="lg" className="action" color="#215732"></FontAwesomeIcon>
                                                        </span>
                                                        : null
                                                    }
                                                </label>
                                                <input 
                                                    type="file" 
                                                    className="form-control" 
                                                    disabled={watch('bloqueado')}
                                                    {...register("sepa_file")} 
                                                    defaultValue={''} />
                                            </div>
                                            : null
                                        }

                                        <div className="form-group">
                                            <label htmlFor="tipo_cliente_id">Tipo Vencimiento</label>
                                            { (tipoVencimientos && tipoVencimientos.length > 0) &&
                                                <select 
                                                    {...register("tipo_vencimiento_id", {required: true})}
                                                    disabled={watch('bloqueado')}
                                                    defaultValue={isEdit ? currentCliente.tipo_vencimiento_id : 1}
                                                    onChange={handleChangeTipoVencimiento}>
                                                    {tipoVencimientos.map(op => <option key={'tipo-vencimiento-' + op.id} value={op.id}>{op.duracion}</option>)}
                                                </select>
                                            }
                                        </div>
                                    </div>
                                    : null
                                }

                                <h6 className="text-uppercase mt-3">Datos bancarios</h6>
                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="iban">IBAN</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            {...register("iban", { maxLength: 150 })} 
                                            disabled={watch('bloqueado')}
                                            defaultValue={isEdit ? currentCliente.iban : ''} />

                                        <div className="form-invalid">
                                            {(errors.iban) && errors.iban?.message}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="tipo_banco_id">Tipo Banco</label>
                                        { (tipoBancos && tipoBancos.length > 0) &&
                                            <select 
                                                {...register("tipo_banco_id", {required: true})}
                                                disabled={watch('bloqueado')}
                                                defaultValue={isEdit ? currentCliente.tipo_banco_id : 1}
                                                onChange={handleChangeTipoBanco}>
                                                {tipoBancos.map(op => <option key={'tipo-banco-' + op.id} value={op.id}>{op.nombre}</option>)}
                                            </select>
                                        }
                                    </div>
                                </div>

                                <h6 className="text-uppercase mt-3">Dirección de facturación</h6>

                                <div className="w-100 d-flex align-items-center mb-4">
                                    <div className="me-3">
                                        <select 
                                            {...register("pais_facturacion_id", {required: true})}
                                            defaultValue={isEdit ? currentCliente.pais_facturacion_id : 1}
                                            disabled={watch('bloqueado')}
                                            onChange={handleChangePaisFacturacion}>
                                            {paises?.map(op => <option key={'paises-fact-' + op.id} value={op.id}>{op.nombre}</option>)}
                                        </select>

                                        <div className="form-invalid">
                                            {(errors.pais_facturacion_id) && errors.pais_facturacion_id?.message}
                                        </div>
                                    </div>

                                    <GooglePlacesAutocomplete 
                                        type={'address'}
                                        disabled={watch('bloqueado')}
                                        types={['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair']}
                                        country={watch('pais_facturacion_id') ? paises?.find(p => p.id === parseInt(getValues('pais_facturacion_id')))?.codigo : 'ES'}
                                        onSelectResult={handleChangeDireccion}
                                   />
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="d-flex flex-column mb-3 me-3">
                                        <label htmlFor="direccion_facturacion" style={{marginBottom: '5px'}}>Código postal</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            disabled={watch('bloqueado')} 
                                            {...register("codigo_postal_facturacion", { maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.codigo_postal_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.codigo_postal_facturacion) && errors.codigo_postal_facturacion?.message}
                                        </div>
                                    </div>
                                    
                                    <div className="form-group">
                                        <label htmlFor="direccion_facturacion">Dirección</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            disabled={watch('bloqueado')}
                                            {...register("direccion_facturacion", { maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.direccion_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.direccion_facturacion) && errors.direccion_facturacion?.message}
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex align-items-center">
                                    <div className="form-group me-3">
                                        <label htmlFor="poblacion_facturacion">Población</label>
                                        <input 
                                            type="text" 
                                            className="form-control"
                                            disabled={watch('bloqueado')} 
                                            {...register("poblacion_facturacion", { maxLength: 150 })} 
                                            defaultValue={isEdit ? currentCliente.poblacion_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.poblacion_facturacion) && errors.poblacion_facturacion?.message}
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="direccion_facturacion">Area (Provincia, CCAA)</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            disabled={watch('bloqueado')} 
                                            placeholder="Provincia, CCAA"
                                            {...register("area_facturacion", { maxLength: 150 })} 

                                            defaultValue={isEdit ? currentCliente.area_facturacion : ''} />

                                        <div className="form-invalid">
                                            {(errors.area_facturacion) && errors.area_facturacion?.message}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex align-content-center align-self-end">
                        <button type="submit" className="btn btn-primary">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}