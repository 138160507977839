import { combineReducers } from "redux";
import { clientesReducer, clientesStatusReducer } from "./redux/clientes";
import { devolucionesReducer, devolucionesStatusReducer } from "./redux/devoluciones";
import { estadosIncidenciaFullfilledReducer } from "./redux/estadosIncidencia";
import { estadosVentaFullfilledReducer } from "./redux/estadosVenta";
import { facturasReducer, facturasStatusReducer } from "./redux/facturas";
import { familiaPiezaFullfilledReducer } from "./redux/familiaPiezas";
import { incidenciasReducer, incidenciasStatusReducer } from "./redux/incidencias";
import { marcasFullfilledReducer } from "./redux/marcas";
import { modelosFullfilledReducer } from "./redux/modelos";
import { paisesFullfilledReducer } from "./redux/paises";
import { piezasReducer, piezasStatusReducer } from "./redux/piezas";
import { plataformasFullfilledReducer } from "./redux/plataformas";
import { poblacionesFullfilledReducer } from "./redux/poblaciones";
import { presupuestosReducer, presupuestosStatusReducer } from "./redux/presupuestos";
import { proveedoresReducer, proveedoresStatusReducer } from "./redux/proveedores";
import { rolesFullfilledReducer } from "./redux/roles";
import { searchReducer } from "./redux/features/search";
import { tipoClientesFullfilledReducer } from "./redux/tipoClientes";
import { tipoClientesB2bFullfilledReducer } from "./redux/tipoClientesB2b";
import { tipoDevolucionesFullfilledReducer } from "./redux/tipoDevoluciones";
import { tipoFacturasFullfilledReducer } from "./redux/tipoFacturas";
import { tipoGarantiasFullfilledReducer } from "./redux/tipoGarantias";
import { tipoIncidenciasFullfilledReducer } from "./redux/tipoIncidencias";
import { tipoPiezasFullfilledReducer } from "./redux/tipoPiezas";
import { usersReducer, usersStatusReducer } from "./redux/users";
import { ventasReducer, ventasStatusReducer } from "./redux/ventas";
import { avisosReducer, avisosStatusReducer } from "./redux/avisos";
import { fichaIncidenciaReducer, currentIncidenciaStatusReducer } from "./redux/features/fichaIncidencia";
import { dashboardReducer, dashboardStatusReducer } from "./redux/features/dashboard";
import { currentClienteReducer, currentClienteStatusReducer } from "./redux/features/currentCliente";
import { currentUserReducer, currentUserStatusReducer } from "./redux/features/currentUser";
import { currentPiezaReducer } from "./redux/features/currentPieza";
import { currentProveedorReducer } from "./redux/features/currentProveedor";
import { fichaClienteReducer, fichaClienteStatusReducer } from "./redux/features/fichaCliente";
import { fichaProveedorReducer, fichaProveedorStatusReducer } from "./redux/features/fichaProveedor";
import { tarifasFullfilledReducer } from "./redux/tarifas";
import { stepsFullfilledReducer } from "./redux/incidenciaSteps";
import { avisosListReducer, avisosListStatusReducer } from "./redux/features/avisosList";
import { administracionFullfilledReducer, atencionClienteFullfilledReducer, comercialesFullfilledReducer, recambistasFullfilledReducer,  } from "./redux/features/tipoUsers";
import { readOnlyDataReducer, readOnlyDataStatusReducer } from "./redux/readOnly";
import { notificacionesReducer, notificacionesStatusReducer } from "./redux/features/notificaciones";
import { serviciosEnvioFullfilledReducer } from "./redux/serviciosEnvio";
import { transportistasFullfilledReducer } from "./redux/transportistas";
import { preciosEnvioPiezasFullfilledReducer } from "./redux/preciosEnviosPiezas";
import { enviosReducer, enviosStatusReducer } from "./redux/envios";
import { solicitudesReducer, solicitudesStatusReducer } from "./redux/solicitudes";
import { desguacesOfflineReducer, desguacesOfflineStatusReducer } from "./redux/desguaces_offline";
import { reconstruidosReducer, reconstruidosStatusReducer } from "./redux/reconstruidos";
import { albaranesReducer, albaranesStatusReducer } from "./redux/albaranes";
import { checkoutReducer, checkoutStatusReducer } from "./redux/features/checkout";
import { tipoFeedbackFullfilledReducer } from "./redux/tipoFeedback";
import { finanzasReducer, finanzasStatusReducer } from "./redux/features/finanzas";
import { comercialDetailReducer, comercialDetailStatusReducer } from "./redux/comercial_detail";
import { fichaPresupuestoReducer, fichaPresupuestoStatusReducer } from "./redux/features/fichaPresupuesto";
import { tarifasProveedorFullfilledReducer } from "./redux/tarifas_envio_proveedor";
import { avisosStockReducer, avisosStockStatusReducer } from "./redux/avisos_stock";
import { tipoVencimientoFullfilledReducer } from "./redux/tipoVencimientos";
import { subestadosIncidenciaFullfilledReducer } from "./redux/subestadosIncidencia";
import { recordatoriosClientesReducer, recordatoriosClientesStatusReducer } from "./redux/features/recordatoriosClientes";
import { loadingReducer } from "./redux/features/loading";
import { fichaSearchReducer } from "./redux/features/fichaSearch";
import { tipoBancosFullfilledReducer } from "./redux/tipoBancos";
import { notasCargoReducer, notasCargoStatusReducer } from "./redux/notasCargo";
import { autorizacionesReducer, autorizacionesStatusReducer } from "./redux/autorizacionesCredito";
import { reloadReducer } from "./redux/features/reload";
import { variablesFullfilledReducer } from "./redux/variables";
import { carroceriasPesadasFullfilledReducer } from "./redux/carroceriasPesadas";
import { plantillasEmailFullfilledReducer } from "./redux/plantillasEmail";

const reducer = combineReducers({
    // UTILITIES
    search: searchReducer,
    ficha_search: fichaSearchReducer,
    // DATA LIST
    albaranes: combineReducers({
        entities: albaranesReducer,
        status: albaranesStatusReducer,
    }),
    notas_cargo: combineReducers({
        entities: notasCargoReducer,
        status: notasCargoStatusReducer,
    }),
    avisos: combineReducers({
        entities: avisosReducer,
        status: avisosStatusReducer
    }),
    clientes: combineReducers({
        entities: clientesReducer,
        status: clientesStatusReducer,
    }),
    presupuestos: combineReducers({
        entities: presupuestosReducer,
        status: presupuestosStatusReducer,
    }),
    envios: combineReducers({
        entities: enviosReducer,
        status: enviosStatusReducer,
    }),
    devoluciones: combineReducers({
        entities: devolucionesReducer,
        status: devolucionesStatusReducer,
    }),
    facturas: combineReducers({
        entities: facturasReducer,
        status: facturasStatusReducer,
    }),
    incidencias: combineReducers({
        entities: incidenciasReducer,
        status: incidenciasStatusReducer,
    }),
    notificaciones: combineReducers({
        entities: notificacionesReducer,
        status: notificacionesStatusReducer,
    }),
    piezas: combineReducers({
        entities: piezasReducer,
        status: piezasStatusReducer,
    }),
    proveedores: combineReducers({
        entities: proveedoresReducer,
        status: proveedoresStatusReducer,
    }),
    detalles: combineReducers({
        entities: comercialDetailReducer,
        status: comercialDetailStatusReducer,
    }),
    users: combineReducers({
        entities: usersReducer,
        status: usersStatusReducer,
    }),
    ventas: combineReducers({
        entities: ventasReducer,
        status: ventasStatusReducer,
    }),
    solicitudes: combineReducers({
        entities: solicitudesReducer,
        status: solicitudesStatusReducer,
    }),
    desguaces_offline: combineReducers({
        entities: desguacesOfflineReducer,
        status: desguacesOfflineStatusReducer,
    }),
    reconstruidos: combineReducers({
        entities: reconstruidosReducer,
        status: reconstruidosStatusReducer,
    }),
    // READ ONLY DATA
    read_only: combineReducers({
        entities: readOnlyDataReducer,
        status: readOnlyDataStatusReducer,
    }),
    //-------------------------------
    tipo_clientes: tipoClientesFullfilledReducer,
    tipo_clientes_b2b: tipoClientesB2bFullfilledReducer,
    tipo_bancos: tipoBancosFullfilledReducer,
    poblaciones: poblacionesFullfilledReducer,
    paises: paisesFullfilledReducer,
    roles: rolesFullfilledReducer,
    estados_venta: estadosVentaFullfilledReducer,
    estados_incidencia: estadosIncidenciaFullfilledReducer,
    subestados_incidencia: subestadosIncidenciaFullfilledReducer,
    tipo_facturas: tipoFacturasFullfilledReducer,
    tipo_piezas: tipoPiezasFullfilledReducer,
    familia_piezas: familiaPiezaFullfilledReducer,
    tipo_garantias: tipoGarantiasFullfilledReducer,
    tipo_devoluciones: tipoDevolucionesFullfilledReducer,
    tipo_incidencias: tipoIncidenciasFullfilledReducer,
    tipo_feedback: tipoFeedbackFullfilledReducer,
    marcas: marcasFullfilledReducer,
    modelos: modelosFullfilledReducer,
    plataformas: plataformasFullfilledReducer,
    tarifas: tarifasFullfilledReducer,
    steps: stepsFullfilledReducer,
    transportistas: transportistasFullfilledReducer,
    servicios_envio: serviciosEnvioFullfilledReducer,
    precios_envios_piezas: preciosEnvioPiezasFullfilledReducer,
    tarifas_envio_proveedor: tarifasProveedorFullfilledReducer,
    tipo_vencimientos: tipoVencimientoFullfilledReducer,
    recambistas: recambistasFullfilledReducer,
    comerciales: comercialesFullfilledReducer,
    atencion_cliente: atencionClienteFullfilledReducer,
    administracion: administracionFullfilledReducer,
    variables: variablesFullfilledReducer,
    carrocerias_pesadas: carroceriasPesadasFullfilledReducer,
    plantillas_email: plantillasEmailFullfilledReducer,
    //-------------------------------
    // CURRENT DATA
    loading: loadingReducer,
    reload: reloadReducer,
    dashboard: combineReducers({
        entity: dashboardReducer,
        status: dashboardStatusReducer
    }),
    presupuesto: combineReducers({
        entity: fichaPresupuestoReducer,
        status: fichaPresupuestoStatusReducer
    }),
    currentIncidencia: combineReducers({
        entity: fichaIncidenciaReducer,
        status: currentIncidenciaStatusReducer
    }),
    currentCliente: combineReducers({
        entity: currentClienteReducer,
        status: currentClienteStatusReducer
    }),
    currentUser: combineReducers({
        entity: currentUserReducer,
        status: currentUserStatusReducer
    }),
    currentPieza: currentPiezaReducer,
    currentProveedor: currentProveedorReducer,
    fichaCliente: combineReducers({
        entity: fichaClienteReducer,
        status: fichaClienteStatusReducer
    }),
    fichaProveedor: combineReducers({
        entity: fichaProveedorReducer,
        status: fichaProveedorStatusReducer
    }),
    avisosList: combineReducers({
        entity: avisosListReducer,
        status: avisosListStatusReducer
    }),
    checkout: combineReducers({
        entity: checkoutReducer,
        status: checkoutStatusReducer
    }),
    finanzas: combineReducers({
        entity: finanzasReducer,
        status: finanzasStatusReducer,
    }),
    avisosStock: combineReducers({
        entities: avisosStockReducer,
        status: avisosStockStatusReducer,
    }),
    recordatorios: combineReducers({
        entities: recordatoriosClientesReducer,
        status: recordatoriosClientesStatusReducer
    }),
    autorizaciones: combineReducers({
        entities: autorizacionesReducer,
        status: autorizacionesStatusReducer
    }),
})

const rootReducer = reducer;

export default rootReducer;