import React from "react";
import { useForm } from "react-hook-form";

export default function Searcher({
    onSearch
 }) {
    const { register, formState: { errors }, handleSubmit: handleSubmitTelf } = useForm();
    
    const onSubmit = async (data, e) => {
        e.preventDefault();
        onSearch(data);
    };

    return (
        <div className="w-100 d-flex flex-column justify-content-center">
            <form className="d-flex align-self-center mt-2" onSubmit={handleSubmitTelf(onSubmit)}>
                <div className="form-group mb-0">
                    <input 
                        type="text" 
                        className="form-control" 
                        placeholder="Teléfono | CIF | Email"
                        {...register("buscador", { required: true })} 
                        defaultValue='' />

                    <div className="form-invalid">
                        {errors.buscador?.type === 'required' && "Tienes que buscar"}
                    </div>
                </div>

                <button type="submit" className="btn btn-primary ms-2" style={{maxHeight: "43px"}}>Comprovar</button>
            </form>
        </div>
    );
}