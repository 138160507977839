import React, { useEffect } from 'react';
import { DoughnutChart } from '../../../../../../../shared/components/Charts/components/Doughnut';
import VerticalBarChart from '../../../../../../../shared/components/Charts/components/VerticalBar';
import MetricsTable from '../../../shared/MetricsTable';

export default function ClientesMetrics({view, data}) {
    /** Methods **/
    const renderClientesB2BRecurrentes = (view, data) => {
        const isSpecialView = view === 'año' || view === 'año-variable' || view === 'ultimos-12meses';

        if (!isSpecialView) {
            if (typeof data?.clientes_b2b_recurrentes === 'object') {
                return (
                    <VerticalBarChart
                        title={'Talleres que han comprado +2 veces en los últimos 60 días'}
                        dataset={data.clientes_b2b_recurrentes}
                    />
                );
            } else {
                return (
                    <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap'>
                        <div className='w-100 w-md-100 card-item d-flex flex-column align-items-center'>
                            Talleres que han comprado +2 veces en los últimos 60 días
                            <h1>{data.clientes_b2b_recurrentes}</h1>
                        </div>
                    </div>
                );
            }
        }
    
        return <p className='text-center'>No hay datos.</p>;
    };

    /** Render **/
    return (
        <div className='w-100 d-flex flex-column align-items-center justify-content-between my-3'>
            <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className='col-12 col-md-4 pe-0 pe-md-3'>
                    { data?.tipo_clientes_chart ?
                        <DoughnutChart
                            title={'Clientes'}
                            dataset={data?.tipo_clientes_chart}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
                <div className='col-12 col-md-6 pe-0 pe-md-3 d-flex align-items-center justify-content-center'>
                    {renderClientesB2BRecurrentes(view, data)}
                </div>
            </div>

            <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_clientes ?
                        <MetricsTable 
                            titulo={"Top 5 Clientes anual"}
                            labels={['cliente', 'ultima venta', 'ventas', 'presupuestos', 'ticket medio']}
                            data={data.top_clientes}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>

                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_clientes_view ?
                        <MetricsTable 
                            titulo={"Mejores Clientes"}
                            labels={['cliente', 'ultima venta', 'ventas', 'presupuestos', 'ticket medio']}
                            data={data.top_clientes_view}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
            </div>

             <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.top_ratio_conversion ?
                        <MetricsTable 
                            titulo={"Top ratio de conversión"}
                            labels={['cliente', 'presupuestos', 'ventas', 'ratio']}
                            data={data.top_ratio_conversion}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>

                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.low_ratio_conversion ?
                        <MetricsTable 
                            titulo={"Menor ratio conversión"}
                            labels={['cliente', 'presupuestos', 'ventas', 'ratio']}
                            data={data.low_ratio_conversion}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
             </div>
             
             <div className='w-100 d-flex flex-wrap flex-md-nowrap justify-content-between'>
                <div className="col-12 col-md-6 pe-0 pe-md-3">
                    { data?.ultimas_ventas ?
                        <MetricsTable 
                            titulo={"Últimas ventas"}
                            labels={['cliente', 'fecha', 'importe']}
                            data={data.ultimas_ventas}
                        />
                        : <p className='text-center'>No hay datos.</p>
                    }
                </div>
             </div>
        </div>
    )
}