import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer} from 'react-toastify';
import { fetchReadOnlyData } from "../../redux/readOnly";

import Home from './components/Home';
import Navbar from "./components/Navbar";
import Users from './components/Users';
import UserDetail from "./components/Users/components/UserDetail";
import Clientes from './components/Clientes';
import Proveedores from './components/Proveedores';
import Presupuestos from './components/Presupuestos';
import Ventas from './components/Ventas';
import Devoluciones from './components/Devoluciones';
import Facturas from "./components/Facturas";
import DevolucionContainer from "./components/Devoluciones/components/DevolucionContainer";
import Incidencias from "./components/Incidencias";
import FichaIncidencia from "./components/Incidencias/components/FichaIncidencia";
import ClienteSheet from "./components/Clientes/components/ClienteSheet";
import ProveedorSheet from "./components/Proveedores/components/ProveedorSheet";
import Ajustes from "./components/Ajustes";
import Account from "./components/Account";
import FichaPresupuesto from "./components/Presupuestos/components/FichaPresupuesto";
import Envios from "./components/Envios";
import Solicitudes from "./components/Solicitudes";
import DesguacesOffline from "./components/DesguacesOffline";
import Reconstruidos from "./components/Reconstruidos";
import Albaranes from "./components/Albaranes";
import Comerciales from "./components/Comerciales";
import Finanzas from "./components/Finanzas";
import Stock from "./components/Stock";
import NewVersionModal from "./components/shared/components/NewVersionModal"
import RecordatoriosClientes from "./components/RecordatoriosClientes";
import LoadingBackdrop from "./components/shared/components/LoadingBackdrop";
import ReportModal from "./components/shared/components/ReportModal";
import NotasCargo from "./components/NotasCargo";
import TecDoc from './components/TecDoc';
import AutorizacionesCredito from "./components/AutorizacionesCredito";
import config from "../../config";


import { fetchSteps } from "../../redux/incidenciaSteps";
import { fetchMarcas } from "../../redux/marcas";
import { fetchModelos } from "../../redux/modelos";
import { fetchTarifas } from "../../redux/tarifas";
import { fetchTipoPiezas } from "../../redux/tipoPiezas";
import { fetchCarroceriasPesadas } from "../../redux/carroceriasPesadas";
import { fetchPlantillasEmail } from "../../redux/plantillasEmail";
import { fetchTransportistas } from "../../redux/transportistas";
import { fetchVariables } from "../../redux/variables";
import useEcho from "../../hooks/useEcho";


const Dashboard = ({onLogout}) => {
    const dispatch = useDispatch();
    const loading = useSelector(state => {
        return state.loading
    })
    const reload = useSelector(state => {
        return state.reload
    })
    const user = JSON.parse(localStorage.getItem('user'));
    const [openReport, setOpenReport] = useState(false);
    const webSocketChannel = `read-only`;
    const echo = useEcho();

    useEffect(() => {
        const localStorageReadOnly = localStorage.getItem('read_only');
        if(localStorageReadOnly) {
            const data = JSON.parse(localStorage.getItem('read_only')) || {};
            dispatch({type: 'roles/fullfilled', payload: data.roles || []});
            dispatch({type: 'estados_venta/fullfilled', payload: data.estados_venta || []});
            dispatch({type: 'estados_incidencia/fullfilled', payload: data.estados_incidencia || []});
            dispatch({type: 'subestados_incidencia/fullfilled', payload: data.subestados_incidencia || []});
            dispatch({type: 'tipo_incidencias/fullfilled', payload: data.tipo_incidencias || []});
            dispatch({type: 'tipo_facturas/fullfilled', payload: data.tipo_facturas || []});
            dispatch({type: 'tipo_piezas/fullfilled', payload: data.tipo_piezas || []});
            dispatch({type: 'familia_piezas/fullfilled', payload: data.familia_piezas || []});
            dispatch({type: 'tipo_garantias/fullfilled', payload: data.tipo_garantias || []});
            dispatch({type: 'tipo_clientes/fullfilled', payload: data.tipo_clientes || []});
            dispatch({type: 'tipo_clientes_b2b/fullfilled', payload: data.tipo_clientes_b2b || []});
            dispatch({type: 'tipo_devoluciones/fullfilled', payload: data.tipo_devoluciones || []});
            dispatch({type: 'tipo_feedback/fullfilled', payload: data.tipo_feedback || []});
            dispatch({type: 'tipo_bancos/fullfilled', payload: data.tipo_bancos || []});
            dispatch({type: 'marcas/fullfilled', payload: data.marcas || []});
            dispatch({type: 'modelos/fullfilled', payload: data.modelos || []});
            dispatch({type: 'plataformas/fullfilled', payload: data.plataformas || []});
            dispatch({type: 'paises/fullfilled', payload: data.paises || []});
            dispatch({type: 'tarifas/fullfilled', payload: data.tarifas || []});
            dispatch({type: 'descuentos/fullfilled', payload: data.descuentos || []});
            dispatch({type: 'steps/fullfilled', payload: data.steps || []});
            dispatch({type: 'transportistas/fullfilled', payload: data.transportistas || []});
            dispatch({type: 'servicios_envio/fullfilled', payload: data.servicios_envio || []});
            dispatch({type: 'precios_envios_piezas/fullfilled', payload: data.precios_envios_piezas || []});
            dispatch({type: 'recambistas-messages/fullfilled', payload: data.mensajes || []});
            dispatch({type: 'tarifas_envio_proveedor/fullfilled', payload: data.tarifas_envio_proveedor || []});
            dispatch({type: 'tipo_vencimiento/fullfilled', payload: data.tipo_vencimiento || []});
            dispatch({type: 'recambistas/fullfilled', payload: data.recambistas || []});
            dispatch({type: 'comerciales/fullfilled', payload: data.comerciales || []});
            dispatch({type: 'atencion_cliente/fullfilled', payload: data.atencion_cliente || []});
            dispatch({type: 'administracion/fullfilled', payload: data.finanzas || []});
            dispatch({type: 'carrocerias_pesadas/fullfilled', payload: data.carrocerias_pesadas || []});
            dispatch({type: 'plantillas_email/fullfilled', payload: data.plantillas_email || []});
        } else {
            dispatch(fetchReadOnlyData());
        }
    }, []);

    useEffect(() => {
        if (echo) {
            connectWebSocket();
        }

        return () => {
            if (echo) {
              echo.leaveChannel(webSocketChannel);
            }
        }
    }, [echo]);

    const connectWebSocket = () => {
        echo.private(webSocketChannel)
        .listen('.UpdateReadOnly', async (e) => {
            if(e.readOnly === 'incidencia-step') {
                dispatch(fetchSteps());
            } else if(e.readOnly === 'marcas') {
                dispatch(fetchMarcas());
            } else if(e.readOnly === 'modelos') {
                dispatch(fetchModelos());
            } else if(e.readOnly === 'tarifas') {
                dispatch(fetchTarifas());
            } else if(e.readOnly === 'tipo-piezas' || e.readOnly === 'avisos-tipo-piezas') {
                dispatch(fetchTipoPiezas());
            } else if(e.readOnly === 'carrocerias-pesadas') {
                dispatch(fetchCarroceriasPesadas());
            } else if(e.readOnly === 'plantillas-email') {
                dispatch(fetchPlantillasEmail());
            } else if(e.readOnly === 'transportistas') {
                dispatch(fetchTransportistas());
            } else if(e.readOnly === 'variables') {
                dispatch(fetchVariables());
            }
        });
    }

    const handleOpenReportProblem = () => { setOpenReport(true); }
    const handleCloseReportProblem = () => { setOpenReport(false); }

    return (
        <div className="dashboard-container">
            <Navbar 
                onOpenReportProblem={handleOpenReportProblem}
                onLogout={onLogout} />
            
            <Routes>
                <Route path='*' exact={true} element={<Navigate to="/" />}></Route>
                <Route path="/" exact element={<Home />}></Route>

                {user?.rol_id !== 7 && 
                    <>
                        <Route path="/ajustes" element={<Ajustes />}></Route>
                        <Route path="/mi-cuenta" element={<Account onLogout={onLogout} />}></Route>
                        
                        <Route path="/autorizaciones-credito/:page" element={<AutorizacionesCredito />}></Route>

                        <Route path="/clientes/:page" element={<Clientes />}></Route>
                        <Route path="/cliente/ficha/:clienteId" element={<ClienteSheet />}></Route>

                        <Route path="/devoluciones/:page" element={<Devoluciones />}></Route>
                        <Route path="/devolucion/:devolucionId" element={<DevolucionContainer />}></Route>
                        
                        <Route path="/envios/:page" element={<Envios />}></Route>

                        <Route path="/finanzas/" element={<Finanzas />}></Route>

                        <Route path="/recordatorios/" element={<RecordatoriosClientes />}></Route>
                        
                        <Route path="/facturas/:page" element={<Facturas />}></Route>
                        <Route path="/albaranes/:page" element={<Albaranes />}></Route>
                        <Route path="/notas-cargo/:page" element={<NotasCargo />}></Route>
                        
                        <Route path="/presupuestos/:page" element={<Presupuestos ecommerce={false} />}></Route>
                        <Route path="/ecommerce/:page" element={<Presupuestos ecommerce={true} />}></Route>
                        <Route path="/presupuesto/:presupuestoId" element={<FichaPresupuesto />}></Route>

                        <Route path="/proveedores/:page" element={<Proveedores />}></Route>
                        <Route path="/proveedor/ficha/:proveedorId" element={<ProveedorSheet />}></Route>
                        
                        <Route path="/comerciales/:page" element={<Comerciales pais={null}  />}></Route>
                        <Route path="/portugal/:page" element={<Comerciales pais={'portugal'} />}></Route>

                        <Route path="/users/:page" element={<Users />}></Route>
                        <Route path="/user/:userId" element={<UserDetail />}></Route>
                        
                        <Route path="/ventas/:page" element={<Ventas />}></Route>

                        <Route path="/incidencias/:page" element={<Incidencias />}></Route>
                        <Route path="/incidencia/:incidenciaId" element={<FichaIncidencia />}></Route>

                        <Route path="/solicitudes/:page" element={<Solicitudes />}></Route>
                        <Route path="/desguaces-offline/:page" element={<DesguacesOffline />}></Route>
                        <Route path="/reconstruidos/:page" element={<Reconstruidos />}></Route>

                        <Route path="/avisos-stock/:page" element={<Stock />}></Route>

                        <Route path="/tecdoc/:page" element={<TecDoc />}></Route>
                    </>
                }
            </Routes>

            { loading && <LoadingBackdrop /> }
            { reload && <NewVersionModal /> }

            { (openReport) &&
                <ReportModal 
                    state={openReport}
                    onClose={handleCloseReportProblem}
                />
            }

            <ToastContainer
                containerId="dashboard"
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
            />
        </div> 
    );
}

export default Dashboard