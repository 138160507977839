import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Pagination from '@mui/material/Pagination';
import {disablePresupuesto, duplicatePresupuesto, enablePresupuesto, exportPresupuestosClientes, generatePresupuestoPDF, validatePresupuesto} from '../shared/services/presupuestos';
import Header from '../shared/components/Header';
import { faLink, faClock, faCheck, faCross, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConfirmModal from "../shared/components/ConfirmModal";
import { fetchPresupuestos } from "../../../../redux/presupuestos";
import { handleCopyPresupuestoText, isBoolean, isWithFaros, isWithParagolpes, isWithTurbo, setErrorMessage } from "../shared/helpers/functionalities";
import { Popover } from "@mui/material";
import Loading from "../shared/components/Loading";
import ExportForm from "../shared/components/ExportForm";
import InfoModal from "../shared/components/InfoModal";
import ValidacionModal from "./components/ValidacionModal";
import ProblemasList from "./components/ProblemasList";
import { marcarProblemaLeido } from "../shared/services/piezas";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";
import config from "../../../../config";
import PlantillaEmailModal from "./components/PlantillaEmailModal";
export default function Presupuestos({
  ecommerce
}) {
  const user = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const { page } = useParams();
  const currPage = parseInt(page);
  let navigate = useNavigate(); 
  const [currentPresupuesto, setCurrentPresupuesto] = useState(null);
  const [currentPage, setPage] = useState(currPage);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [isDisabling, setIsDisabling] = useState(false);
  const [isReactivating, setIsReactivating] = useState(false);
  const [showValidate, setShowValidate] = useState(false);
  const [showInvalidate, setShowInvalidate] = useState(false);
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let presupuestoAux = {};
  const open = Boolean(anchorEl);
  const [openPlantillaEmail, setOpenPlantillaEmail] = useState(false);
  const presupuestos = useSelector(state => {
    return state.presupuestos.entities
  });
  const presupuestosStatus = useSelector(state => {
    return state.presupuestos.status
  });
  const searcher = useSelector(state => {
    return state.search
  })
  const plantillasEmail = useSelector(state => {
    return state.plantillas_email
  });

  useEffect(() => {
    dispatch(fetchPresupuestos(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    const query = !isEmpty ? 'ecommerce=' + ecommerce
        + `&key=${searcher.key}&search=${searcher?.search ? searcher?.search : ''}`
        + (searcher?.start_date ? '&start_date=' + searcher?.start_date : '')
        + (searcher?.end_date ? '&end_date=' + searcher?.end_date : '')
        + (searcher?.min ? '&min=' + searcher?.min : '')
        + (searcher?.max ? '&max=' + searcher?.max : '')
        + (searcher?.responsable ? '&responsable=' + searcher?.responsable : '')
        + (isBoolean(searcher.contiene_casco) ? '&contiene_casco=' + searcher?.contiene_casco : '')
        + (searcher?.casco ? '&casco=' + searcher?.casco : '')
      : 'ecommerce=' + ecommerce;

    return query;
  }

  const handleSearch = (searchData) => {
    setPage(1);
    dispatch({type: "search/set", payload: searchData}); 
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/${ecommerce ? 'ecommerce' : 'presupuestos'}/${newPage}`);
    dispatch(fetchPresupuestos(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = presupuestos.total_items < 15 ? 1 : Math.ceil(presupuestos.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, presupuesto) => {
    if(action === 'details') {
      navigate(`/presupuesto/${presupuesto.id}`);
    } else if(action === 'download') {
      generarPDF(presupuesto)
    } else if(action === 'clone') {
      duplicate(presupuesto)
    } else if(action === 'enable') {
      reactivate(presupuesto)
    } else if(action === 'disable') {
      disable(presupuesto)
    } else if(action === 'validate') {
      handleOpenValidate(presupuesto)
    } else if(action === 'invalidate') {
      handleOpenInvalidate(presupuesto);
    }
  }

  const generarPDF = async (presupuesto) => {
    dispatch({type: 'loading/set', payload: true});
    const pdfResult = await generatePresupuestoPDF(presupuesto.id);

    if(pdfResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', presupuesto.numero + '.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const reactivate = (presupuesto) => { 
    setCurrentPresupuesto(presupuesto);
    setIsReactivating(true);
  }

  const handleConfirmReactivate = async (confirm) => {
    if (confirm) {
      dispatch({type: 'loading/set', payload: true});
      const activateResult = await enablePresupuesto(currentPresupuesto.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });

      if(activateResult && activateResult.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(activateResult.message)
        dispatch(fetchPresupuestos(currPage, getQuery()));
      }
    }

    setIsReactivating(false);
  }

  const disable = (presupuesto) => {
    setCurrentPresupuesto(presupuesto);
    setIsDisabling(true);
  }

  const handleConfirmDisable = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const disableResult = await disablePresupuesto(currentPresupuesto.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });

      if(disableResult && disableResult.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(disableResult.message)
        dispatch(fetchPresupuestos(currPage, getQuery()));
      }
    }

    setIsDisabling(false);
  }

  const handleConfirmValidate = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const result = await validatePresupuesto({validado: true, validacion: confirm}, currentPresupuesto.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });
  
      if(result && result.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(result.message)
        dispatch(fetchPresupuestos(currPage, getQuery()));
      }
    } 
    
    handleCloseValidate();
  }
  
  const handleOpenValidate = (pres) => { setShowValidate(true); setCurrentPresupuesto(pres); }
  const handleCloseValidate = () => { setShowValidate(false); setCurrentPresupuesto(null); }

  const duplicate = (presupuesto) => { 
    setCurrentPresupuesto(presupuesto);
    setIsDuplicating(true);
  }

  const handleConfirmDuplicate = async (confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const duplicateResult = await duplicatePresupuesto(currentPresupuesto.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });

      if(duplicateResult && duplicateResult.success) {
        dispatch({type: 'loading/set', payload: false});
        setIsDuplicating(false);
        toast.success(duplicateResult.message)
        dispatch(fetchPresupuestos(currPage, getQuery()));
      } 
    } else {
      setIsDuplicating(false);
    }
  }

  const handleCopyText = (presupuesto) => {
    if(presupuesto.validado) {
      const text = handleCopyPresupuestoText(presupuesto, user);

      navigator.clipboard.writeText(text);
      toast.success('Texto copiado!');

      if(isWithParagolpes(presupuesto?.piezas)) {
        toast.warning(`Verificar los componentes que lleva montados el paragolpes para que al 
        cliente le llegue lo mismo que en las fotos, preguntarlo por escrito al desguace.`);
      }
  
      if(isWithFaros(presupuesto?.piezas)) {
        toast.warning(`En el caso de un faro de xenon o led, verificar si el faro viene con las centralitas, 
        informar al cliente para que no haya mal entendidos. Preguntarlo por escrito al desguace.`);
      }

      if(isWithTurbo(presupuesto?.piezas)) {
        toast.warning(`Recuerda pedir un video al desguace donde se vea el juego axial y radial del eje del turbo, 
        es importante conseguir el video antes de la venta del turbo para evitar una posible incidencia, 
        los turbos presentan una incidencia aprox. del 40%.`);
      }
    } else {
      toast.error("¡¡PRESUPUESTO NO VALIDADO!! 😛🖕🏼");
    }
  }

  const handleGoToEnvio = (envio) => {
    dispatch({type: "search/set", payload: {search: envio.codigo_transporte, key: 'envio' }});
    navigate('/envios/1', { replace : true});
  }

  const handleGoToEcommerce = () => {
    setPage(1);
    dispatch({type: "search/set", payload: {}});
    navigate('/ecommerce/1', { replace : true});
  }

  const handleGoToPresupuestos = () => {
    setPage(1);
    dispatch({type: "search/set", payload: {}});
    navigate('/presupuestos/1', { replace : true});
  }

  const handlePopoverOpen = (event, pres) => { 
    setAnchorEl(event.currentTarget); 
    presupuestoAux = pres;
  };
  const handlePopoverClose = () => { setAnchorEl(null); };

  const handleOpenExport = () => { setIsOpenExport(true); }
  const handleCloseExport = () => { setIsOpenExport(false); }
  const handleExportPresupuestosClientes = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const cliente = data.cliente;
    delete data.cliente;
    data.entity_id = cliente.id;
    const excelResult = await exportPresupuestosClientes(data);
    const startDate = moment(data.startDate).format('DD-MM-YYYY');
    const endDate = moment(data.endDate).format('DD-MM-YYYY');

    if(excelResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([excelResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', cliente.nombre + '_' + startDate + '_' + endDate + '.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleMarkProblemAsRead = async(problemaId) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await marcarProblemaLeido(problemaId);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message)
      dispatch(fetchPresupuestos(currPage, getQuery()));
    }
  }

  const handleOpenInvalidate = (pres) => { setShowInvalidate(true); setCurrentPresupuesto(pres); }
  const handleConfirmInvalidate = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const result = await validatePresupuesto({validado: false, validacion: null}, currentPresupuesto.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });
  
      if(result && result.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(result.message)
        dispatch(fetchPresupuestos(currPage, getQuery()));
      }
    } 

    handleCloseInvalidate();
  }
  const handleCloseInvalidate = () => {
    setShowInvalidate(false);
    setCurrentPresupuesto(null);
  }

  const getPresupuestoRowInfo = (presupuesto, pieza, piezasValues, i) => {
    const envioPieza = presupuesto?.envios?.find(e => e.piezas?.find(p => p.id === pieza.id));
    const servicioEnvioPieza = pieza ? pieza.servicio_envio : null;

    const numero = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%">
      <span className="disabled-item" title="Presupuesto" style={{cursor: 'pointer'}} onClick={() =>  handleCopyText(presupuesto)} >
        { presupuesto.es_devolucion.length > 0 && presupuesto.es_devolucion[0].tipo_devolucion_id === 1 ?
          <b className="text-warning">{presupuesto?.numero}</b>
          : presupuesto.es_devolucion.length > 0 && presupuesto.es_devolucion[0].tipo_devolucion_id === 2 ?
          <b className="text-danger">{presupuesto?.numero}</b>
          : presupuesto.factura_proveedor && presupuesto.factura_proveedor?.length > 0 ?
          <b>{presupuesto?.numero}</b>
          : presupuesto?.numero
        }
      </span>
    </td> : null;

    const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
      <div className="w-100 d-flex flex-column">
        <p>{moment(presupuesto.fecha).format("DD-MM-YYYY")}</p>
        <small>CREADO: {moment(presupuesto.created_at).format("DD-MM-YYYY HH:mm")}</small>
        <small>ULT MOD.: {moment(presupuesto.updated_at).format("DD-MM-YYYY HH:mm")}</small>
      </div>
    </td> : null
    const checkout = i === 0 ? 
      <td rowSpan={piezasValues.length + 1} className="text-center">
          <a title="Checkout URL" href={config.recomotor.clientsUrl  + 'checkout/' + presupuesto.checkout_id} target={"_blank"}  className="disabled-item text-green" rel="noreferrer">
            <FontAwesomeIcon icon={faLink} size="1x" className="action" style={{ cursor: 'pointer' }} color="#215732" />
          </a>
          <div style={{height: ".5rem"}} />
          <span>
            <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" style={{ cursor: 'pointer' }} color="#215732" onClick={() => handleOpenPlantillasModal(presupuesto)}/>
          </span>
      </td> 
    : null
    const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%">
      <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + presupuesto.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
        {presupuesto.cliente?.nombre}
      </Link>
    </td> : null
    const clienteTelf = i === 0 ?  <td rowSpan={piezasValues.length + 1}>{presupuesto.cliente.telefono}</td> : null
    const aceptado = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center"> {presupuesto.expirado ? <FontAwesomeIcon icon={faCross} size="1x" className="action" color="#dc3545" /> : presupuesto.aceptado ? <FontAwesomeIcon icon={faCheck} size="1x" className="action" color="#215732" /> : <FontAwesomeIcon icon={faClock} size="1x" className="action" color="#ffc107" />}</td> : null
    const descuento = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">
    { presupuesto?.vale_descuento ? 
      <div className="d-flex flex-column">
        -{parseFloat(presupuesto.total_descuento_iva).toFixed(2)}€
        <small className="fw-bold text-green">{presupuesto?.vale_descuento.codigo}</small>
      </div>
      : '-'
    }
    </td> : null
    const totalIva = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">{presupuesto.total_iva}€</td> : null
    const envio = envioPieza ?
        <div className="d-flex flex-column">
          <span onClick={() => handleGoToEnvio(envioPieza)} className="text-green link">{envioPieza.codigo_transporte}</span>
          <small>{envioPieza.transportista_id === 1 ? 'PROVEEDOR' : envioPieza.codigo_envio}</small>
        </div>
        : (servicioEnvioPieza) ?
          <div className="text-uppercase d-flex flex-column">
            <span className="text-green">{servicioEnvioPieza.transportista?.nombre}</span>
            <small style={{fontSize: '10px'}}>{servicioEnvioPieza.servicio_envio?.nombre}</small>
          </div>
        : 
        <div className="d-flex flex-column">
          <small className="text-danger fw-bold text-uppercase">falta envio</small>
        </div>
    const confirmada = pieza ? <span className={"fw-bold " + (pieza.confirmacion == true ? 'text-green' : 'text-danger')}>
      {pieza.confirmacion == true ? 'SI' : 'NO'}
    </span> : null
    const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center text-uppercase"><small style={{wordBreak: 'break-all'}}>{presupuesto.created_by?.username}</small></td> : null
    const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
      <ActionsMenu 
          options={getActions(presupuesto, user)}
          onAction={(action) => handleAction(action, presupuesto)}
      />
    </td> : null

    return (
      <tr
          className={(((!presupuesto.validado && presupuesto.validacion === null) || (presupuesto.validado && presupuesto.validacion === false) || (presupuesto.validado_integracion === false))) ? "disabled-row" : ""}
          key={'presupuesto-' + presupuesto.id + '-' + i}
          onMouseEnter={(!presupuesto.validado && presupuesto.validacion === null) ? (e => handlePopoverOpen(e, presupuesto)) : null}
          onMouseLeave={(!presupuesto.validado && presupuesto.validacion === null) ? handlePopoverClose : null}
        >
          {numero}
          {fecha}
          {checkout}
          {cliente}
          {clienteTelf}
          { (pieza) ?
            <>
              <td className="border-light" style={{backgroundColor: pieza.disponible === null ? '#fff1ae' : !pieza.disponible ? '#fab3ae' : 'none' }}>
                <div className="w-100 d-flex flex-column text-uppercase">
                  <small className="w-100 d-flex flex-column">
                    <span>
                      {pieza?.presupuesto_relationship?.unidades > 1 ? 
                        <strong>{'x'+pieza?.presupuesto_relationship?.unidades}</strong> : '' 
                      } 
                      {pieza.nombre}
                    </span>
                    <span>REF: <b>{pieza.referencia_pieza}</b></span>
                    { pieza?.casco > 0 ?
                      <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                      : ''
                    }
                  </small>

                  <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + pieza.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                    {pieza.proveedor.nombre}
                  </Link>
                </div>
              </td>
              <td className="text-center border-light">
                { (pieza?.url && pieza?.url?.includes('http')) ? 
                  <a href={pieza.url} className="text-green" target={"_blank"} rel="noreferrer">
                    <FontAwesomeIcon icon={faLink}></FontAwesomeIcon>
                  </a>
                  : '-'
                }
              </td>
              <td className="text-center border-light">{parseFloat(pieza.precio_compra).toFixed(2)}€</td>
              <td className="text-center border-light">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
              <td className="text-center">{pieza.casco > 0 ? parseFloat(pieza.casco).toFixed(2) + '€' : '-'}</td>
            </>
           : 
           <td colSpan={5} className="text-center">No hay piezas</td>
          }
          {descuento}
          {totalIva}
          <td className="text-center border-light">{envio}</td>
          <td className="text-center border-light">{confirmada}</td>
          {aceptado}
          {responsable}
          {acciones}
      </tr>
    )
  }

  const handleOpenPlantillasModal = (presupuesto) => {
    setOpenPlantillaEmail(true);
    setCurrentPresupuesto(presupuesto);
  }

  return (
    <div className="content pt-3">
      <Header  
        viewSearch={true} 
        onSearch={handleSearch}
        onExport={handleOpenExport}
        onEcommerce={handleGoToEcommerce}
        onPresupuesto={handleGoToPresupuestos}
      ></Header>

      <div className="w-100 d-flex flex-column align-items-end content-table presupuestos">
        <div className="w-100 d-flex align-items-center justify-content-between justify-content-md-start mb-3">
          <div className="legend d-flex align-items-center me-md-3">
            <span className="circle" style={{background: '#ffc107'}}></span>
            <p>Devolución parcial</p>
          </div>

          <div className="legend d-flex align-items-center">
            <span className="circle" style={{background: '#dc3545'}}></span>
            <p>Devolución total</p>
          </div>
        </div>
        
        { (presupuestos?.problemas?.length > 0) &&
          <ProblemasList 
          problemas={presupuestos.problemas}
          onMarkAsRead={handleMarkProblemAsRead}
          />
        }

        { (presupuestosStatus.loading === 'succeed' || presupuestosStatus.loading === 'rejected') ?
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>Nº</th>
                    <th className="text-center">fecha</th>
                    <th className="text-center"></th>
                    <th>cliente</th>
                    <th>telf</th>
                    <th><small>Pieza</small></th>
                    <th><small>URL</small></th>
                    <th className="text-center"><small>compra</small></th>
                    <th className="text-center"><small>venta</small></th>
                    <th className="text-center"><small>casco</small></th>
                    <th className="text-center">descuento</th>
                    <th className="text-center">total IVA</th>
                    <th className="text-center">Envío</th>
                    <th className="text-center">conf</th>
                    <th className="text-center">aceptado</th>
                    <th className="text-center">resp</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>
                  { 
                    presupuestos.presupuestos?.map((presupuesto, index) => {
                      const piezasValues = Object.values(presupuesto.piezas)
                      let piezasRows = piezasValues.map((pieza, i) => {
                        const row = getPresupuestoRowInfo(presupuesto, pieza, piezasValues, i)

                        return (
                          row
                        )
                      })
                      
                      if(piezasRows?.length === 0) {
                        const row = getPresupuestoRowInfo(presupuesto, null, piezasValues, 0)
                        piezasRows =  row
                      }
                      
                      return (
                        <tbody className="border-light" key={presupuesto.id}>
                          {piezasRows}
                        </tbody>
                      )
                    }) 
                  }
              </table>

              <>
              <Popover
                id="disabledPresupuesto"
                sx={{ pointerEvents: 'none'}}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                disableRestoreFocus
                classes={{ root: "MuiPopover-root" }}
              >
                <small>
                  {(!presupuestoAux.validado && presupuestoAux.validacion === null) ? 
                    "Presupuesto pendiente de validar"
                    : (!presupuestoAux.validado && !presupuestoAux.validacion) ?
                    "Presupuesto NO VALIDADO"
                    : ""
                  }
                </small>
              </Popover>
              </>
            </div>

            <Pagination 
              className="mt-3" 
              count={getPaginationCount()} 
              page={currentPage} 
              onChange={handleChangePage} 
            />
          </>
          :
          <Loading />
        }
      </div>

      { isDuplicating && 
        <ConfirmModal 
          onConfirmAction={handleConfirmDuplicate} 
          title={`Duplicar presupuesto ${currentPresupuesto.numero}`} 
          description={'Estás seguro de duplicar?'}
          state={isDuplicating}>
        </ConfirmModal>
      }
      { (isDisabling) &&
        <ConfirmModal 
            onConfirmAction={handleConfirmDisable} 
            title={`Desactivar presupuesto ${currentPresupuesto.numero}`} 
            description={'Estás seguro de desactivar?'}
            state={isDisabling}>
        </ConfirmModal>
      }

      { (isReactivating) &&
        <ConfirmModal 
            onConfirmAction={handleConfirmReactivate} 
            title={`Reactivar presupuesto ${currentPresupuesto.numero}`} 
            description={'Estás seguro de reactivar?'}
            state={isReactivating}>
        </ConfirmModal>
      }

      { showInvalidate && 
        <ConfirmModal 
          onConfirmAction={handleConfirmInvalidate} 
          title={`Invalidar presupuesto ${currentPresupuesto.numero}`} 
          description={'Estás seguro de invalidar?'}
          state={showInvalidate}>
        </ConfirmModal>
      }

      { showValidate && 
        <ValidacionModal 
          presupuestoId={currentPresupuesto.id}
          onConfirmAction={handleConfirmValidate} 
          onClose={handleCloseValidate}
          state={showValidate}>
        </ValidacionModal>
      }

      { (isOpenExport) && 
        <InfoModal
          state={isOpenExport}
          title="Exportar listado de presupuestos de un cliente"
          content={
            <ExportForm 
              dateFilter={true}
              clienteFilter={true}
              onSubmitExport={handleExportPresupuestosClientes}
            />
          }
          onClose={handleCloseExport}
        ></InfoModal>
      }

      {openPlantillaEmail && (
          <InfoModal
            state={openPlantillaEmail}
            title="Plantillas email"
            content={<PlantillaEmailModal plantillas={plantillasEmail} presupuesto={currentPresupuesto}/>}
            width={"md"}
            onClose={() => setOpenPlantillaEmail(false)}
          />
        )}
    </div>
  )
}