export const getActions = () => {
  return {
    actions: [
      {
        label: "Editar",
        icon: "edit",
        action: "edit",
      },
      {
        label: "Cambiar fecha de proximo contacto",
        icon: "date",
        action: "changeNextContactDate",
      },
      {
        label: "Añadir contacto",
        icon: "add",
        action: "addContact",
      },
      {
        label: "Añadir observación",
        icon: "comment",
        action: "addObservation",
      },
    ],
    extra: [],
  };
};
