import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useDispatch, useSelector } from "react-redux";
import Loading from "../shared/components/Loading";
import { fetchRecordatoriosClientes } from "../../../../redux/features/recordatoriosClientes";
import { archivarNotificacion } from "../shared/services/notificaciones";
import { faArchive, faBook, faCheckCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import ConfirmModal from "../shared/components/ConfirmModal";
import { Link, useNavigate } from "react-router-dom";
import { archivarWhatsAppsRecordatorios, enviarWhatsAppRecordatorio, enviarWhatsAppsRecordatorios } from "../shared/services/whatsapp";
import { setErrorMessage } from "../shared/helpers/functionalities";
import WhatsappLogs from "./components/WhatsappLogs";
import InfoModal from "../shared/components/InfoModal";

export default function RecordatoriosClientes() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const recordatorios = useSelector(state => {
        return state.recordatorios.entities
    });
    const status = useSelector(state => {
        return state.recordatorios.status
    });
    const { register, handleSubmit, setValue, getValues } = useForm({
        mode: "onChange"
    });

    const [selectAll, setSelectAll] = useState(false);
    const [formData, setFormData] = useState(null);
    const [currentNotificacion, setCurrentNotificacion] = useState(null);
    const [openConfirmSendWhatsapp, setOpenConfirmSendWhatsapp] = useState(false);
    const [openConfirmSendWhatsappItem, setOpenConfirmSendWhatsappItem] = useState(false);
    const [openLogs, setOpenLogs] = useState(false);
    const [openArchive, setOpenArchive] = useState(false);
    const [openArchiveSelected, setOpenArchiveSelected] = useState(false);

    useEffect(() => {
        dispatch(fetchRecordatoriosClientes());
    }, []);

    const handleGoTo = (item, search) => {
        dispatch({type: "search/set", payload: search});
    
        if(item === 'presupuesto') {
          navigate('/presupuestos/1', { replace : true});
        }
    }

    const handleOpenConfirmArchive = (notificacion) => { 
        setOpenArchive(true); 
        setCurrentNotificacion(notificacion); 
    }

    const handleArchive = async(confirm) => {
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});
            const response = await archivarNotificacion(currentNotificacion, true);
    
            if(response && response.success) {
                dispatch({type: 'loading/set', payload: false});
                toast.success(response.message);
                dispatch(fetchRecordatoriosClientes());
            }
        }
        
        setOpenArchive(false);
        setCurrentNotificacion(null);
    }

    const handleSelectAll = (select) => {
        setSelectAll(select);
        recordatorios.forEach((recordatorio) => setValue("notificacion-" + recordatorio.id, select));
    }

    const handleOpenConfirmSendWhatsapp = (data, e) => { 
        e.preventDefault();
        let dataArray = [];

        Object.entries(data).forEach((value) => {
            const notifId = value[0].split('-')[1];
            if(value[1] == true) dataArray.push(notifId);
        });


        setOpenConfirmSendWhatsapp(true); 
        setFormData(dataArray); 
    }
    const handleOpenConfirmSendWhatsappItem = (notificacion) => {
        setOpenConfirmSendWhatsappItem(true);
        setCurrentNotificacion(notificacion);
    }

    const handleSendAllWhatsapp = async(confirm) => {
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});

            const response = await enviarWhatsAppsRecordatorios({notificaciones: formData})
            .catch(function (error) {
                toast.error(setErrorMessage(error))
                dispatch({type: 'loading/set', payload: false});
            });
      
            if(response && response.success) {
                dispatch({type: 'loading/set', payload: false});
                toast.success(response.message)
                dispatch(fetchRecordatoriosClientes());
            }
        }   

        setOpenConfirmSendWhatsapp(false);
        setCurrentNotificacion(null);
    }

    const handleOpenArchiveSelected = () => {
        let dataArray = [];

        recordatorios.forEach((recordatorio) => {
            const checked = getValues("notificacion-" + recordatorio.id);

            if(checked) dataArray.push(recordatorio.id);
        });


        if(dataArray.length === 0) {
            toast.warning('No hay notificaciones seleccionadas');
            return;
        }

        setFormData(dataArray);
        setOpenArchiveSelected(true);
    }

    const handleArchiveSelected = async(confirm) => {
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});

            const response = await archivarWhatsAppsRecordatorios({notificaciones: formData})
            .catch(function (error) {
                toast.error(setErrorMessage(error))
                dispatch({type: 'loading/set', payload: false});
            });
      
            if(response && response.success) {
                dispatch({type: 'loading/set', payload: false});
                toast.success(response.message)
                dispatch(fetchRecordatoriosClientes());
            }
        }   

        setOpenArchiveSelected(false);
        setFormData(null);
    }

    const handleSendWhatsapp = async(confirm) => {
        let tipo;
    
        if(confirm) {
            dispatch({type: 'loading/set', payload: true});

            if(currentNotificacion.mensaje.includes('Comprobante pendiente')) {
                tipo = 'comprobante';
            } else if(currentNotificacion.mensaje.includes('Presupuesto pendiente')) {
                tipo = 'presupuesto';
            }

            const response = await enviarWhatsAppRecordatorio({
                tipo: tipo,
                notificacion_id: currentNotificacion.id,
                presupuesto_id: currentNotificacion.presupuesto_id
            }).catch(function (error) {
                toast.error(setErrorMessage(error))
                dispatch({type: 'loading/set', payload: false});
            });
      
          if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success('WhatsApp enviado al cliente correctamente!')
          }
        }
        
        setOpenConfirmSendWhatsappItem(false);
        setFormData(null);
    }

    const handleOpenLogs = (notificacion) => { setOpenLogs(true); setCurrentNotificacion(notificacion); }
    const handleCloseLogs = () => { setOpenLogs(false); setCurrentNotificacion(null); }


    if(status.loading === 'pending' || status.loading === 'idle') {
        return ( <Loading /> );
    }

    if(status.loading === 'succeed' || status.loading === 'rejected') {
        return (
            <div className="content pt-3">
                <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleOpenConfirmSendWhatsapp)}>
                    <div className="w-100 d-flex align-items-center mb-3">
                        <button type="button" className="btn btn-add me-3"  onClick={() => handleSelectAll(selectAll ? false : true)}>
                            <span className="add">
                                <FontAwesomeIcon icon={faCheckCircle} size="1x" className="action" color="#215732" />
                            </span>
                            {selectAll ? 'Deseleccionar todos' : 'Seleccionar todos'}
                        </button>

                        <button type="submit" className="btn btn-add me-3">
                            <span className="add">
                                <FontAwesomeIcon icon={faWhatsapp} size="1x" className="action" color="#215732" />
                            </span>
                            Enviar Whatsapp seleccionados
                        </button>

                        <button type="button" className="btn btn-add" onClick={() => handleOpenArchiveSelected()}>
                            <span className="add">
                                <FontAwesomeIcon icon={faArchive} size="1x" className="action" color="#215732" />
                            </span>
                            Archivar seleccionados
                        </button>
                    </div>

                    <div className="w-100 d-flex flex-column align-items-end content-table">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>numero</th>
                                        <th className="text-center">fecha</th>
                                        <th className="text-center">cliente</th>
                                        <th>mensaje</th>
                                        <th>pieza</th>
                                        <th className="text-center">precio / unid.</th>
                                        <th className="text-center">envío</th>
                                        <th className="text-center">total IVA</th>
                                        <th className="text-center">acciones</th>
                                    </tr>
                                </thead>

                                { recordatorios?.map((recordatorio, index) => {
                                    const piezasValues = Object.values(recordatorio.presupuesto.piezas)
                                    const piezasRows = piezasValues.map((pieza, i) => {
                                        const servicioEnvioPieza = recordatorio.presupuesto?.servicios_envio?.find(e => parseInt(e.piezas) === pieza.id);

                                        const checkbox = i === 0 ? 
                                        <td width={"3%"} rowSpan={piezasValues.length + 1}>
                                            <input 
                                                type="checkbox"
                                                {...register("notificacion-" + recordatorio.id)} 
                                                defaultChecked={false} />
                                        </td> : null;

                                        const numero = i === 0 ? 
                                        <td width={"8%"} rowSpan={piezasValues.length + 1}>
                                            <p 
                                                className="text-primary"
                                                style={{textDecoration: 'underline', cursor: 'pointer'}}
                                                onClick={() => handleGoTo('presupuesto', {search: recordatorio?.presupuesto?.numero, key: 'presupuesto'})}>
                                                {recordatorio?.presupuesto?.numero}
                                            </p> 
                                        </td> : null;

                                        const fecha = i === 0 ? 
                                        <td className="text-center" width={"12%"} rowSpan={piezasValues.length + 1}>
                                            <div className="w-100 d-flex flex-column">
                                                <p>{moment(recordatorio.presupuesto.fecha).format("DD-MM-YYYY")}</p>
                                               <small>Fecha de notificación</small>
                                               <small className="fw-bold">{moment(recordatorio.fecha).format('DD-MM-YYYY HH:mm')}</small>
                                            </div>
                                        </td> : null;

                                        const cliente = i === 0 ? 
                                        <td width={"10%"} className="text-center" rowSpan={piezasValues.length + 1}>
                                            <div className="w-100 d-flex flex-column">
                                                <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + recordatorio?.presupuesto?.entidad.id} title="Ficha Cliente" className="text-green text-uppercase">
                                                {recordatorio?.presupuesto?.entidad.nombre}
                                                </Link> 
                                                <small>{recordatorio?.presupuesto?.entidad?.telefono}</small>
                                            </div>
                                        </td> : null;
                                        
                                        const msj = i === 0 ? 
                                        <td rowSpan={piezasValues.length + 1}>{recordatorio.mensaje.split('.', 2)}</td> : null;

                                        const totalPresu = i === 0 ? 
                                        <td rowSpan={piezasValues.length + 1} className="text-center">
                                            {recordatorio.presupuesto.total_iva}
                                        </td> : null;

                                        const acciones = i === 0 ?
                                        <td width={"6%"} rowSpan={piezasValues.length + 1} className="text-center">
                                            <span title="Ver logs" onClick={() => handleOpenLogs(recordatorio)}>
                                                <FontAwesomeIcon icon={faBook} size="1x" className="action" color="#215732" />
                                            </span>

                                            <span title="Enviar whatsapp de recordatorio" className="mx-1" onClick={() => handleOpenConfirmSendWhatsappItem(recordatorio)}>
                                                <FontAwesomeIcon icon={faWhatsapp} size="1x" className="action" color="#29a745" />
                                            </span>

                                            <span title="Archivar" onClick={() => handleOpenConfirmArchive(recordatorio)}>
                                                <FontAwesomeIcon icon={faTimes} size="1x" className="action" color="#dc3545" />
                                            </span>
                                        </td> : null;

                                        return (
                                            <tr key={i}>
                                                {checkbox}
                                                {numero}
                                                {fecha}
                                                {cliente}
                                                {msj}
                                                <td className="border-light">
                                                    <div className="w-100">
                                                        <small className="w-100 d-flex flex-column align-items-start">
                                                        <span>{pieza?.presupuesto_relationship?.unidades > 1 ? <strong>{'x'+pieza?.presupuesto_relationship?.unidades}</strong> : '' } {pieza.nombre}</span>
                                                        <span>#{pieza?.id_referencia_plataforma}</span>
                                                        { pieza?.casco > 0 ?
                                                            <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                                            : ''
                                                        }
                                                        </small>
                                                    </div>
                                                </td>
                                                <td className="text-center border-light">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                                                <td className="text-center">
                                                    { servicioEnvioPieza ? (servicioEnvioPieza.precio_transporte + '€')
                                                        : '-'
                                                    }
                                                </td>
                                                {totalPresu}
                                                {acciones}
                                            </tr>
                                        )
                                    })

                                    return (
                                        <tbody className="border-light" key={index}>
                                            {piezasRows}
                                        </tbody>
                                    )
                                })}
                            </table>
                        </div>
                    </div>
                </form>

                { openConfirmSendWhatsapp && 
                    <ConfirmModal 
                        onConfirmAction={handleSendAllWhatsapp} 
                        title={'Enviar whatsapps'} 
                        description={'Estás seguro de enviar los whatsapps de recordatorio a los seleccionados?'}
                        state={openConfirmSendWhatsapp}>
                    </ConfirmModal>
                }

                { openConfirmSendWhatsappItem && 
                    <ConfirmModal 
                        onConfirmAction={handleSendWhatsapp} 
                        title={'Enviar whatsapp'} 
                        description={'Estás seguro de enviar el whatsapps de recordatorio?'}
                        state={openConfirmSendWhatsappItem}>
                    </ConfirmModal>
                }

                { openArchive && 
                    <ConfirmModal 
                        onConfirmAction={handleArchive} 
                        title={'Archivar recordatorio'} 
                        description={'Estás seguro de archivar el recordatorio?'}
                        state={openArchive}>
                    </ConfirmModal>
                }

                { openArchiveSelected && 
                    <ConfirmModal 
                        onConfirmAction={handleArchiveSelected} 
                        title={'Archivar recordatorios seleccionados'} 
                        description={'Estás seguro de archivar los recordatorios?'}
                        state={openArchiveSelected}>
                    </ConfirmModal>
                }

                { openLogs &&
                <InfoModal
                    state={openLogs}
                    title={'Logs'}
                    content={
                        <WhatsappLogs
                            logs={currentNotificacion?.whatsapp_logs}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseLogs}
                ></InfoModal>
            }
            </div>
        )
    }
}