import { useState, useEffect } from "react";
import React from "react";
import moment from 'moment';
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Header from '../shared/components/Header';
import { useDispatch, useSelector } from "react-redux";
import { fetchDetallesEntidades } from "../../../../redux/comercial_detail";
import InfoModal from "../shared/components/InfoModal";
import ProximoContactoForm from "./components/Forms/ProximoContactoForm";
import { toast } from "react-toastify";
import ContactoForm from "./components/Forms/ContactoForm";
import EntityDetalleTable from "./components/EntityDetalleTable";
import ObservacionesForm from "./components/Forms/ObservacionesForm";
import ExportForm from "../shared/components/ExportForm";
import { capitalize, setErrorMessage } from "../shared/helpers/functionalities";
import EntidadContacto from "./components/EntidadContacto";
import DailyLlamadas from "./components/DailyLlamadas";
import { exportClientesUltimasComprasExcel, exportConversionEntidadesExcel, newContacto, newProximoContacto, updateContacto, updateDetalleComercial, updateObservaciones } from "../shared/services/comerciales";
import Loading from "../shared/components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { getSecciones } from "./secciones";
import ClienteModal from "../shared/components/ClienteModal";
import { newEntidad } from "../shared/services/clientes";

export default function Comerciales({
  pais
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('user'));
  const { page } = useParams();
  const currPage = parseInt(page); 
  const [currentPage, setPage] = useState(currPage);
  const currTab = localStorage.getItem('comerciales-tab') ? localStorage.getItem('comerciales-tab') : 'interesado';
  const [tab, setTab] = useState(currTab ? currTab : 'pendientes');
  const [secciones, setSecciones] = useState([]);
  const [currentEntidad, setCurrentEntidad] = useState(null);
  const [currentContacto, setCurrentContacto] = useState(null);
  const [contactosList, setContactosList] = useState(null);
  const [startOfWeek, setStartOfWeek] = useState(null);
  const [endOfWeek, setEndOfWeek] = useState(null);
  const [isChangeProximoContacto, setIsChangeProximoContacto] = useState(false);
  const [isViewContactosList, setIsViewContactosList] = useState(false);
  const [isViewDailyLlamadas, setIsViewDailyLlamadas] = useState(false);
  const [isOpenAddContacto, setIsOpenAddContacto] = useState(false);
  const [isOpenExportConversiones, setIsOpenExportConversiones] = useState(false);
  const [isEditContacto, setIsEditContacto] = useState(false);
  const [isAddObservaciones, setIsAddObservaciones] = useState(false);
  const [isOpenNewCliente, setIsOpenNewCliente] = useState(false);
  const detalles = useSelector(state => {
    return state.detalles.entities
  });
  const detallesStatus = useSelector(state => {
    return state.detalles.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchDetallesEntidades(currentPage, getQuery(tab)))
  }, [searcher]);

  useEffect(() => {
    setSecciones(getSecciones(pais));
  }, [pais]);

  const handleChangeTab = (e, newTab) => { 
    setTab(newTab); 
    setPage(1);
    navigate(`/${pais ? 'portugal' : 'comerciales'}/${1}`);
    dispatch(fetchDetallesEntidades(1, getQuery(newTab)));
    localStorage.setItem('comerciales-tab', newTab);
  }

  const getDailyLlamadas = () => {
    const today = moment();
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');
    const allTalleres = detalles.comerciales;
    setStartOfWeek(startOfWeek);
    setEndOfWeek(endOfWeek);

    let daily = allTalleres.filter(taller => {
      if((taller.volver_contactar || taller.proximo_contacto) && taller.owner_id === user.id) {
        const fecha = moment(taller.proximo_contacto);
  
        if(fecha.isBetween(startOfWeek, endOfWeek, 'days', '[]')) {
          return true
        } else return false;
      } else return false;
    }).sort((a, b) => -b?.proximo_contacto?.localeCompare(a?.proximo_contacto) || -b?.tipo?.localeCompare(a?.tipo));

    return daily;
  }

  const handleChangePage = (newPage, id) => {
    setPage(newPage);
    navigate(`/${pais ? 'portugal' : 'comerciales'}/${newPage}`);
    dispatch(fetchDetallesEntidades(newPage, getQuery(tab)));
  }

  const getQuery = (section = null) => {
    let query;
    let isEmpty = true;

    if(searcher) {
      isEmpty = Object.values(searcher).every(x => x === null || x === '');
      query = !isEmpty ? 'search=' + (searcher?.search ? searcher?.search : '') 
        + ((searcher?.comercial && searcher?.comercial !== -1) ? '&comercial=' + searcher?.comercial : '')
        + ((searcher?.tipo && searcher?.tipo !== 'todos') ? '&tipo=' + searcher?.tipo : '')
      : null;
    }

    if(section) {
      if(!isEmpty) {
        query += '&seccion=' + section;

        if(!query?.includes('tipo')) {
          query += '&tipo=validos';
        }
      } else {
        query = 'seccion=' + section ;
      }

      if(isEmpty && !query?.includes('tipo')) {
        query += '&tipo=validos';
      }
    }

    if(!pais) {
      query += '&from=comerciales';
    } else query += '&from=portugal';
    

    return query;
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/${pais ? 'portugal' : 'comerciales'}/${1}`);
    dispatch({type: "search/set", payload: searchData});
  }

  const handleCloseProximoContacto = () => { setIsChangeProximoContacto(false); }
  const handleOpenProximoContacto = (entidad) => { 
    setCurrentEntidad(entidad);
    setIsChangeProximoContacto(true); 
  }

  const handleAddProximoContacto = async (data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await newProximoContacto(data)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setIsChangeProximoContacto(false);
      dispatch(fetchDetallesEntidades(page[tab], getQuery(tab)));
    }
  }

  const handleCloseContacto = () => { 
    setCurrentEntidad(null);
    setIsViewContactosList(false); 
  }
  const handleOpenContactos = (entidad) => { 
    setCurrentEntidad(entidad);
    setIsViewContactosList(true); 
  }

  const handleCloseDaily = () => { setIsViewDailyLlamadas(false); }
  const handleOpenDaily = () => { 
    if(detalles && detalles?.comerciales?.length > 0) {
      let daily = getDailyLlamadas();

      if(daily && daily.length > 0) {
        setContactosList(daily);
      }
    }
    setIsViewDailyLlamadas(true); 
  }

  const handleCloseObservaciones = () => { setIsAddObservaciones(false); }
  const handleOpenObservaciones = (entidad) => {
    setCurrentEntidad(entidad);
    setIsAddObservaciones(true);
  }

  const handleSubmitObservaciones = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await updateObservaciones(data)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      setIsAddObservaciones(false);
      dispatch(fetchDetallesEntidades(page[tab], getQuery(tab)));
    }
  }

  const handleOpenAddContacto = (detalle) => { 
    setCurrentEntidad(detalle);
    setCurrentContacto(null);
    setIsEditContacto(false);
    setIsOpenAddContacto(true); 
  }
  const handleCloseOpenAddContacto = () => {
    setCurrentContacto(null);
    setIsEditContacto(false);
    setIsOpenAddContacto(false);
  }

  const handleEditContacto = (contacto) => {
    setCurrentContacto(contacto);
    setIsEditContacto(true);
    setIsOpenAddContacto(true);
  }

  const handleSubmitContacto = async(contacto) => {
    let response;
    dispatch({type: 'loading/set', payload: true});

    if(isEditContacto) {
      response = await updateContacto(contacto)
      .catch(function (error) {
          toast.error(setErrorMessage(error.message))
          dispatch({type: 'loading/set', payload: false});
      });
    } else {
      response = await newContacto(currentEntidad.id, contacto)
      .catch(function (error) {
          toast.error(setErrorMessage(error.message))
          dispatch({type: 'loading/set', payload: false});
      });
    }

    const contactosAux = currentEntidad.contactos;

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});

      if(isEditContacto) {
        const index = contactosAux.findIndex(c => c.id === contacto.id);
        contactosAux.splice(index, 1, response.data)
      } else {
        contactosAux.push(response.data);
      }

      setCurrentEntidad({...currentEntidad, contactos: contactosAux});
      setIsViewContactosList(false);
      setIsEditContacto(false);
      setCurrentContacto(null);
      setIsOpenAddContacto(false);
      toast.success(response.message);
      dispatch(fetchDetallesEntidades(page[tab], getQuery(tab)));
    }
  }

  const handleExport = async() => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await exportClientesUltimasComprasExcel()
    .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
    });

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Facturas descargadas correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'ultimas_compras_clientesB2B.xlsx');
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }


  const handleOpenConversionEntidadesExport = () => { setIsOpenExportConversiones(true); }
  const handleCloseConversionEntidadesExport = () => { setIsOpenExportConversiones(false); }

  const handleExportConversionEntidades = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const exportResult = await exportConversionEntidadesExcel(data)
    .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
    });

    if(exportResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('Excel descargado correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'conversion_clientes_' + data.startDate + '_' + data.endDate + '.xlsx');
      document.body.appendChild(link);
      link.click();
      setIsOpenExportConversiones(false);
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleUpdate = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await updateDetalleComercial(data)
    .catch(function (error) {
        toast.error(setErrorMessage(error.message))
        dispatch({type: 'loading/set', payload: false});
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchDetallesEntidades(page[tab], getQuery(tab)));
    }
  }

  const handleOpenNewCliente = () =>  { setIsOpenNewCliente(true); }
  const handleCloseNewCliente = () =>  { setIsOpenNewCliente(false); }

  const handleNewcliente = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    data.from = pais && pais === 'portugal' ? 10 : 1;
    delete data.id;

    const response = await newEntidad(data)
    .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      dispatch(fetchDetallesEntidades(page[tab], getQuery(tab)));
      setIsOpenNewCliente(false);
    }
  }

  return (
    <div className="content pt-3">
      <Header
        onNew={handleOpenNewCliente}
        viewSearch={true} 
        detalleTab={tab}
        onSearch={handleSearch}
        onExport={handleOpenConversionEntidadesExport}
      ></Header>

      { (detallesStatus.loading === 'succeed' || detallesStatus.loading === 'rejected') ?
          <>
            <div className="w-100 d-flex flex-column align-items-start mt-3">
              <button type="button" className="btn btn-info" onClick={() => handleOpenDaily()}>Llamadas diarias</button>
            </div>

            <div className='card-grid w-100 d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between mt-3'>
              { detalles.totales?.map(total => {
                return (
                  <div className='card-item d-flex flex-column align-items-center' key={total.comercial_id}>
                      <h2 className="text-uppercase">{total.comercial}</h2>
                      <h1>{total.count}</h1>
                  </div>
                )
              })}

              <div className='card-item d-flex flex-column align-items-center'>
                  <h2>Talleres +2 ventas en los últimos 60 días</h2>
                  <h1>{detalles.clientes_60dias}</h1>
              </div>
            </div>

            <div className="w-100 mt-3">
                <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChangeTab} aria-label="Ajustes">
                        { secciones?.map(seccion => {
                          return (
                            <Tab key={'tab-' + seccion.value} label={capitalize(seccion.nombre)} value={seccion.value} />
                          )
                        })}
                    </TabList>
                    </Box>
                    { secciones.map((seccion, i) => {
                      let tab = null;

                      if(seccion.value === 'cliente') {
                        tab = 
                        <TabPanel value={seccion.value} key={"comerciales-tab-" + seccion.value}>
                          <div className="w-100 d-flex flex-column align-items-end">
                            <button className="btn btn-primary mb-4" onClick={() => handleExport()}>
                              Exportar últimas ventas
                            </button>

                            <EntityDetalleTable
                                id={seccion.value}
                                currPage={currentPage}
                                detalles={detalles.entidades}
                                tipo={searcher?.tipo ? searcher?.tipo : 'validos'}
                                onOpenAddContacto={handleOpenAddContacto}
                                onOpenContactos={handleOpenContactos}
                                onOpenProximoContacto={handleOpenProximoContacto}
                                onOpenAddObservaciones={handleOpenObservaciones}
                                onEditEntidadDetalle={handleUpdate}
                                onPageChange={handleChangePage}
                              />
                          </div>
                        </TabPanel>
                      } else {
                        tab = 
                          <TabPanel value={seccion.value} key={"comerciales-tab-" + seccion.value}>
                            <EntityDetalleTable 
                                id={seccion.value}
                                currPage={currentPage}
                                detalles={detalles.entidades}
                                tipo={searcher?.tipo ? searcher?.tipo : 'validos'}
                                onOpenAddContacto={handleOpenAddContacto}
                                onOpenContactos={handleOpenContactos}
                                onOpenProximoContacto={handleOpenProximoContacto}
                                onOpenAddObservaciones={handleOpenObservaciones}
                                onEditEntidadDetalle={handleUpdate}
                                onPageChange={handleChangePage}
                            />
                        </TabPanel>
                      }
                      return (tab);
                    })}
                </TabContext>
            </div>
          </>
        :
        <Loading />
      }

      { isOpenNewCliente && 
        <ClienteModal
          fromPresupuesto={false} 
          open={isOpenNewCliente}
          cliente={null}
          telefono={null}
          selectedDireccionId={null}
          isEdit={false}
          onSubmitCliente={handleNewcliente}
          onClose={handleCloseNewCliente}
        />
      }

      { isAddObservaciones && 
        <InfoModal
          state={isAddObservaciones}
          title="Añadir observación"
          content={
            <ObservacionesForm 
              entityId={currentEntidad.id}
              onSubmitObservacion={handleSubmitObservaciones}
          />}
          width={'sm'}
          onClose={handleCloseObservaciones}
        ></InfoModal>
      }

      { isChangeProximoContacto && 
        <InfoModal
          state={isChangeProximoContacto}
          title="Cambiar fecha de próximo contacto"
          content={
            <ProximoContactoForm 
              proximoContacto={{
                entity_id: currentEntidad.id, 
                proximo_contacto: currentEntidad.proximo_contacto
              }} 
              onSubmitPC={handleAddProximoContacto} 
          />}
          width={'sm'}
          onClose={handleCloseProximoContacto}
        ></InfoModal>
      }

      { isOpenAddContacto && 
        <InfoModal
          state={isOpenAddContacto}
          title="Añadir contacto"
          content={
            <ContactoForm 
              detalle={currentEntidad}
              contacto={currentContacto}
              isEdit={isEditContacto}
              onSubmitContacto={handleSubmitContacto}
          />}
          width={'sm'}
          onClose={handleCloseOpenAddContacto}
        ></InfoModal>
      }

      { isViewContactosList && 
        <InfoModal
          state={isViewContactosList}
          title="Contactos"
          content={
            <EntidadContacto
              entidad={currentEntidad} 
              contactos={currentEntidad.contactos}
              feedbacks={currentEntidad.feedbacks}
              onEditContacto={handleEditContacto}
          />}
          width={'md'}
          onClose={handleCloseContacto}
        ></InfoModal>
      }

      { isViewDailyLlamadas &&
        <InfoModal
          state={isViewDailyLlamadas}
          title="Llamadas diarias"
          content={
            <DailyLlamadas 
              list={contactosList}
              startOfWeek={startOfWeek}
              endOfWeek={endOfWeek}
          />}
          width={'md'}
          onClose={handleCloseDaily}
        ></InfoModal>
      }

      { isOpenExportConversiones &&
        <InfoModal
          state={isOpenExportConversiones}
          title="Exportar conversión de clientes"
          content={
            <ExportForm 
              dateFilter={true}
              responsableFilter={false}
              tipoClienteFilter={false}
              tipoFacturasFilter={false}
              onSubmitExport={handleExportConversionEntidades}
            />
          }
          width={'sm'}
          onClose={handleCloseConversionEntidadesExport}
        ></InfoModal>
      }
    </div>
  )
}

/**
<Header 
  talleresTab={tab}
  onSearch={handleSearch}
  onExport={handleOpenConversionEntidadesExport}
  onCheck={handleOpenCheckTelefonos}
></Header>

const handleOpenCheckTelefonos = () => setOpenCheckTelefonos(true);
  const handleCloseCheckTelefonos = () => { setOpenCheckTelefonos(false); }

  const handleCheckTelefonos = async(data) => {
    const exportResult = await comprobarTelefonos(data)
    .catch(function (error) {
        toast.error(setErrorMessage(error))
    });

    if(exportResult) {
      toast.success('Excel descargado correctamente!')
    
      const url = window.URL.createObjectURL(new Blob([exportResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'comprobar_telefonos.xlsx');
      document.body.appendChild(link);
      link.click();
      setOpenCheckTelefonos(false);
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

{ isOpenCheckTelefonos &&
    <InfoModal
      state={isOpenCheckTelefonos}
      title="Comprobar teléfonos"
      content={
        <ImportForm 
          text={"¡IMPORTANTE! El Excel tiene que tener solo 1 columna, tiene que ser un excel limpio, sin filtros ni nada parecido."}
          onSubmitImport={handleCheckTelefonos}
        />
      }
      width={'sm'}
      onClose={handleCloseCheckTelefonos}
    ></InfoModal>
  }
 */