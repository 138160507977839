import { useState, useEffect } from "react";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProveedorInfo from "./components/ProveedorInfo";
import ClienteInfo from "./components/ClienteInfo";
import IncidenciaInfo from "./components/IncidenciaInfo";
import PiezasInfo from "./components/PiezasInfo";
import { isCanariasCeutaOrMelillaOrBaleares } from "../../../../../shared/helpers/functionalities";

export default function InfoCard({
    incidencia,
    cliente, 
    proveedor,
    piezas,
    direccionEnvioId,
    onViewAvisos,
    onViewValeDescuento,
    onOpenSolicitudes
}) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [hasWarning, setHasWarning] = useState(false);

    useEffect(() => {
        const {codigo_postal_facturacion, poblacion_facturacion, pais_facturacion_id} = cliente;

        if(pais_facturacion_id) {
            if(pais_facturacion_id === 1 && (codigo_postal_facturacion && poblacion_facturacion)) {
                if(isCanariasCeutaOrMelillaOrBaleares(parseInt(cliente.codigo_postal_facturacion))) setHasWarning(true); 
            } else { setHasWarning(true); }
        }
      
    }, [incidencia, cliente, direccionEnvioId]);

    const handleGoToEnvio = (envio) => {
        dispatch({type: "search/set", payload: {search: envio.codigo_transporte, key: 'envio' }});
        navigate('/envios/1', { replace : true});
    }
    
    return (
        <div className="w-100 custom-card">
            <IncidenciaInfo
                incidencia={incidencia}
                cliente={cliente}
                onGoToEnvio={handleGoToEnvio}
                onViewValeDescuento={onViewValeDescuento}
                onViewAvisos={onViewAvisos}
                onOpenSolicitudes={onOpenSolicitudes}
            />

            <hr />

            <ClienteInfo 
                cliente={cliente}
                direccionEnvioId={direccionEnvioId}
                hasWarning={hasWarning}
            />

            <hr />

            <ProveedorInfo 
                proveedor={proveedor}
            />

            <hr />
            
            <PiezasInfo
                piezas={piezas}
            />
        </div>
    )
}