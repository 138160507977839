import { toast } from "react-toastify";
import { newEntidad, updateEntidad } from "../../views/Dashboard/components/shared/services/clientes";
import { newDireccion, updateDireccion } from "../../views/Dashboard/components/shared/services/direcciones";
import { deletePieza, newPieza, updatePieza } from "../../views/Dashboard/components/shared/services/piezas";
import { calcularTotal, changePresupuestoCliente, getPresupuestoCompleto, updatePresupuestoDireccionEnvio, updatePresupuestoInfo, updatePresupuestoServicioEnvio } from "../../views/Dashboard/components/shared/services/presupuestos";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { getPiezasGarantia2anyos, getPiezasSinValidacion, setErrorMessage } from "../../views/Dashboard/components/shared/helpers/functionalities";

const initialPresupuesto = {
    piezas: [],
    cliente: null,
    es_venta: false,
    progress: 0
};

export const presupuestoReducer = (state = initialPresupuesto, action) => {
    switch(action.type){
        case "presupuesto/nuevo": {
            const presupuesto = {
                piezas: [],
                cliente: null,
                es_venta: false,
                progress: 0,
                total: 0,
                total_envio: 0,
                total_casco: 0,
                iva: 21,
                total_iva: 0
            }
            return presupuesto
        }
        case "presupuesto/fullfilled": {
            return action.payload;
        }
        case "presupuesto/set-progress": {
            return {...state, progress: action.payload }
        }

        //CLIENTE Y DIRECCIONES
        case "presupuesto/set-cliente": {
            const cliente = {
                ...state, 
                cliente: { ...action.payload }
            }
            return cliente
        }
        case "presupuesto/direcciones": {
            const isIn = state.cliente.direcciones.find(d => d.id === action.payload.id)

            if(!isIn) {
                const newDirecciones = {
                    ...state, 
                    cliente: {
                        ...state.cliente,
                        direcciones: state.cliente.direcciones.concat({ ...action.payload })
                    }
                }
                return newDirecciones
            } else {
                const newDirecciones = state.cliente.direcciones.map(dir => {
                    if(dir.id === action.payload.id) {
                        return  action.payload
                    }
    
                    return dir;
                })

                const newState = {
                    ...state, 
                    cliente: {
                        ...state.cliente,
                        direcciones: newDirecciones
                    }
                }
    
                return newState
            }
        }

        // PIEZAS
        case "presupuesto/add-piezas": {
            const newPiezas = state.piezas;
            newPiezas.push(action.payload);
            return {...state, piezas: newPiezas }
        }
        case "presupuesto/update-piezas": {
            const newPiezas = state.piezas;
            const index = newPiezas.findIndex((pieza) => parseInt(pieza.id) === parseInt(action.payload.id));
            newPiezas.splice(index, 1, action.payload);

           return {
                ...state,
                piezas: newPiezas,
            }
        }
        case "presupuesto/delete-piezas": {
            const newPiezas = state.piezas;
            //borramos la pieza
            const index = newPiezas.findIndex((pieza) => parseInt(pieza.id) === parseInt(action.payload.id));
            newPiezas.splice(index, 1);
            return {...state, piezas: newPiezas };
        }
        case "": {
            const newPiezas = state.piezas;
            const index = newPiezas.findIndex((pieza) => parseInt(pieza.id) === parseInt(action.payload.pieza));
            const pieza = {...newPiezas[index], proveedor: action.payload.proveedor};
            newPiezas.splice(index, 1, pieza);

           return {
                ...state,
                piezas: newPiezas,
            }
        }

        // NUEVO PRESUPUESTO TOTAL
        case "presupuesto/calcular": {
            //const piezas2anyos = getPiezasGarantia2anyos();
            //const piezasSinValidacion = getPiezasSinValidacion();
            const total = state.piezas.reduce((acc, cur) => acc = acc + (parseFloat(cur.precio_venta) * cur.unidades), 0);
            const casco = state.piezas.reduce((acc, cur) => acc = acc + ( cur.casco > 0 ? parseFloat(cur.casco) * cur.unidades : 0), 0);
            const envio = state.piezas?.reduce((acc, cur) => acc = acc + ( cur?.servicio_envio ? parseFloat(cur.servicio_envio.precio_transporte) : 0 ), 0);
            let totalSinIva = total + casco + envio;
            let ivaTotal = (total + casco + envio) * state.iva / 100;
            let totalCompleto = totalSinIva + ivaTotal;

           return {
                ...state,
                total: parseFloat(total).toFixed(2),
                total_envio: parseFloat(envio).toFixed(2),
                total_casco: parseFloat(casco).toFixed(2),
                iva: 21,
                total_descuento_iva: 0,
                total_iva: Number(totalCompleto).toFixed(2),
                validado: true,
                validacion: null,
            }
        }

        case "presupuesto/clear": {
            return {
                ...state,
                presupuesto: {},
                piezas: [],
                servicios_envio: [],
                cliente: null,
                es_venta: false,
                progress: 0
            }
        }
        default:
            return state
    }
}

const asyncPresupuesto = makeAsyncTypes('presupuesto');
const [setPending, setFullFilled, setError ] = asyncMac(asyncPresupuesto);

export const presupuestosFetchingReducer = makeFetchingReducer([
    'presupuesto/pending', 
    'presupuesto/fullfilled', 
    'presupuesto/rejected'
]);

export const fetchPresupuesto = (presupuestoId) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await getPresupuestoCompleto(presupuestoId)
        const data = await response.data;
        dispatch(setFullFilled(data));
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const calculatePresupuesto = (presupuestoId) => async dispatch => {
    try {
        const response = await calcularTotal(presupuestoId)
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const updateServicioEnvio = (presupuestoId, servicio) => async dispatch => {
    dispatch(setPending());

    try {
        const response = await updatePresupuestoServicioEnvio(servicio, presupuestoId)
        const data = await response.data;
        dispatch(setFullFilled(data));
        toast.success('Servicio de envio actualizado correctamente.')
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const updatePresupuesto = (presupuesto) => async dispatch => {
    try {
        const response = await updatePresupuestoInfo(presupuesto, presupuesto.id)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const setSelectedDireccionOnPresupuesto = (presupuestoId, direccionId) => async dispatch => {
    try {
        const response = await updatePresupuestoDireccionEnvio(direccionId, presupuestoId)
        const data = await response.data;
        toast.success(response.message);
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch(setError(e.message))
    }
}

export const newClientePresupuesto = (cliente, isChange, presupuestoId) => async dispatch => {
    try {
        const response = await newEntidad(cliente)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'presupuesto/set-cliente', payload: data})
        
        if(isChange) {
            dispatch(changeClientePresupuesto(presupuestoId, data.id));
        } else {
            dispatch({type: 'loading/set', payload: false});
        }
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const updateClientePresupuesto = (cliente) => async dispatch => {
    try {
        const response = await updateEntidad(cliente)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'presupuesto/set-cliente', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const changeClientePresupuesto = (presupuestoId, clienteId) => async dispatch => {
    try {
        const response = await changePresupuestoCliente(presupuestoId, {entity_id: clienteId})
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'presupuesto/set-cliente', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}


export const newDireccionClientePresupuesto = (direccion) => async dispatch => {
    try {
        const response = await newDireccion(direccion)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'presupuesto/direcciones', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const updateDireccionClientePresupuesto = (direccion) => async dispatch => {
    try {
        const response = await updateDireccion(direccion)
        const data = await response.data;
        toast.success(response.message);
        dispatch({type: 'presupuesto/direcciones', payload: data})
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    }
}

export const añadirPieza = (pieza, presupuestoId = null) => async dispatch => {
    try {
        const response = await newPieza(pieza)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        data.unidades = pieza.unidades;
        
        toast.success(response.message);

        if(!presupuestoId) {
            dispatch({type: 'presupuesto/add-piezas', payload: data})
            dispatch({type: "presupuesto/calcular", payload: null});
        } else {
            dispatch(setFullFilled(data));
        }
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    } finally {
        dispatch({type: 'loading/set', payload: false});
    }
}

export const modificarPieza = (pieza, presupuestoId = null) => async (dispatch) => {
    try {
        const response = await updatePieza(pieza)
        .catch(function (error) {
            dispatch({type: 'loading/set', payload: false});
            toast.error(setErrorMessage(error.message))
        });
        const data = await response.data;
        data.unidades = parseInt(pieza.unidades);

        toast.success(response.message);

        if(!presupuestoId) {
            dispatch({type: 'presupuesto/update-piezas', payload: data});
            dispatch({type: "presupuesto/calcular", payload: null});
        } else {
            dispatch(setFullFilled(data));
        }
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    } finally {
        dispatch({type: 'loading/set', payload: false});
    }
}

export const eliminarPieza = (pieza, presupuestoId = null) => async dispatch => {
    try {
        const response = await deletePieza(pieza.id)
        const data = await response.data;
        toast.success(response.message);
       
        if(!presupuestoId) {
            dispatch({type: 'presupuesto/delete-piezas', payload: pieza})
            dispatch({type: "presupuesto/calcular", payload: null});
        } else {
            dispatch(setFullFilled(data));
        }
    } catch (e) {
        toast.error(e.message)
        dispatch(setError(e.message))
    } finally {
        dispatch({type: 'loading/set', payload: false});
    }
}

export const fichaPresupuestoReducer = presupuestoReducer;
export const fichaPresupuestoStatusReducer = presupuestosFetchingReducer;