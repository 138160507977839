import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from '../shared/components/Header'
import Pagination from '@mui/material/Pagination';
import moment from 'moment';
import { generateFacturaPDF } from "../shared/services/facturas";
import { useDispatch, useSelector } from "react-redux";
import { fetchDevoluciones } from "../../../../redux/devoluciones";
import { generateAlbaranPDF } from "../shared/services/albaranes";
import Loading from "../shared/components/Loading";
import { updateDevolucion } from "../shared/services/devoluciones";
import InfoModal from "../shared/components/InfoModal";
import CodigoAutorizacionForm from "./components/CodigoAutorizacionForm";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";

export default function Devoluciones() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { page } = useParams();
  const currPage = parseInt(page);
  const [currentPage, setPage] = useState(currPage);
  const [currentDevolucion, setCurrentDevolucion] = useState(null);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const tiposDevolucion = useSelector(state => {
    const { tipo_devoluciones } = state;
    return tipo_devoluciones;
  });
  const devoluciones = useSelector(state => {
    return state.devoluciones.entities
  });
  const devolucionesStatus = useSelector(state => {
    return state.devoluciones.status
  });
  const searcher = useSelector(state => {
    return state.search
  })

  useEffect(() => {
    dispatch(fetchDevoluciones(currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    const query = !isEmpty ? `&key=${searcher.key}&search=${searcher?.search ? searcher?.search : ''}`
      + (searcher?.start_date ? '&start_date=' + searcher?.start_date : '')
      + (searcher?.end_date ? '&end_date=' + searcher?.end_date : '')
      + (searcher?.min ? '&min=' + searcher?.min : '')
      + (searcher?.max ? '&max=' + searcher?.max : '')
      + (searcher?.responsable ? '&responsable=' + searcher?.responsable : '')
    : null;

    return query;
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/devoluciones/${1}`);
    dispatch({type: "search/set", payload: searchData});     
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/devoluciones/${newPage}`);
    dispatch(fetchDevoluciones(newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = devoluciones.total_items < 15 ? 1 : Math.ceil(devoluciones.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, devolucion) => {
    if(action === 'download') {
      generateThePdf(devolucion)
    } else  if(action === 'edit') {
      handleOpenEdit(devolucion)
    } 
  }

  const getTipoDevolucionName = (tipoId) => tiposDevolucion.find(td => td.id === tipoId)?.nombre;

  const handleViewVentas = () => { 
    dispatch({type: "search/set", payload: ""});
    navigate('/ventas/1', { replace: true });
  }

  const generateThePdf = async (devolucion) => {
    dispatch({type: 'loading/set', payload: true});
    let pdfResult;

    if(devolucion.factura_id) pdfResult = await generateFacturaPDF(devolucion.factura_id);
    else pdfResult = await generateAlbaranPDF(devolucion.albaran_id);

    if(pdfResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', (devolucion.numero) + '.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleOpenEdit = (devolucion) => { setIsOpenEdit(true); setCurrentDevolucion(devolucion); }
  const handleCloseEdit = () => { setIsOpenEdit(false); setCurrentDevolucion(null); }
  const handleEditDevolucion = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await updateDevolucion(data);

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseEdit();
    }
  }

  return (
    <div className="content pt-3">
      <div className="w-100 d-flex flex-wrap align-items-end justify-content-between">
        <Header viewSearch={true} onSearch={handleSearch}></Header>
        
        <div className="w-100 d-flex align-items-center justify-content-end mt-3">
          <button className="btn btn-default" onClick={handleViewVentas}>Ventas</button>
        </div>
      </div>

      { (devolucionesStatus.loading === 'succeed' || devolucionesStatus.loading === 'rejected') ?
          <div className="w-100 d-flex flex-column align-items-end content-table">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>dev</th>
                    <th>tipo</th>
                    <th className="text-center">venta</th>
                    <th className="text-center">fecha</th>
                    <th className="text-center">factura</th>
                    <th>cliente</th>
                    <th>telf</th>
                    <th>Pieza</th>
                    <th className="text-center">€ venta</th>
                    <th className="text-center">€ dev</th>
                    <th className="text-center">€ envio</th>
                    <th className="text-center">total</th>
                    <th className="text-center">envio</th>
                    <th className="text-center">descuento</th>
                    <th className="text-center">total IVA</th>
                    <th className="text-center">resp</th>
                    <th className="text-center">creado por</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>

                { devoluciones.devoluciones?.map((devolucion, index) => {
                    const piezasValues = Object.values(devolucion.piezas)
                    const piezasRows = piezasValues.map((pieza, i) => {
                      const dev = i === 0 ? <td rowSpan={piezasValues.length + 1} width="3%">{devolucion.id}</td> : null
                      const tipo = i === 0 ? <td rowSpan={piezasValues.length + 1} width="3%" className="text-uppercase">{getTipoDevolucionName(devolucion.tipo_devolucion_id)}</td> : null
                      const venta = i === 0 ? <td rowSpan={piezasValues.length + 1} width="3%" className="text-center">{devolucion.venta_id}</td> : null
                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">{moment(devolucion.fecha).format("DD-MM-YYYY")}</td> : null
                      const factura = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">{
                        devolucion.numero
                      }</td> : null
                      const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%">
                        <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + devolucion.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                          {devolucion.cliente?.nombre}
                        </Link>
                      </td> : null
                      const clienteTelf = i === 0 ?  <td rowSpan={piezasValues.length + 1}>{devolucion.cliente.telefono}</td> : null
                      const total = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">{parseFloat(devolucion.total).toFixed(2)}</td> : null
                      const envio = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">{parseFloat(devolucion.total_envio).toFixed(2)}</td> : null
                      const descuento = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">
                        { devolucion?.vale_descuento ? 
                          <div className="d-flex flex-column">
                            +{parseFloat(devolucion.total_descuento_iva).toFixed(2)}€
                            <span className="fw-bold text-green">{devolucion?.vale_descuento.codigo}</span>
                          </div>
                          : '-'
                        }
                      </td> : null
                      const totalIva = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">{parseFloat(devolucion.total_iva).toFixed(2)}</td> : null
                      const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase">{devolucion.created_by?.username}</td> : null
                      const createdBy = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase">{devolucion.crafted_by?.username}</td> : null
                      const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                        <ActionsMenu 
                            options={getActions(devolucion)}
                            onAction={(action) => handleAction(action, devolucion)}
                        />
                      </td> : null
                          
                      return (
                        <tr key={i}>
                          {dev}
                          {tipo}
                          {venta}
                          {fecha}
                          {factura}
                          {cliente}
                          {clienteTelf}
                          <td>
                            { pieza?.devolucion_relationship?.unidades > 1 ? 
                              <strong>{'x'+pieza?.devolucion_relationship?.unidades}</strong> : '' 
                            } 
                            {pieza.nombre.length > 25 ? pieza.nombre.substring(0, 25) + '...' : pieza.nombre}
                          </td>
                          <td className="text-center">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                          <td className="text-center">{parseFloat(pieza.devolucion_relationship.importe).toFixed(2)}€</td>
                          <td className="text-center">{parseFloat(pieza.devolucion_relationship.importe_envio).toFixed(2)}€</td>
                          {total}
                          {envio}
                          {descuento}
                          {totalIva}
                          {responsable}
                          {createdBy}
                          {acciones}
                        </tr>
                      )
                    })
                    return (
                      <tbody key={index}>
                        {piezasRows}
                      </tbody>
                    )
                  }) 
                }
              </table>
            </div>

            <Pagination 
              className="mt-3" 
              count={getPaginationCount()} 
              page={currentPage} 
              onChange={handleChangePage} 
            />
          </div>
        :
        <Loading />
      }

      { (isOpenEdit) && 
        <InfoModal
          state={isOpenEdit}
          title={"Editar código de autorización devolución " + currentDevolucion.numero}
          content={
            <CodigoAutorizacionForm
              devolucion={currentDevolucion}
              onSubmitCodigoAut={handleEditDevolucion}
            />
          }
          width={'sm'}
          onClose={handleCloseEdit}
        ></InfoModal>
      }
    </div>
  )
}