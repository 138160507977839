import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import './login.scss';
import {authenticate} from '../../services/auth';
import logo from '../../images/logo.png';
import { ToastContainer} from 'react-toastify';
import { toast } from 'react-toastify';
import { setErrorMessage } from "../Dashboard/components/shared/helpers/functionalities";
import config from '../../config';
import CircularProgress from '@mui/material/CircularProgress';

export default function Login({setToken}) {
    let navigate = useNavigate();
    let location = useLocation();
    let from = location.state?.from?.pathname || "/";
    const email = useFormInput('');
    const password = useFormInput('');
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (isLoading) return;
        setError(null);

        setIsLoading(true);
        const response = await authenticate({
            email: email.value,
            password: password.value
        }).catch(function (error) {
            toast.error(setErrorMessage(error))
        });
        setIsLoading(false);

        if(response && response.success) {
            setToken(response.data.user);

            if(response.data.user.rol_id === 3) {
                navigate(from === "/" ? '/comerciales/1' : from, { replace: true });
            } else {
                navigate(from, { replace: true });
            }
        }
    };

    return (
        <div className="container w-100 h-100 d-flex align-items-center justify-content-center">
            <div className="col-md-7 col-lg-5 py-4 px-5">
                <img src={logo} className="logo d-block m-auto my-4" alt="Recomotor" />
                
                <form className="form d-flex flex-column align-items-stretch pt-3 pb-4" autoComplete="on" onSubmit={handleLogin}>
                    <div className="form-group mb-3">
                        <input type="text" className="form-control" placeholder="Email o username" {...email} />
                    </div>

                    <div className="form-group">
                        <input type="password" className="form-control" placeholder="Password" {...password} />
                    </div>

                    <button type="submit" className="btn btn-primary mt-4" style={{maxHeight: "43px"}}>
                        {isLoading
                            ? <CircularProgress sx={{ color: 'white' }} size={16} />
                            : 'Login'
                        }
                    </button>
                </form>

                {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
            </div>

            <footer>
                <span>{config.app.version}</span>
            </footer>

            <ToastContainer
                containerId="login"
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);
   
    const handleChange = e => {
      setValue(e.target.value);
    }
    return {
      value,
      onChange: handleChange
    }
  }

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}