import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import {updateVenta, uploadProviderInvoice, downloadProviderInvoice, enviarEmailFormularioIncidencia} from '../shared/services/ventas';
import Header from '../shared/components/Header';
import { faMoneyCheck, faMobileAlt, faTruck, faLink, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoModal from "../shared/components/InfoModal";
import HistoricoVenta from "./components/HistoricoVenta";
import VentaEstadosForm from "./components/VentaEstadosForm";
import VentaFacturaProvForm from "./components/VentaFacturaProvForm";
import FacturasProveedorLista from "./components/FacturasProveedorLista";
import { faCcVisa } from "@fortawesome/free-brands-svg-icons";
import { generateFacturaPDF } from "../shared/services/facturas";
import IncidenciaForm from "./components/IncidenciaForm";
import { newIncidencia } from "../shared/services/incidencias";
import { useDispatch, useSelector } from "react-redux";
import { fetchVentas } from "../../../../redux/ventas";
import { isBoolean, setErrorMessage } from "../shared/helpers/functionalities";
import { generateAlbaranPDF } from "../shared/services/albaranes";
import { regenerarEnvioPendiente, enviarEmailProveedor } from "../shared/services/envios";
import ConfirmModal from "../shared/components/ConfirmModal";
import Loading from "../shared/components/Loading";
import PendientesList from "./components/PendientesList";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";
import { newNota } from "../shared/services/nota_cargo";
import NotaCargoForm from "./components/NotaCargoForm";
import config from "../../../../config";

export default function Ventas() {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { page } = useParams();
  const currPage = parseInt(page);
  const [currentPage, setPage] = useState(currPage);
  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [isStateUpdate, setIsStateUpdate] = useState(false);
  const [isUploadProviderInvoice, setIsUploadProviderInvoice] = useState(false);
  const [isMultipleProviderInvoice, setIsMultipleProviderInvoice] = useState(false);
  const [isIncidencia, setIsIncidencia] = useState(false);
  const [selectedVenta, setSelectedVenta] = useState(null);
  const [selectedPieza, setSelectedPieza] = useState(null);
  const [selectedEnvio, setSelectedEnvio] = useState(null);
  const [isOpenConfirmEnviosPendientes, setIsOpenConfirmEnviosPendientes] = useState(false);
  const [isOpenConfirmEnviarEmailProveedor, setIsOpenConfirmEnviarEmailProveedor] = useState(false);
  const [isOpenConfirmEnvioFormularioIncidencia, setIsOpenConfirmEnvioFormularioIncidencia] = useState(false);
  const [openNotaCargo, setOpenNotaCargo] = useState(false);
  const [isOpenErrorEnvio, setIsOpenErrorEnvio] = useState(false);

  const estados = useSelector(state => {
    const { estados_venta } = state;
    return estados_venta;
  });
  const ventas = useSelector(state => {
    return state.ventas.entities
  });
  const ventasStatus = useSelector(state => {
    return state.ventas.status
  });
  const searcher = useSelector(state => {
    return state.search
  })
   
  useEffect(() => {
    dispatch(fetchVentas(false, currPage, getQuery()));
  }, [searcher]);

  const getQuery = () => {
    const isEmpty = Object.values(searcher).every(x => x === null || x === '');
    let query = !isEmpty ? `&key=${searcher.key}&search=${searcher?.search ? searcher?.search : ''}`
      + (searcher?.start_date ? '&start_date=' + searcher?.start_date : '')
      + (searcher?.end_date ? '&end_date=' + searcher?.end_date : '')
      + (searcher?.min ? '&min=' + searcher?.min : '')
      + (searcher?.max ? '&max=' + searcher?.max : '')
      + (searcher?.responsable ? '&responsable=' + searcher?.responsable : '')
      + (isBoolean(searcher.contiene_casco) ? '&contiene_casco=' + searcher?.contiene_casco : '')
      + (searcher?.casco ? '&casco=' + searcher?.casco : '')
      + (isBoolean(searcher.confirmacion) ? '&confirmacion=' + searcher?.confirmacion : '')
    : null;

    return query;
  }

  const handleSearch = (searchData) => {
    setPage(1);
    navigate(`/ventas/${1}`);
    dispatch({type: "search/set", payload: searchData});    
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    navigate(`/ventas/${newPage}`);
    dispatch(fetchVentas(false, newPage, getQuery()));
  }

  const getPaginationCount = () => {
    var totalPages = ventas.total_items < 15 ? 1 : Math.ceil(ventas.total_items / 15);
    return parseInt(totalPages);
  }

  const handleAction = (action, venta) => {
    if(action === 'status') {
      handleEdit(venta)
    } else  if(action === 'provider-invoice') {
      handleEditProviderInvoice(venta)
    } else  if(action === 'history') {
      handleViewHistory(venta)
    } else  if(action === 'download') {
      generateThePdf(venta)
    } else if(action === 'refund') {
      handleNewRefund(venta)
    } else if(action === 'issue') {
      handleIncidencia(venta)
    } else if(action === 'shipment-note') {
      handleOpenNotaCargo(venta)
    } else if(action === 'incidencia-form-mail') {
      handleOpenConfirmEnvioFormularioIncidencia(venta)
    } else if (action === 'incidencia-form-phone') {
      handleCopyFormularioIncidenciaText(venta)
    }
  }

  const handleOpenConfirmEnvioFormularioIncidencia = (venta) => {
    setSelectedVenta(venta);
    setIsOpenConfirmEnvioFormularioIncidencia(true);
  }
  const handleCloseConfirmEnvioFormularioIncidencia = () => {
    setIsOpenConfirmEnvioFormularioIncidencia(false);
    setSelectedVenta(null);
  }
  const handleConfirmEnvioFormularioIncidencia = async(confirm) => {
    if(confirm) {
      const response = await enviarEmailFormularioIncidencia(selectedVenta.id);

      if(response && response.success) {
        toast.success(response.message);
      }
    }

    handleCloseConfirmEnvioFormularioIncidencia();
  }

  const handleCopyFormularioIncidenciaText = (venta) => {
    const text = `Hola ${venta?.cliente?.nombre},

        Hemos recibido tu solicitud de asistencia. Para agilizar el proceso, por favor completa el formulario de incidencias en el siguiente enlace:
        
        ${config.recomotor.landingUrl + `/solicitudes/${venta?.id}/incidencia`}      
        
        Si necesitas asistencia adicional, no dudes en contactarnos. Agradecemos tu paciencia.`;

    navigator.clipboard.writeText(text);
    toast.success('Texto copiado!');
  }

  const handleEdit = (venta) => {
    setIsStateUpdate(true);
    setSelectedVenta(venta);
  } 

  const handleEditProviderInvoice = (venta) => {
    setIsUploadProviderInvoice(true);
    setSelectedVenta(venta);
  } 

  const handleCloseHistory = () => setIsHistoryOpen(false);
  const handleCloseEstado = () => setIsStateUpdate(false);
  const handleCloseUploadInvoice = () => setIsUploadProviderInvoice(false);
  const handleCloseDownloadProviderInvoices = () => setIsMultipleProviderInvoice(false);
  const handleCloseNuevaIncidencia = () => setIsIncidencia(false);

  const handleViewHistory = (venta) => {
    setIsHistoryOpen(true);
    setSelectedVenta(venta);
  }

  const downloadInvoice = async (invoice) => {
    dispatch({type: 'loading/set', payload: true});
    const invoiceResult = await downloadProviderInvoice(invoice.factura_proveedor);

    if(invoiceResult) {
      dispatch({type: 'loading/set', payload: false});
      const url = window.URL.createObjectURL(new Blob([invoiceResult], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', invoice.factura_proveedor); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }

  const handleUploadProviderInvoice = async (data) => {
    const files = data.factura_proveedor;
    let facturasProveedor = new FormData();
    facturasProveedor.append('id', selectedVenta.id);

    if(files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        facturasProveedor.append(`files[${i}]`, files[i])
      }

      dispatch({type: 'loading/set', payload: true});
      const uploadProviderInvoicesResult = await uploadProviderInvoice(facturasProveedor, selectedVenta.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });

      if(uploadProviderInvoicesResult && uploadProviderInvoicesResult.success && uploadProviderInvoicesResult.data) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(uploadProviderInvoicesResult.message)
        setIsUploadProviderInvoice(false);
        let query = getQuery;
        dispatch(fetchVentas(false, currPage, query));
      }
    }
  }

  const getEstadoName = (estadoId) => estados.find(u => u.id === estadoId)?.nombre;

  const handleViewDevoluciones = () => { 
    localStorage.removeItem('searcher');
    navigate('/devoluciones/1', { replace: true });
  }

  const handleUpdateEstado = async (data) => {
    dispatch({type: 'loading/set', payload: true});
    let response; 

    if(data?.piezas?.length > 0) {
      await Promise.all(data?.piezas.map(p => updateVenta({
        id: data.id,
        estado_venta_id: data.estado_venta_id, 
        pieza_id: p.pieza_id,
        codigo_pedido: p.codigo_pedido,
        tipo_pedido: p.tipo_pedido,
        comentario: p.comentario
      }))).then(res => {
          if(res) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(res[0]?.message)
            setIsStateUpdate(false);
            dispatch(fetchVentas(false, currPage, getQuery()));
          }
      });
    } else {
      response = await updateVenta(data)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });

      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message)
        setIsStateUpdate(false);
        dispatch(fetchVentas(false, currPage, getQuery()));
      }
    } 
  }

  const generateThePdf = async (venta) => {
    let pdfResult;
    dispatch({type: 'loading/set', payload: true});

    if(venta.factura_id) pdfResult = await generateFacturaPDF(venta.factura_id);
    else pdfResult = await generateAlbaranPDF(venta.albaran_id);

    if(pdfResult) {
      dispatch({type: 'loading/set', payload: false});
      toast.success('PDF generado correctamente!')
      const url = window.URL.createObjectURL(new Blob([pdfResult]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', (venta.numero) + '.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  } 

  const handleNewRefund = (venta) => { navigate('/devolucion/0', { state : { ventaId: venta.id }, replace : true}); }

  const handleIncidencia = (venta) => {
    setSelectedVenta(venta);
    setIsIncidencia(true);
  }

  const handleSubmitIncidencia = async (data) => {
    dispatch({type: 'loading/set', payload: true});

    data.forEach(async(incidencia) => {
      const incidenciaResult = await newIncidencia(incidencia)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error))
      });

      if(incidenciaResult && incidenciaResult.data) {
        toast.success(`${data.length > 1 ? 'Incidencias creadas ' : 'Incidencia creada '} correctamente!`)
      }
    })

    dispatch({type: 'loading/set', payload: false});
    setIsIncidencia(false);
    navigate('/incidencias/1')
  }

  const handleGoToPresupuestos = (id) => {
    navigate('/presupuesto/' + id, { replace : true});
  }

  const handleGoToEnvio = (envio) => {
    dispatch({type: "search/set", payload: {search: envio.codigo_transporte, key: 'envio' }});
    navigate('/envios/1', { replace : true});
  }

  const handleOpenConfirmEnviosPendientes = (venta, pieza, envio) => {
    if(venta && pieza) {
      setSelectedVenta(venta);
      setSelectedPieza(pieza);
    } else {
      setSelectedEnvio(envio);
    }
    
    setIsOpenErrorEnvio(true);
  }
  const handleCloseEnviosPendientes = () => {
    setIsOpenConfirmEnviosPendientes(false);
  } 

  const handleOpenConfirmEnvioEmailProveedor = (envio) => {
    setSelectedEnvio(envio);
    setIsOpenConfirmEnviarEmailProveedor(true);
  }
  const handleCloseEnvioEmailProveedor = () => {
    setSelectedEnvio(null);
    setIsOpenConfirmEnviarEmailProveedor(false);
  } 

  const handleCreateEnvioPendiente = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      let response;

      if(selectedVenta && selectedPieza) {
        response = await regenerarEnvioPendiente({
          presupuesto_id: selectedVenta.presupuesto_id,
          servicio_envio_id: selectedPieza.servicio_envio.id
        }).catch(function (error) {
          toast.error(setErrorMessage(error));
          dispatch({type: 'loading/set', payload: false});
        });
      } else {
        response = await regenerarEnvioPendiente({
          envio_id: selectedEnvio.id
        }).catch(function (error) {
          toast.error(setErrorMessage(error));
          dispatch({type: 'loading/set', payload: false});
        });
      }
    
      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message)
        dispatch(fetchVentas(false, currPage, getQuery()));
      }
    } 

    handleCloseEnviosPendientes();
  }

  const handleEnviarEmailProveedor = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await enviarEmailProveedor(selectedEnvio.id)
      .catch(function (error) {
        toast.error(setErrorMessage(error))
        dispatch({type: 'loading/set', payload: false});
      });
  
      if(response && response.success) {
        dispatch({type: 'loading/set', payload: false});
        toast.success(response.message)
        dispatch(fetchVentas(false, currPage, getQuery()));
      }
    }

    handleCloseEnvioEmailProveedor();
  }

  const handleOpenNotaCargo = (venta) => {
    setSelectedVenta(venta);
    setOpenNotaCargo(true);
  }
  const handleCloseNotaCargo = () => {
    setOpenNotaCargo(false);
    setSelectedVenta(null);
  }
  const handleNewNotaCargo = async(data) => {
    dispatch({type: 'loading/set', payload: true});

    data.forEach(async(nota) => {
      const notaResult = await newNota(nota)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error))
      });

      if(notaResult && notaResult.data) {
        toast.success(`${data.length > 1 ? 'Notas de cargo creadas ' : 'Nota de cargo creada '} correctamente!`)
      }
    })

    dispatch({type: 'loading/set', payload: false});
    setIsIncidencia(false);
    navigate('/notas-cargo/1')
  }

  const handleCloseErrorEnvio = () => {
    setSelectedVenta(null);
    setSelectedPieza(null);
    setSelectedEnvio(null);
    setIsOpenErrorEnvio(false);
  }
  const handleOpenConfirmRegenerateEnvio = () => setIsOpenConfirmEnviosPendientes(true);

  //<button className="btn btn-default" onClick={handleViewVentas}>{isArchived ? 'No archivadas' : 'Archivadas'}</button>
    

  return (
    <div className="content pt-3">
      <div className="w-100 d-flex flex-wrap align-items-end justify-content-between">
        <Header viewSearch={true} onSearch={handleSearch}></Header>
      </div>

      <div className="w-100 d-flex flex-column align-items-end content-table">
        <div className="w-100 d-flex flex-wrap flex-md-nowrap align-items-end justify-content-between mb-3">
          <div className="flex-grow-1 d-flex align-items-center justify-content-between justify-content-md-start me-5">
            <div className="legend d-flex align-items-center me-md-3">
              <span className="circle" style={{background: '#000'}}></span>
              <p>Producto/s comprado/s</p>
            </div>

            <div className="legend d-flex align-items-center me-md-3">
              <span className="circle" style={{background: '#ffc107'}}></span>
              <p>Devolución parcial</p>
            </div>

            <div className="legend d-flex align-items-center">
              <span className="circle" style={{background: '#dc3545'}}></span>
              <p>Devolución total</p>
            </div>
          </div>

          <button className="btn btn-default align-self-end" onClick={handleViewDevoluciones}>Devoluciones</button>
        </div>

        { (ventas?.pendientes?.length > 0) &&
          <PendientesList 
          pendientes={ventas.pendientes}
          />
        }

        { (ventasStatus.loading === 'succeed' || ventasStatus.loading === 'rejected') ?
          <>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>pres</th>
                    <th className="text-center">fecha</th>
                    <th>cliente</th>
                    <th>telf</th>
                    <th><small>Pieza</small></th>
                    <th className="text-center"><small>compra</small></th>
                    <th className="text-center"><small>venta</small></th>
                    <th className="text-center"><small>casco</small></th>
                    <th className="text-center">descuento</th>
                    <th className="text-center">total IVA</th>
                    <th className="text-center">margen bruto</th>
                    <th className="text-center">envio</th>
                    <th className="text-center">CONF</th>
                    <th className="text-center">estado</th>
                    <th className="text-center">pago</th>
                    <th className="text-center">resp</th>
                    <th className="text-center"></th>
                  </tr>
                </thead>

                { ventas.ventas?.map((venta, index) => {
                    const piezasValues = Object.values(venta.piezas)
                    const piezasRows = piezasValues.map((pieza, i) => {
                      const envioPieza = venta?.envios?.length > 0 ? venta?.envios?.find(e => e?.piezas.find(p => p.id === pieza.id)) : null;

                      const presupuesto = i === 0 ? <td rowSpan={piezasValues.length + 1} width="5%">
                        <span className="link" title="Presupuesto" onClick={() => handleGoToPresupuestos(venta?.presupuesto?.id)} >
                          { venta.es_devolucion.length > 0 && venta.es_devolucion[0].tipo_devolucion_id === 1 ?
                            <b className="text-warning">{venta.presupuesto?.numero}</b>
                            : venta.es_devolucion.length > 0 && venta.es_devolucion[0].tipo_devolucion_id === 2 ?
                            <b className="text-danger">{venta.presupuesto?.numero}</b>
                            : venta.factura_proveedor && venta.factura_proveedor?.length > 0 ?
                            <b>{venta.presupuesto?.numero}</b>
                            : venta.presupuesto?.numero
                          }
                        </span>
                        
                      </td> : null
                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                        <div className="w-100 d-flex flex-column">
                          <p>{moment(venta.fecha).format("DD-MM-YYYY")}</p>
                          <small>CREADO: {moment(venta.created_at).format("DD-MM-YYYY HH:mm")}</small>
                          <small>ULT MOD.: {moment(venta.updated_at).format("DD-MM-YYYY HH:mm")}</small>
                        </div>
                      </td> : null
                      const cliente = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%">
                        <Link target="_blank" rel="noopener noreferrer" to={"/cliente/ficha/" + venta.cliente?.id} title="Ficha Cliente" className="text-green text-uppercase">
                          {venta.cliente?.nombre}
                        </Link>
                      </td> : null
                      const clienteTelf = i === 0 ?  <td rowSpan={piezasValues.length + 1}>{venta.cliente.telefono}</td> : null
                      const pago = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center"> 
                        { venta.tipo_pago_id === 1 ?
                          <span title="Tarjeta">
                            <FontAwesomeIcon icon={faCcVisa} size="1x" className="action" color="#215732" />
                          </span>
                        
                          : venta.tipo_pago_id === 2 ?
                          <span title="Bizum">
                            <FontAwesomeIcon icon={faMobileAlt} size="1x" className="action" color="#215732" />
                          </span>
                          
                          : venta.tipo_pago_id === 3 ?
                          
                          <span title="Transferencia">
                            <FontAwesomeIcon icon={faMoneyCheck} size="1x" className="action" color="#215732" />
                          </span>
                          : 
                          <span title="Crédito">
                            <b className="text-success">C</b>
                          </span>
                        }
                      </td> : null
                      const descuento = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">
                        { venta?.vale_descuento ? 
                          <div className="d-flex flex-column">
                            -{parseFloat(venta.total_descuento_iva).toFixed(2)}€
                            <span className="fw-bold text-green">{venta?.vale_descuento.codigo}</span>
                          </div>
                          : '-'
                        }
                      </td> : null
                      const totalIva = i === 0 ? <td rowSpan={piezasValues.length + 1} width="6%" className="text-center">{parseFloat(venta.total_iva).toFixed(2)}</td> : null
                      const envio = envioPieza ?
                            <div className="d-flex flex-column">
                            { envioPieza.numero_seguimiento !== '' ?
                                <>
                                  <span onClick={() => handleGoToEnvio(envioPieza)} className="text-green link">{envioPieza.codigo_transporte}</span>
                                  <small>{envioPieza.transportista_id === 1 && envioPieza.numero_seguimiento === '-' ? 'PROVEEDOR' : envioPieza.numero_seguimiento}</small>
                                  <a title="Seguimiento URL" href={config.recomotor.clientsUrl + 'seguimiento/' + venta.presupuesto.checkout_id} target={"_blank"}  className="disabled-item mt-2 text-green" rel="noreferrer">
                                    <FontAwesomeIcon icon={faLink} size="1x" className="action" style={{ cursor: 'pointer' }} color="#215732" />
                                  </a> 
                                  <span title="Enviar email proveedor" style={{cursor: 'pointer'}}>
                                    <FontAwesomeIcon icon={faEnvelope} size="1x" className="action" color="#215732" onClick={() => handleOpenConfirmEnvioEmailProveedor(envioPieza)} />
                                  </span>
                                </>
                              :
                              <span title="Generar envio pendiente" style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faTruck} size="1x" className="action" color="#215732" onClick={() => handleOpenConfirmEnviosPendientes(null, null, envioPieza)} />
                              </span>
                            }
                            </div>
                            : 
                            <div className="w-100">
                              <span title="Generar envio pendiente" style={{cursor: 'pointer'}}>
                                <FontAwesomeIcon icon={faTruck} size="1x" className="action" color="#215732" onClick={() => handleOpenConfirmEnviosPendientes(venta, pieza)} />
                              </span>
                            </div>
                      const confirmada = <span className={"fw-bold " + (pieza.confirmacion == true ? 'text-green' : 'text-danger')}>
                          {pieza.confirmacion == true ? 'SI' : 'NO'}
                        </span>
                      const beneficio = i === 0 ? <td rowSpan={piezasValues.length + 1} className="text-center">{parseFloat(venta.beneficio).toFixed(2)}</td> : null
                      const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center"><small className="text-uppercase">{getEstadoName(venta.estado_venta_id)}</small></td> : null
                      const responsable = i === 0 ?  <td rowSpan={piezasValues.length + 1}  className="text-center text-uppercase"><small>{venta.created_by?.username}</small></td> : null
                      const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                        <ActionsMenu 
                            options={getActions(venta)}
                            onAction={(action) => handleAction(action, venta)}
                        />
                      </td> : null
                          
                      return (
                        <tr key={i} className={!venta.revisado ? 'not-viewed' : ''}>
                          {presupuesto}
                          {fecha}
                          {cliente}
                          {clienteTelf}
                          <td>
                            <div className="w-100 d-flex flex-column text-uppercase">
                              <small className="w-100 d-flex flex-column">
                                <span>
                                  {pieza?.venta_relationship?.unidades > 1 ? 
                                    <strong>{'x'+pieza?.venta_relationship?.unidades}</strong> : '' 
                                  } 
                                  {pieza.nombre}
                                </span>
                                <span>REF: <b>{pieza.referencia_pieza}</b></span>
                                { pieza?.casco > 0 ?
                                  <span className="fw-bold text-uppercase highlight"><small>contiene casco</small></span>
                                  : ''
                                }
                              </small>

                              <Link target="_blank" rel="noopener noreferrer" to={"/proveedor/ficha/" + pieza.proveedor?.id} title="Ficha Proveedor" className="text-green text-uppercase">
                                {pieza.proveedor.nombre}
                              </Link>
                            </div>
                          </td>
                          <td className="text-center">{parseFloat(pieza.precio_compra).toFixed(2)}€</td>
                          <td className="text-center">{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                          <td className="text-center">{pieza.casco > 0 ? parseFloat(pieza.casco).toFixed(2) + '€' : '-'}</td>
                          {descuento}
                          {totalIva}
                          {beneficio}
                          <td className="text-center">{envio}</td>
                          <td className="text-center">{confirmada}</td>
                          {estado}
                          {pago}
                          {responsable}
                          {acciones}
                        </tr>
                      )
                    })
                    return (
                      <tbody key={index}>
                        {piezasRows}
                      </tbody>
                    )
                  }) 
                }
              </table>
            </div>

            <Pagination 
              className="mt-3" 
              count={getPaginationCount()} 
              page={currentPage} 
              onChange={handleChangePage} 
            />
          </>
          :
          <Loading />
        }
      </div>

      { isStateUpdate && 
        <InfoModal
          state={isStateUpdate}
          title="Cambiar estado"
          content={<VentaEstadosForm venta={selectedVenta} onSubmitEstado={handleUpdateEstado} />}
          onClose={handleCloseEstado}
        ></InfoModal>
      }

      { isUploadProviderInvoice && 
        <InfoModal
          state={isUploadProviderInvoice}
          title="Adjuntar factura/s de proveedor"
          content={<VentaFacturaProvForm venta={selectedVenta} onSubmitVenta={handleUploadProviderInvoice} />}
          onClose={handleCloseUploadInvoice}
        ></InfoModal>
      }

      { isHistoryOpen && 
        <InfoModal
          width={'md'}
          state={isHistoryOpen}
          title="Historico de venta"
          content={<HistoricoVenta historico={selectedVenta.historico} />}
          onClose={handleCloseHistory}
        ></InfoModal>
      }

      { isMultipleProviderInvoice && 
        <InfoModal
          state={isMultipleProviderInvoice}
          title="Facturas de proveedor"
          content={
            <FacturasProveedorLista 
              venta={selectedVenta} 
              onDownloadInvoice={downloadInvoice} />
          }
          onClose={handleCloseDownloadProviderInvoices}
        ></InfoModal>
      }

      { isIncidencia && 
        <InfoModal
          state={isIncidencia}
          title="Nueva Incidencia"
          content={
            <IncidenciaForm 
              venta={selectedVenta} 
              onSubmitIncidencia={handleSubmitIncidencia} />
          }
          width={'lg'}
          onClose={handleCloseNuevaIncidencia}
        ></InfoModal>
      }

      { openNotaCargo && 
        <InfoModal
          state={openNotaCargo}
          title="Nueva Nota de cargo"
          content={
            <NotaCargoForm 
              venta={selectedVenta}
              onSubmitNota={handleNewNotaCargo}
            />
          }
          width={'sm'}
          onClose={handleCloseNotaCargo}
        ></InfoModal>
      }

      { (isOpenErrorEnvio) &&
        <InfoModal 
          state={isOpenErrorEnvio}
          title="Error/es de envío"
          content={
            <div className="w-100">

              <button 
                type="button" 
                className="btn btn-primary"
                onClick={() => handleOpenConfirmRegenerateEnvio()}
              >Corregido </button>
            </div>
          }
          width={'sm'}
          onClose={handleCloseErrorEnvio}
        />
      }

      { isOpenConfirmEnviosPendientes && 
          <ConfirmModal 
              onConfirmAction={handleCreateEnvioPendiente} 
              title={'Regenerar envio pendiente'} 
              description={'Estás seguro de regenerar el envio pendiente?'}
              state={isOpenConfirmEnviosPendientes}>
          </ConfirmModal>
      }

      { isOpenConfirmEnviarEmailProveedor && 
          <ConfirmModal 
              onConfirmAction={handleEnviarEmailProveedor} 
              title={'Enviar email al proveedor'} 
              description={'Estás seguro de enviar el email al proveedor?'}
              state={isOpenConfirmEnviarEmailProveedor}>
          </ConfirmModal>
      }

      { isOpenConfirmEnvioFormularioIncidencia &&
        <ConfirmModal 
              onConfirmAction={handleConfirmEnvioFormularioIncidencia} 
              title={'Enviar email de formulario de incidencia'} 
              description={'Estás seguro de enviar el email?'}
              state={isOpenConfirmEnvioFormularioIncidencia}>
          </ConfirmModal>
      }
    </div>
  )
}